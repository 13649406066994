import { API, Auth } from "aws-amplify";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import "./Profile.css";
import utilityFunctions from "../utilityFunctions";

export default class Profile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      username: null,
      email: null,
      defaultCC: undefined
    }
  }

  async componentWillUpdate() {
    utilityFunctions.log("profile componentWillUpdate")
    utilityFunctions.isAuthenticated(this.props);
  }

  async componentDidMount() {
    utilityFunctions.log("profile componentDidMount")
    this.props.hideLoader(false);
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (user !== null) {
        this.setState({ name: user.attributes['custom:givNameCus'] + " " + user.attributes['custom:famName_cus'], username: user.username, email: user.attributes.email })
        utilityFunctions.log(user);
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      if (e !== 'No current user') {
        alert(e);
      }
    }
    try {
      let retrieveCardsResponse = await API.get("payments", "/card")
      utilityFunctions.log(`retrieveCardsResponse ${JSON.stringify(retrieveCardsResponse)}`);
      if (retrieveCardsResponse.sources.length > 0) {
        this.setState({
          defaultCC: {
            brand: retrieveCardsResponse.sources[0].brand,
            last4: retrieveCardsResponse.sources[0].last4
          }
        });
      }
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  segToCreditCardView() {
    utilityFunctions.log('seg to CreditCard view');
    this.props.history.push("/paymentMethod");
  }

  render() {
    return (
      this.props.completedStartup && !!this.state.name ?  
      <div style={{marginTop: '30px'}}>
        <div>
          <ListGroup>
            <ListGroupItem>
              <h3 style={{fontFamily: 'Roboto, helvetica', color: 'grey'}}>Account</h3>
              <span style={{fontFamily: 'Roboto, helvetica', color: 'grey'}}>Name: <span style={{ontFamily: 'Roboto, helvetica', color: 'lightGrey'}}>{this.state.name}</span></span><br />
              <span style={{fontFamily: 'Roboto, helvetica', color: 'grey'}}>Email: <span style={{ontFamily: 'Roboto, helvetica', color: 'lightGrey'}}>{this.state.email}</span></span><br />
              {
                    !!this.props.userInfo && !!this.props.userInfo.business ?
                    <span style={{flexDirection: 'column'}}>
                      <span>
                        <span style={{fontFamily: 'Roboto, helvetica', color: 'grey'}}>Company: <span style={{ontFamily: 'Roboto, helvetica', color: 'lightGrey'}}>{this.props.userInfo.business.name}</span>
                        {
                          this.props.userInfo.business.verified && <img src={require("../assets/myCondico/cell/verified3x.png")} height="12" width="12" alt="verifiedCompany" />
                        }
                        </span>
                      </span>
                      {
                        !this.props.userInfo.business.verified && <span style={{color: 'red', margin: 24, fontFamily: 'Roboto, helvetica', fontSize: 11}}>unverified</span>
                      }
                    </span> : null
              }
            </ListGroupItem>
            {
              !!this.props.userInfo && this.props.userInfo.type !== "admin" &&
              <ListGroupItem>
                  { 
                    !!this.props.userInfo && !!this.props.userInfo.business &&
                    <span style={{paddingBottom: 5,  marginTop: -8, fontStyle: 'italic', fontSize: 14, fontFamily: 'Roboto, helvetica', color: 'red'}}>{this.props.userInfo.business.verified ? `` : `To verify your company name send an email from your work email address to info@mycondico.com.`}</span>
                  }
              </ListGroupItem>
            }
            {
              !!this.props.userInfo && this.props.userInfo.type !== "admin" &&
              <ListGroupItem onClick={() => this.segToCreditCardView()}>
                <h3 style={{fontFamily: 'Roboto, helvetica', color: 'grey'}}>Payment Cards</h3>
                {
                  !!this.state.defaultCC ? 
                  <span>
                    <span className="cell">
                      <span className="mainContent">
                        <img src={ require(`../assets/profileTab/stripe/LightTheme/${this.state.defaultCC.brand}.png`) } height="43" width="53" alt="CC" /> {this.state.defaultCC.last4}
                      </span>
                      <span style={{fontFamily: 'Roboto, helvetica'}}>
                        powered by <img style={{paddingRight:"5px"}} src={ require(`../assets/profileTab/stripeLogo.png`) } height="43" width="55" alt="CC" />
                        <img src={require("../assets/myCondico/cell/disclosureIndicator@3x.png")} height="20" width="12" alt="disclosureIndicator" />
                      </span>
                    </span><br />
                    <span style={{fontFamily: 'Roboto, helvetica', paddingLeft:"60px"}}>(default charge card)</span>
                  </span> :
                  <span className="cell">
                    <span className="mainContent">
                      <img src={ require("../assets/profileTab/Payment.png") } height="43" width="53" alt="cCC" /> Add Card
                    </span>
                    <span style={{fontFamily: 'Roboto, helvetica'}}>
                      <img src={require("../assets/myCondico/cell/disclosureIndicator@3x.png")} height="20" width="12" alt="disclosureIndicator" />
                    </span>
                  </span>
              }
              </ListGroupItem>
            }
          </ListGroup>
        </div>
        <div className= "ButtonContainer">
          <Button
            className="button"
            block
            bsSize="large"
            onClick={() => {
              this.props.clearPartnerLink(); 
              this.props.handleLogout()
              }
            }
          >
            <font face="Roboto, helvetica" color="white">Logout</font>
          </Button>
        </div>
      </div>
      : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}
