import { API } from "aws-amplify";
import React, { Component } from "react";
import { FormControl, ListGroup, Modal, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ReactJson from 'react-json-view'

import utilityFunctions from "../../utilityFunctions";

export default class Users extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        selectedPath: undefined,
        usersRequestAccountEmail: undefined,
        companyToVerifyEmail: undefined,
        usersVerifyAccountEmail: undefined,
        reqId: undefined
    }
    utilityFunctions.log(`constructor Admin`);
    this.handleAdminFunctionChange = this.handleAdminFunctionChange.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
  }

  handleAdminFunctionChange(path) {
    utilityFunctions.log('handleAdminFunctionChange');
    if (path === '/users/requestVerification') {
        utilityFunctions.log('handleAdminFunctionChange /profile/requestVerification');
        this.setState({selectedPath: '/users/requestVerification'}, () => this.makeApiRequestPost('requestVerification'));
    } else if (path === '/users/verifyBusiness') {
        utilityFunctions.log('handleAdminFunctionChange /profile/verifyBusiness');
        this.setState({selectedPath: '/users/verifyBusiness'}, () => this.makeApiRequestPost('verifyBusiness'));
    }
  }

  handleCloseModalPopover() {
    this.setState({showRequestBox: false, showRefundBox: false});
  }

  handleRequestUserEmailChange = event => {
    this.setState({usersRequestAccountEmail: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  handleCompanyEmailChange = event => {
    this.setState({companyToVerifyEmail: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  handleVerifyUserEmailChange = event => {
    this.setState({usersVerifyAccountEmail: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  handleReqIdChange = event => {
    this.setState({reqId: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  async makeApiRequestPost(action) {
    utilityFunctions.log(`POSTING profile business ${action}...`);
    this.props.hideLoader(false);
    let apiResponse = undefined
    try {
        if (action === 'requestVerification') {
            apiResponse = await this.businessPost({
                action: 'requestBusinessVerification',
                usersEmail: this.state.usersRequestAccountEmail,
                companyEmail: this.state.companyToVerifyEmail
            });
            utilityFunctions.log(`api response for POST refund is ${JSON.stringify(apiResponse)}`);
            this.checkResult(apiResponse, 'request');
        } else if (action === 'verifyBusiness') {
            apiResponse = await this.businessPost({
                action: 'verifyBusiness',
                usersEmail: this.state.usersVerifyAccountEmail,
                reqId: this.state.reqId
            });
            utilityFunctions.log(`api response for POST refund is ${JSON.stringify(apiResponse)}`);
            this.checkResult(apiResponse, 'verify');
        }
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
      }
      this.props.handleSetAppError();
    }
  }

  businessPost(data) {
    return API.post("admin", "/profile", {
      body: data
    });
  }

  checkResult(response, type) {
    this.props.hideLoader(true);
    utilityFunctions.log(`response is ${response}`);
    if (type === 'request') {
        if(!!response.requested && response.requested) {
            this.setState({
                apiResponse: {
                    requested: response.requested
                }
            });
        } else {
          utilityFunctions.log("failure");
        }
    } else if (type === 'verify') {
        if(!!response.verified && response.verified) {
            this.setState({
                apiResponse: {
                    verified: response.verified
                }
            });
        } else {
          utilityFunctions.log("failure");
        }
    }
  }

  clear() {
    utilityFunctions.log('clearing app state');
    this.setState({
      selectedPath: undefined,
      apiResponse: undefined,
      reqId: undefined,
      usersVerifyAccountEmail: undefined,
      companyToVerifyEmail: undefined,
      usersRequestAccountEmail: undefined,
      showRequestBox: false,
      showRefundBox: false
    })
  }

  render() {
    if (this.props.userInfo !== undefined && this.props.userInfo.type !== "admin") {
      this.props.history.push('/profile');
      return (<div>NOT AUTHORIZED</div>)
    };
    return (
      this.props.completedStartup ?
      <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Nav style={{backgroundColor: 'white', width: '100px'}} className="flex-column">
                <LinkContainer to="/users/requestVerification">
                    <NavItem>
                      <div >
                        <button onClick={() => this.setState({showRequestBox: true})} style={{color: this.state.selectedPath === '/users/requestVerification' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Send Business Approval Request</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/users/verifyBusiness">
                    <NavItem>
                      <div >
                        <button onClick={() => this.setState({showRefundBox: true})} style={{color: this.state.selectedPath === '/users/verifyBusiness' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Verify Company</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
            </Nav>
            <div style={{height: '500px', width: '90%', border: '1px solid lightGrey 0px border-bottom', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                <h3 style={{alignSelf: 'center'}}><u>{this.state.selectedPath === '/users/requestVerification' ? 'REQUEST BUSINESS VERIFICATION' : this.state.selectedPath === '/users/verifyBusiness' ? 'VERIFY BUSINESS' : 'CLICK SIDE OPTION'}</u></h3><hr /><br />
                <button onClick={() => this.clear()} style={{color: 'red', backgroundColor: 'transparent', marginRight: '50px' }}><font face="Roboto, helvetica" >Clear</font></button>
                {
                  this.state.apiResponse ? 
                  <ListGroup>
                    <ReactJson src={this.state.apiResponse} />
                  </ListGroup> :
                  <div style={{margin: '20px', alignSelf: 'flex-start'}}>
                    <div>{`Instructions:`}</div><br />
                    <div>{`1) Select 'Send Business Approval Request' to send an email to a business email address so that the user can respond. Important: Users signed up email is their account identifer. The business email you send to might be different. `}</div><br />
                    <div>{`2) Then upon reeiving a email response from step #1, click 'Verify Company' if satisfied with details of email response`}</div><br />
                  </div>
                }
            </div>
        </div><br />
        <div style={{display: 'flex', justifyContent: 'left'}}>
            {
                this.state.showRequestBox &&
                <Modal show={this.state.showRequestBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Users Account and the Company Email to Request Email Response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl
                            onChange={this.handleRequestUserEmailChange}
                            placeholder="Users email used at signup"
                        />
                        <FormControl
                            onChange={this.handleCompanyEmailChange}
                            placeholder="user@company.com"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={this.handleCloseModalPopover} variant="secondary">Close</button>
                      <button onClick={() => {this.handleAdminFunctionChange('/users/requestVerification'); this.handleCloseModalPopover()}} variant="primary">Save</button>
                    </Modal.Footer>
                </Modal>
            }
            {
                this.state.showRefundBox &&
                <Modal show={this.state.showRefundBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Users Account and Email Response Req ID to Verify Business</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl
                            onChange={this.handleVerifyUserEmailChange}
                            placeholder="Users email used at signup"
                        />
                        <FormControl
                            onChange={this.handleReqIdChange}
                            placeholder="Obtain reqID from subject line of clients email ex. 11633170369"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={this.handleCloseModalPopover} variant="secondary">Close</button>
                      <button onClick={() => {this.handleAdminFunctionChange('/users/verifyBusiness'); this.handleCloseModalPopover()}} variant="primary">Save</button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
      </div>
      : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}
