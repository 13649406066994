import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button } from "react-bootstrap";

import "./CreateSummary.css";
import config from "../config.js";
import GenericModal from '../components/GenericModal';
import SummaryView from '../components/SummaryView';
import utilityFunctions from "../utilityFunctions";

export default class CreateSummary extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
    this.state = {
      showModal: false,
      modalKind: '',
      createdCondico: undefined
    }
  }

  async handleConfirmation() {
    utilityFunctions.log("confirmed create");
    this.props.hideLoader(false);
    try {
      let createCondicoResponse = await this.createCondico({
        creationData: this.props.creationData
      });
      this.checkResult(createCondicoResponse);
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
      }
      this.props.handleSetAppError();
    }
  }

  createCondico(data) {
    return API.post("transactions", "/transactions/1", {
      body: data.creationData
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`condico response is ${response}`);
    if(!!response.success && response.success) {
      if (!!response.condico) {
        utilityFunctions.log("success");
        this.setState({createdCondico: response.condico, showModal: true, modalKind: "success"}, () => this.props.removeCondicoFromAppStateDueToCompletedAction(config.CondicoAction.create));
      } else {
        utilityFunctions.log("failure");
        this.props.handleSetAppError();
      }
    } else {
      utilityFunctions.log("failure");
      this.setState({createdCondico: response.condico, showModal: true, modalKind: "unsubscribed"});
    }
  }

  handleCloseModalPopover() {
    utilityFunctions.log("close modal called");
    this.setState({ showModal: false }, () => this.props.history.push("/mycondicos"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    var summaryBullets, confirmationBullets, alertTitle, imageType, confirmedCondicoData;
    var includeBullets = true;
    if (!!this.props.appSettings && !!this.props.creationData && this.props.creationData.isComplete) {
      summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.createWithCeilingSummary;
      if (!!this.props.creationData.initiator.acceptFloor) {
        summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.createWithFloorSummary
      }
      if (this.state.modalKind === "success") {
        alertTitle = "Remember";
        includeBullets = false;
        confirmedCondicoData = this.state.createdCondico;
        imageType = config.ConfirmationModalImageType.created;
        confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnCreationSuccessful;
      } else if (this.state.modalKind === "unsubscribed") {
        confirmedCondicoData = this.props.creationData;
        alertTitle = "Sender's Email Unsubscribed";
        imageType = config.ConfirmationModalImageType.unsubscribed;
        confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnCreationUnsubscribedEmail;
      }
    }
    
    return (
      (!!this.props.creationData && this.props.creationData.isComplete) ? 
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
          <Breadcrumb.Item  onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos/create")} >Build Your Condico</Breadcrumb.Item>
          <Breadcrumb.Item active>Summary</Breadcrumb.Item>
        </Breadcrumb>
        { (this.state.showModal === true) ? 
          <GenericModal
          bulletPoints={confirmationBullets}
          includeBullet={includeBullets}
          condicoData={confirmedCondicoData}
          imageType={imageType}
          alertTitle={alertTitle}
          handleCloseModalPopover={this.handleCloseModalPopover}
          /> :
          <div>
            <div className="Summary">
              <h2 style={{fontFamily: 'Roboto, helvetica'}}>Confirm Your Offer</h2>
              For the dispute you have described as follows:
              <p style={{color: 'grey', fontFamily: 'Roboto, helvetica'}}><i>{this.props.creationData.description}</i></p>
              {
                !!this.props.appSettings && 
                <SummaryView
                  childProps={this.props}
                  bulletPoints={summaryBullets}
                  condicoData={this.props.creationData}
                  last4={this.props.last4}
                ></SummaryView>
              }
            </div><br />
            <div className= "ButtonContainer">
              <Button
                className="button"
                block
                bsSize="large"
                onClick={() => this.handleConfirmation()}
              >
                Confirm
              </Button>
            </div>
          </div>}
      </div> :
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="/mycondicos">My Condicos</Breadcrumb.Item>
          <Breadcrumb.Item href="/mycondicos/create">Build Your Condico</Breadcrumb.Item>
          <Breadcrumb.Item active>Summary</Breadcrumb.Item>
        </Breadcrumb>
        You must create a complete condico first
      </div>
    );
  }
};