import { API } from "aws-amplify";
import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { Button, Modal } from "react-bootstrap";

import utilityFunctions from "../utilityFunctions";

const cardStyle = {
    base: {
      color: '#32325d',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

class CheckoutForm extends Component {
    
    constructor(props) {
        super(props);
        this.submitNewCard = this.submitNewCard.bind(this);
        this.handleCloseConfirmationModal = this.handleCloseConfirmationModal.bind(this);
        this.state = {
            showConfirmationModal: false
        }
    }

    async submitNewCard() {
        this.props.childProps.hideLoader(false);
        try {
            let {token} = await this.props.stripe.createToken({name: "Name"});
            let addCardResponse = await this.addCard({
                tokenData: {
                    action: "new",
                    card: token.id
                }
            });
            utilityFunctions.log(`addCardResponse ${JSON.stringify(addCardResponse)}`)
            this.setState({
                showConfirmationModal: true
            });
        } catch (e) {
            if (!!e.response && !!e.response.data) {
              if(e.response.data.message === "Not authorized") {
                utilityFunctions.log("NOT AUTHORIZED");
                this.props.handleLogout();
                return;
              }
              this.props.handleSetAppError();
              return
            }
            this.props.handleSetAppError();
          }
        this.props.childProps.hideLoader(true);
    }

    addCard(data) {
        utilityFunctions.log(`token data ${data}`);
        return API.post("payments", "/card", {
            body: data.tokenData
        });
    }

    handleCloseConfirmationModal() {
        this.setState({ showConfirmationModal: false }, () => this.props.childProps.history.goBack());
    }

    render() {
        return (
        <div className="checkout">
            <h3>Enter information:</h3>
            <CardElement style={cardStyle}/><br />
            <button style={{color: "var(--green_theme)"}} onClick={() => this.submitNewCard()}>Add Card</button>
            <div className="static-modal">
                <Modal show={this.state.showConfirmationModal} onHide={this.handleCloseConfirmationModal}>
                    <Modal.Header>
                    <Modal.Title>Card Added</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your new card is also your default charge card. If you have multiple cards please review to ensure we charge the correct card.</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseConfirmationModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
        );
    }
}

export default injectStripe(CheckoutForm);