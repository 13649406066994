import { Auth } from "aws-amplify";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Button, FormGroup, FormControl, HelpBlock, InputGroup, Image, ControlLabel } from "react-bootstrap";

import config from "./../config.js";
import "./Login.css";
import utilityFunctions from "../utilityFunctions";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.showHidePassword = this.showHidePassword.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this.state = {
      email: "",
      password: "",
      type: "password"
    };
  }

  componentDidMount() {
    utilityFunctions.log('calling app js logout');
    this.props.handleLogout();
  }

  _handleKeyPress(e, value) {
    if (e.key === 'Enter') {
      utilityFunctions.log(`ENTER! on value ${value}`);
      if (value === 'username') {
        ReactDOM.findDOMNode(this.refs['password']).focus();
      } else {
        return;
      }
    } 
  }

  validateForm() {
    return this.getUsernameValidationState() === "success" && this.getPasswordValidationState() === "success";
  }

  getPasswordValidationState() {
    const length = this.state.password.length;
    if (utilityFunctions.passwordIsOfMinStrength(this.state.password)) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.hideLoader(false);
    try {
        let user = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
        utilityFunctions.log(`signing in...${user}`);
        this.props.userHasAuthenticated(true);
    } catch (e) {
        utilityFunctions.log("user error authenticated is: ", this.props.isAuthenticated);
        var message = "Sorry, we do not have a record of the email address or username, or you have entered an incorrect password";
        utilityFunctions.log(`e.message ${e.message}`);
        if (e.message === "User does not exist." || e.message === "Incorrect username or password.") {
          //Auth.resendSignUp('roy2');
          alert(message);
        } else {
          alert(e.message);
        }
        
    }
    utilityFunctions.log("finished async and wait for login");
    this.props.hideLoader(true);
  }

  forgotPassword = event => {
    utilityFunctions.log("forgot pass");
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }

  needAccount = event => {
    this.props.history.push("/signup");
  }

  showHidePassword() {
    if (this.state.type === "password") {
      this.setState({type: "input"});
      return;
    } 
    this.setState({type: "password"});
    return;
  }

  getUsernameValidationState() {
    const length = this.state.email.length;
    if (utilityFunctions.isEmail(this.state.email)) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  renderDevNoticeSection() {
    return (
      <section height="35px" ref="top" id="devNotice" width="100%" style={{backgroundColor: 'red'}}>
        <div align="center" valign="top" style={{textAlign:'center'}} width="50%">
          <table border="0" width="50%">
          <tbody>
            <tr>
              <td>
                <div style={{margin:"50px"}} align="trailing" valign="top">
                  <a href="https://www.mycondico.com">
                    <p>Please visit our production site to sign up / login by clicking <b><u>here</u></b></p>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          </table>	
        </div>
      </section>
    );
  }

  render() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 800) {
      mobile = true;
    }
    return (
      <div className={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "LoginLeadPages" : "Login"}>
        <div style={{marginLeft: '20px', marginRight: '20px'}}>
          <div>
            {( config.Environment === "development" ) && this.renderDevNoticeSection()}
          </div>
          <form style={{marginTop: '100px'}} onSubmit={this.handleSubmit}>
            <FormGroup 
              controlId="email"
              validationState={this.getUsernameValidationState()}
              >
              <ControlLabel><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Email</font></font></ControlLabel>
              <InputGroup>
                <InputGroup.Addon><font size="+1">@</font></InputGroup.Addon>
                <FormControl
                  onKeyPress={(e) => this._handleKeyPress(e, 'username')}
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <FormControl.Feedback />
              { (this.getUsernameValidationState() === "success" || this.getUsernameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. name@domain.com</HelpBlock> }
            </FormGroup>
            <FormGroup 
              controlId="password" 
              validationState={this.getPasswordValidationState()}
            >
              <ControlLabel><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Password</font></font></ControlLabel>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                <div style={{flexGrow: '1'}}>
                  <InputGroup>
                    <InputGroup.Addon><font size="+1">#</font></InputGroup.Addon>
                    <FormControl
                      ref="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      type={this.state.type}
                    />
                    <FormControl.Feedback />
                  </InputGroup>
                </div>
                <div style={{margin: '1px', backgroundColor: config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "transparent", borderWidth: 1, borderColor: !mobile ? 'white': 'lightGrey', borderStyle: 'solid', flexGrow: '1'}}>
                  <Image style={{height:"15px", width:"18px", marginLeft: '11px'}} onClick={this.showHidePassword} src={this.state.type === "password" ? require("../assets/authentication/hidePassword@3x.png") : require("../assets/authentication/showPassword@3x.png")}></Image>
                </div>
              </div>
                  { (this.getPasswordValidationState() === "success" || this.getPasswordValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>8 char minimum, uppercase, lowercase, special char, number</HelpBlock> }
            </FormGroup>
            <Button
              className="button"
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
            >
              <font style={{fontWeight:"600", fontSize: '20px'}} face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "black" : "white"}>Login</font>
            </Button>
          </form>
        </div>
        <div style={{display: "flex", flexDirection: "column"}} className="ForgotLink">
          <button style={{backgroundColor: 'transparent', border: 'none', color:"var(--blue_theme)", paddingTop: "10px"}} onClick={this.needAccount}><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "blue"} >don't have an account?</font></button>
          <button style={{backgroundColor: 'transparent', border: 'none', color:"var(--blue_theme)", paddingTop: "10px"}} href="/forgotpassword" onClick={this.forgotPassword}><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "blue"} >forgot or change password</font></button> 
        </div>
      </div>
    );
  }
}