import React from "react";
import { Breadcrumb } from "react-bootstrap";

const FeeSchedule = (props) => (
    <div>
        {
            (!!props.isAuthenticated) && 
            <Breadcrumb>
                <Breadcrumb.Item onClick={(e) => props.history.goBack()} >Go Back</Breadcrumb.Item>
                <Breadcrumb.Item active>Fee Schedule</Breadcrumb.Item>
            </Breadcrumb>
        }
        <section className="wrapper style2 spotlights">
                <section id="one">
                    <div className="content">
                        <div className="inner">
                        For disputes concerning amounts greater than $1,000, the initiator of a successful Condico is charged according to the following fee schedule, subject to the terms of any accepted promo code:
                        </div><br /><br />
                        <font size="+1"><font color="#99CC00">I.</font> Settlements between $0 - $1,000</font><br />
                        If you have a dispute concerning $1,000 or less, Condico is happy to help you resolve the dispute for free.<br /><br />
                        <font size="+1"><font color="#99CC00">II.</font> Settlements between $1,001 - $5,000</font><br /><br />
                        $50 <br /><br />
                        <font size="+1"><font color="#99CC00">III.</font> Settlements between $5,001 - $20,000</font><br /><br />
                        $150 <br /><br />
                        <font size="+1"><font color="#99CC00">IV.</font> Settlements between $20,001 - $25,000</font><br /><br />
                        $500 <br /><br />
                        <font size="+1"><font color="#99CC00">V.</font> Settlements between $25,001 - $100,000</font><br /><br />
                        $750 <br /><br />
                        <font size="+1"><font color="#99CC00">VI.</font> Settlements greater than $100,000</font><br /><br />
                        $1000 <br /><br />
                    </div>
                </section>
            </section>
    </div>
);

export default FeeSchedule;