import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import "./More.css";
import utilityFunctions from "../utilityFunctions";

export default class More extends Component {
  constructor(props) {
    super(props)
    this.state = {
        cells: [
            {
                title: "About"
            },
            {
                title: "Terms and Conditions"
            },
            {
                title: "Help & FAQs"
            },
            {
                title: "Contact Us"
            }
        ]
    }
  }

  async componentWillUpdate() {
    utilityFunctions.log("profile componentWillUpdate")
    utilityFunctions.isAuthenticated(this.props);
  }

  handleCellClick = (event, path, cell) => {
    event.preventDefault();
    utilityFunctions.log(`more clicked on ${cell.title}`);
    this.props.history.push(path);
  }

  // Render Functions
  render() {
    return (
        !this.props.completedStartup ? <div style={{marginTop: '30px'}}>Loading...</div> :
        <div style={{marginTop: '30px'}} className="More">
            {this.state.cells.length > 0 ? this.renderCells() : this.renderEmptyCell()}
        </div>
    );
  }

  renderCells() {
    return (
        <div className="cells">
            <ListGroup>
              {this.props.completedStartup && this.renderCellList(this.state.cells)}
            </ListGroup>
        </div>
    );
  }

  renderCellList(cells) {
    var aboutPath = "/more/about";
    var termsPath = "/more/terms";
    var helpPath = "/more/help";
    var contactPath = "/more/contact";
    return cells.map(
        (cell, i) => {
            var path;
            path = contactPath;
            if (i === 0) {
                path = aboutPath;
            } else if (i === 1) {
                path = termsPath;
            } else if ( i === 2) {
                path = helpPath;
            }
            return (
                <ListGroupItem
                    key={i}
                    onClick={(e) => this.handleCellClick(e, path, cell)}
                >
                    {this.renderCell(cell)}
                </ListGroupItem>
            )
        }
      );
  }

  renderCell(cell) {
    return (
        <div className="cell">
          <div className="mainContent">
            {
                cell.title === "About" ? 
                <img src={require("../assets/moreTab/About@3x.png")} height="18" width="18" alt="stateImage" />  : 
                cell.title === "Terms and Conditions" ? 
                <img src={require("../assets/moreTab/TermsAndConditions@3x.png")} height="18" width="18" alt="stateImage" />  :
                cell.title === "Help & FAQs" ? 
                <img src={require("../assets/moreTab/HelpFAQ@3x.png")} height="18" width="18" alt="stateImage" />  :
                <img src={require("../assets/moreTab/ContactUs@3x.png")} height="18" width="18" alt="stateImage" />
            }
            <h3 style={{fontFamily: 'Roboto, helvetica'}}>{cell.title}</h3>
          </div>
          <div className="disclosureIndicatorBox">
            <img src={require("../assets/myCondico/cell/disclosureIndicator@3x.png")} height="20" width="12" alt="stateImage" />
          </div>
        </div>
    );
  }

  renderEmptyCell(){
    return (
        <div className="emptyCell">
          <h3>Couldn't load. Refresh your browser and try again</h3>
        </div>
    );
  }
}