import React, { Component } from "react";
import { Button, Carousel } from "react-bootstrap";
import "./TutorialScreens.css";

export default class TutorialScreens extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingScreen: 0
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.history.push("/terms/auth");
  }

  renderForm() {
    return (
        <div className="Outer">
            <Carousel 
                indicators={false}
                wrap={false}
            >
                <Carousel.Item>
                    <div className="Main">
                        <div className="title" >
                            <h2 className="titleHeader">With Condico, settling disputes</h2>
                            <h2 className="titleHeader"> over money is a snap!</h2>
                        </div>
                        <div className="imageContent" >
                            <img className="image" width={200} height={200} alt="200x200" src={require("../assets/tutorial/firstScreen@3x.png")} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="Main">
                        <div className="title" >
                            <h2 className="titleHeader">Step1: What's the skinny?</h2>
                            <p className="subHeader">Briefly describe your dispute and on a confidential basis state your honest position on how it ought to be resolved.</p>
                        </div>
                        <div className="imageContent" >
                            <img className="image" width={200} height={200} alt="200x200" src={require("../assets/tutorial/secondScreen@3x.png")} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="Main">
                        <div className="title" >
                            <h2 className="titleHeader">Step 2: Relax.</h2>
                            <p className="subHeader">Leave it to Condico to determine if there is common ground between you and the other side to your dispute.</p>
                        </div>
                        <div className="imageContent" >
                            <img className="image" width={200} height={200} alt="200x200" src={require("../assets/tutorial/thirdScreen@3x.png")} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="Main">
                        <div className="title" >
                            <h2 className="titleHeader">Step 3: Condico!</h2>
                            <p className="subHeader">If the offer to pay matches or exceeds what Condico is told would be acceptable, you're settled.</p>
                        </div>
                        <div className="lastImageContent" >
                            <img className="image" width={200} height={200} alt="200x200" src={require("../assets/tutorial/forthScreen@3x.png")} />
                        </div>
                    </div>
                    <div className= "LowerContainer">
                        <Button
                            className="button"
                            block
                            bsSize="large"
                            onClick={this.handleSubmit}
                        >
                            Get Started!
                        </Button>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.renderForm()}
      </div>
    );
  }
}