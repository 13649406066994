import { Auth } from "aws-amplify";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Alert, Button, ControlLabel, FormGroup, FormControl, HelpBlock, Image, InputGroup } from "react-bootstrap";

import "./Signup.css";
import config from "../config";
import utilityFunctions from "../utilityFunctions";

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.showHidePassword = this.showHidePassword.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this.state = {
      email: "",
      password: "",
      phone: "",
      firstname: "",
      lastname: "",
      accountCreated: false,
      type: "password"
    };
  }

  _handleKeyPress(e, value) {
    if (e.key === 'Enter') {
      utilityFunctions.log(`ENTER! on value ${value}`);
      if (value === 'email') {
        ReactDOM.findDOMNode(this.refs['password']).focus();
      } else if (value === 'password') {
        ReactDOM.findDOMNode(this.refs['phone']).focus();
      } else {
        return;
      }
    } 
  }

  validateForm() {
    return (
        this.getEmailValidationState() === "success" &&
        this.getPasswordValidationState() === "success" &&
        this.getPhoneValidationState() === "success"
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  getEmailValidationState() {
    const length = this.state.email.length;
    if (utilityFunctions.isEmail(this.state.email)) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  getPasswordValidationState() {
    const length = this.state.password.length;
    if (utilityFunctions.passwordIsOfMinStrength(this.state.password)) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  getPhoneValidationState() {
    const length = this.state.phone.length;
    if (utilityFunctions.isPhone(this.state.phone)) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.props.hideLoader(false);
    try {
        utilityFunctions.log("user pre signup is: ");
        var phoneNoDashes = this.state.phone.replaceAll('-', '');
        var userName = this.createUsername(this.state.email);
        const newUser = await Auth.signUp({
            username: userName,
            password: this.state.password,
            attributes: {
                email: this.state.email,
                phone_number: `+1${phoneNoDashes}`
            }
        });
        utilityFunctions.log(newUser);
        this.props.hideLoader(true);
        this.setState({accountCreated: true});
    } catch (e) {
        utilityFunctions.log(`user error on signup ${e.message}`);
        if (e.message === "User is not confirmed.") {
            //Auth.resendSignUp() this sends a code we want to resend email (find a way)
            utilityFunctions.log("User not confirmed");
        }
        if (config.Environment === "development") {
          alert(`Error with message: ${e.message} This is a dev only site with restricted user access. Visit our Prod site when it comes live. Thank you`);
        } else {
          alert(`Sorry, an issue has occured with the following message ${e.message}. Please try again shortly`);
          utilityFunctions.log(`sign up error ${e.message}`);
        }
        this.props.hideLoader(true);
    }
  }

  createUsername(email) {
    email = email.toLowerCase();
    var username = email.replace('@', '_');
    username = username.replace('.', '_');
    username = username + '_' + String(Math.floor(Math.random() * 100) + 1);
    utilityFunctions.log(`username is ${username}`);
    return username;
  }

  navigateToLogin = event => {
    event.preventDefault()
    this.props.history.push("/login");
  }

  showHidePassword() {
    if (this.state.type === "password") {
      this.setState({type: "input"});
      return;
    } 
    this.setState({type: "password"});
    return;
  }

  haveAccount = event => {
    this.props.history.push("/login");
  }

  renderForm() {
    var mobile = false;
    if (document.documentElement.clientWidth <= 800) {
      mobile = true;
    }
    return (
      <div style={{marginLeft: '20px', marginRight: '20px'}}>
        <form style={{marginTop: '100px'}} onSubmit={this.handleSubmit}>
          <font style={{fontWeight:"600", fontSize: '22px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Create an account:</font></font><br /><br />
          <FormGroup
            controlId="email" 
            validationState={this.getEmailValidationState()}
          >
            <ControlLabel><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Email</font></font></ControlLabel>
            <InputGroup>
              <InputGroup.Addon><font size="+1">@</font></InputGroup.Addon>
              <FormControl
                onKeyPress={(e) => this._handleKeyPress(e, 'email')}
                ref="email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </InputGroup>
            <FormControl.Feedback />
                { (this.getEmailValidationState() === "success" || this.getEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. name@domain.com</HelpBlock> }
          </FormGroup>
          <FormGroup 
            controlId="password"
            validationState={this.getPasswordValidationState()}
          >
            <ControlLabel><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Password</font></font></ControlLabel>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
              <div style={{flexGrow: '1'}}>
                <InputGroup>
                  <InputGroup.Addon><font size="+1">#</font></InputGroup.Addon>
                  <FormControl
                    onKeyPress={(e) => this._handleKeyPress(e, 'password')}
                    ref="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    type={this.state.type}
                  />
                  <FormControl.Feedback />
                </InputGroup>
              </div>
              <div style={{margin: '1px', backgroundColor: config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "transparent", borderWidth: 1, borderColor: !mobile ? 'white': 'lightGrey', borderStyle: 'solid', flexGrow: '1'}}>
                <Image style={{height:"15px", width:"18px", marginLeft: '11px'}} onClick={this.showHidePassword} src={this.state.type === "password" ? require("../assets/authentication/hidePassword@3x.png") : require("../assets/authentication/showPassword@3x.png")}></Image>
              </div>
            </div>
                { (this.getPasswordValidationState() === "success" || this.getPasswordValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>8 char minimum, uppercase, lowercase, special char, number</HelpBlock> }
          </FormGroup>
          <FormGroup 
            controlId="phone" 
            validationState={this.getPhoneValidationState()}
          >
            <ControlLabel><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Phone</font></font></ControlLabel>
            <InputGroup>
              <InputGroup.Addon>
                <Image style={{height:"15px", width:"18px"}} onClick={this.showHidePassword} src={ require("../assets/moreTab/Phone@3x.png") }></Image>
              </InputGroup.Addon>
              <FormControl
                onKeyPress={(e) => this._handleKeyPress(e, 'phone')}
                ref="phone"
                value={this.state.phone}
                onChange={this.handleChange}
                type="tel"
              />
              <FormControl.Feedback />
            </InputGroup>  
                { (this.getPhoneValidationState() === "success" || this.getPhoneValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Leave out country code. ex 123-456-7890</HelpBlock> }
          </FormGroup>
          <Button
            className="button"
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            >
              <font style={{fontWeight:"600", fontSize: '20px'}} face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "black" : "white"}>Sign Up</font>
          </Button>
        </form>
        <div style={{display: "flex", flexDirection: "column"}} className="ForgotLink">
          <button style={{backgroundColor: 'transparent', border: 'none', color:"var(--blue_theme)", paddingTop: "10px"}} onClick={this.haveAccount}><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "blue"} >Already have an account? Login</font></button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "SignUpLeadPages" : "Signup"}>
        {
          this.state.accountCreated && 
          <Alert bsStyle="success" onDismiss={this.dismissError}>
            <br />
            <h4>We have sent a message to your email account with instructions for you to confirm that the account is yours.  Please provide the confirmation requested before logging in to Condico.</h4>
            <Button href="/login" onClick={this.navigateToLogin} >Go to login</Button>
          </Alert>
        }
        {this.renderForm()}
      </div>
    );
  }
}