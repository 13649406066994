import { Auth } from "aws-amplify";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Breadcrumb, Button, FormControl, FormGroup, HelpBlock, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";

import "./AttemptCondico.css";
import utilityFunctions from "../utilityFunctions";

const attemptStyles = {
  textHeaderLabel: {
    marginLeft: 12,
    fontSize: 17,
    color: '#232b2b',
    fontWeight: '700',
    textAlign: 'left',
    fontFamily: 'Roboto, helvetica'
  }, 
  textLabel: {
    marginLeft: 20,
    fontSize: 17,
    color: '#585858',
    fontWeight: '500',
    textAlign: 'left',
    fontFamily: 'Roboto, helvetica'
  }
}


export default class AttemptCondico extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.state = {
      authUserFirstLastname: undefined,
      validForm: false,
      hasEnteredAmount: false,
      amount: 0,
      displayAmount: 0
    }
  }

  async componentWillMount() {
    utilityFunctions.isAuthenticated(this.props);
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (!!user) {
        this.setState({authUserFirstLastname: user.attributes['custom:givNameCus'] + " " + user.attributes['custom:famName_cus']})
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  handleContinue = event => {
    event.preventDefault();
    utilityFunctions.log("submitting form, seg to attempt summary")
    this.props.history.push('/mycondicos/attempt/attemptSummary');
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  handleAmountChange = event => {
    var attemptAmount;
    var displayAmount = this.state.displayAmount;
    if (event.target.value !== '') {
      var amt = event.target.value;
      // replace all previous occurances of styled commas with empty character ex. 5,000,000 becomes 5000000
      amt = amt.replace(/[,]+/g, '');
      // remove all preceeding zeros ex. 00050 becomes 50
      amt = amt.replace(/^0+/, '');
      //check if input contains only 0-9 and 1 period. Otherwise dont update the field
      var notInSet = /[^0-9.]/.test(amt);
      var periodCount = (amt.match(/\./g) || []).length;
      utilityFunctions.log(`inSet ${!notInSet} periodCount ${periodCount}`);
      if (!notInSet && periodCount <= 1) {
        // check that input only contains 2 digits after period
        if (periodCount === 1) {
          
          utilityFunctions.log(`decimal count is 1 ${amt}`);
          var periodSplit = amt.split('.');
          
          // check that we dont have more than 2 digits on right of decimal ex. 00.004722
          if (periodSplit[1].length <= 2) {
            utilityFunctions.log(`right side of decimal is less than 2`);
            
            // handle case for creation data amount where right side doesnt have value yet or full amount
            if (periodSplit[1].length === 2) {
              attemptAmount = `${periodSplit[0]}${periodSplit[1]}`;
            } else if (periodSplit[1].length === 1) {
              attemptAmount = `${periodSplit[0]}${periodSplit[1]}0`;
            } else if (periodSplit[1].length === 0) {
              attemptAmount = `${periodSplit[0]}00`;
            }
            attemptAmount = parseInt(attemptAmount, 10);
            amt = `${periodSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${periodSplit[1]}`;
            displayAmount = amt;
            this.setState({displayAmount, amount: attemptAmount, hasEnteredAmount: true}, () => this.validateForm());
            return;
          } else {
           utilityFunctions.log('user entered more than 2 digits right of decimal so not updating');
          }
        } else if (periodCount === 0) {
          attemptAmount = parseInt(`${amt}00`, 10);
          utilityFunctions.log('decimal count is 0 so just formating a integer value with commas');
          amt = amt.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          displayAmount = amt;
          this.setState({displayAmount, amount: attemptAmount, hasEnteredAmount: true}, () => this.validateForm());
        } else {
          utilityFunctions.log('user entered 2nd period so not updating');
        }
      }
    } else {
      displayAmount = 0;
      attemptAmount = 0;
      this.setState({displayAmount, amount: attemptAmount, hasEnteredAmount: true}, () => this.validateForm());
    };
  }

  getAmountValidationState() {
    if ( !!this.state.amount && this.state.amount > 0 ) return 'success';
    else if ( this.state.amount === 0 && this.state.hasEnteredAmount ) return 'error';
    return null;
  }

  validateForm() {
    if (this.getAmountValidationState() === "success") {
        this.setState({validForm: true}, () => this.props.setAttemptAmount(this.state.amount));
        return;
    }
    utilityFunctions.log("validation failed");
    this.setState({validForm: false}, () => this.props.setAttemptAmount(null));
    return;
  }

  render() {
    var willingText = "to pay (a maximum of) ";
    var bubbleTxt = "Your partner said they were willing to accept a payment if your amount and theirs had room to settle. The amount is kept confidential and is used only by the system when determining if a settlement is possible.";
    var attemptCondico;
    if (!!this.props.attemptCondico) {
        attemptCondico = this.props.attemptCondico;
        if (!!attemptCondico.initiator.payCeiling && attemptCondico.initiator.payCeiling) {
            willingText = "to accept (a minimum of) ";
            bubbleTxt = "Your partner said they were willing to make a payment if your amount and theirs had room to settle. The amount is kept confidential and is used only by the system when determining if a settlement is possible."
        }
    }
    const popover = (
      <Popover id="modal-popover" title="Willing to accept/pay?" >
        {bubbleTxt}
      </Popover>
    );
    return (
      !!attemptCondico && !!this.state.authUserFirstLastname  ? 
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
          <Breadcrumb.Item active>Attempt</Breadcrumb.Item>
        </Breadcrumb>
        <div>
            <div style={attemptStyles.textHeaderLabel}>Matter to resolve:</div><br />
            <div style={attemptStyles.textLabel}>{`  ${attemptCondico.description}`}</div><br />
            <div style={attemptStyles.textHeaderLabel}>{this.props.userInfo.business ? `${this.props.userInfo.business.name} is willing` : `I, ${this.state.authUserFirstLastname} am willing`}</div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={attemptStyles.textHeaderLabel}>{`${willingText} `}<span>&nbsp;&nbsp;</span></div><OverlayTrigger rootClose trigger="click" overlay={popover} placement="top"><img src={require("../assets/myCondico/infoBtn.png")} style={{marginTop: 5}} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger><br />
            </div>
            <FormGroup
              controlId="amount"
              validationState={this.getAmountValidationState()}
            >
              <InputGroup>
                <InputGroup.Addon>(CAD) C$</InputGroup.Addon>
                <FormControl
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      ReactDOM.findDOMNode(this.refs['amtField']).blur();
                    }
                  }}
                  ref="amtField"
                  autoComplete="off"
                  type="text"
                  onChange={this.handleAmountChange}
                  value={String(this.state.displayAmount)}
                />
                <FormControl.Feedback />
                { (this.getAmountValidationState() === "success" || this.getAmountValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. amount $1.00</HelpBlock> }
              </InputGroup>
            </FormGroup>
            <div style={attemptStyles.textHeaderLabel}>{`to resolve this matter.`}</div><br />
            <div style={attemptStyles.textHeaderLabel}>{`This Condico expires:`}</div>
            <div style={attemptStyles.textLabel}>{`${new Date(attemptCondico.expiry).toLocaleTimeString().toString()}, ${new Date(attemptCondico.expiry).toDateString()}`}</div>
            <div style={attemptStyles.textHeaderLabel}>{`This Condico is being negotiated with:`}</div>
            <div style={attemptStyles.textLabel}>{!!attemptCondico.initiator.business ? ` ${attemptCondico.initiator.firstname} ${attemptCondico.initiator.lastname} from ${attemptCondico.initiator.business}` : ` ${attemptCondico.initiator.firstname} ${attemptCondico.initiator.lastname}`}</div><br /><br />
            <div style={{paddingTop:"25px"}} className= "ButtonContainer">
              <Button
                className="button"
                disabled={!this.state.validForm}
                onClick={this.handleContinue}
                block
                bsSize="large"
              >
                Continue
              </Button>
            </div>
        </div>
      </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}