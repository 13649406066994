import React, { Component } from "react";
import { Breadcrumb } from "react-bootstrap";

export default class About extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div style={{fontFamily: 'Roboto, helvetica'}}>
                {
                    !!this.props.isAuthenticated ? 
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={(e) => this.props.history.goBack()} >More</Breadcrumb.Item>
                        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                    </Breadcrumb> : null
                }
                <section className="wrapper style2 spotlights">
                    <section>
                        <div className="content">
                            <div style={{hyphens: "auto", textAlign:'justify'}} className="inner"><br />
                                <font face="Roboto, helvetica" size="+2"><font color="#333333">About Us</font></font><br />
                                <font face="Roboto, helvetica" color="black">Condico offers a paradigm shift for resolving financial disputes. We think you're going to be really happy with our approach.<br /><br />
                                Everyone knows that resolving a dispute requires some compromise from both sides. And often, if people could be honest with each other, they'd learn that each side is prepared to compromise enough to get the dispute resolved. But honesty isn't the solution to everyday negotiations; it is the problem. And for good reason.<br /><br />
                                No one in their right mind would disclose the real number at which they would be prepared to settle a dispute, because they know that when the other side learns the number, they won't believe it is a final offer. Instead, people are conditioned to expect that the offer they have received is just the starting point for negotiations. People are conditioned to expect a 'dance' where at least a few rounds of offers are exchanged before any settlement is reached. If by chance, a settlement is reached, both sides are usually unhappy. They are upset that they didn't 'stick to their guns' and are convinced that if they had had the fortitude, they could have extracted further concessions from the other party. Often, there's no deal because neither party wants to risk putting their best offer on the table, fearing it will be misperceived as the start of yet another round of negotiations.<br /><br />
                                Enter Condico. Negotiations without the dance.<br /><br />
                                Each side tells Condico, on a confidential basis, their honest position on the figure at which they would be prepared to settle the dispute. If the offer of payment is greater than, or as much as, what the party owed the money is willing to take to walk away, Condico's algorithm spits out your binding settlement figure and you're done. Condico never discloses either side's position. That way, neither party has given away their position if there is no settlement, and neither side feels like they should have driven a harder bargain, because there wasn't any bargaining at all.<br /><br />
                                Condico!</font>
                                <br />
                            </div><br />
                        </div>
                    </section>
                </section>
            </div>
        );
    }
}