import React from "react";
import { Switch } from "react-router-dom";

import About from "./components/About";
import AppliedRoute from "./components/AppliedRoute";
import AppliedDefaultRoute from "./components/AppliedRouteDefault";
import AttemptCondico from "./containers/AttemptCondico";
import AttemptSummary from "./containers/AttemptSummary";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import CollectPersonalInfo from "./containers/CollectPersonalInfo";
import Condico from "./containers/Condico";
import ContactUs from "./containers/ContactUs";
import CreateCondico from "./containers/CreateCondico";
import CreateSummary from "./containers/CreateSummary";
import FAQ from "./components/FAQ";
import ForgotPassword from "./containers/ForgotPassword";
import Home from "./containers/Home";
import Login from "./containers/Login";
import More from "./containers/More";
import MyCondicos from "./containers/MyCondicos";
import NotFound from "./containers/NotFound";
import PartnerLink from "./containers/PartnerLink";
import PayCondico from "./containers/PayCondico";
import PaymentMethod from "./containers/PaymentMethod";
import PaySummary from "./containers/PaySummary";
import Profile from "./containers/Profile";
import Promos from "./containers/adminViews/Promos";
import RescindCondico from "./containers/RescindCondico";
import RescindSummary from "./containers/RescindSummary";
import ReviewCondico from "./containers/ReviewCondico";
import Signup from "./containers/Signup";
import TermsAndConditions from "./components/TermsAndConditions";
import TermsAndConditionsAuth from "./containers/TermsAndConditionsAuth";
import TutorialScreens from "./containers/TutorialScreens";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import StripeElementsWrapper from "./containers/StripeElementsWrapper";
import FeeSchedule from "./components/FeeSchedule";
import Status from "./containers/adminViews/Status";
import Users from "./containers/adminViews/Users";

export default ({ childProps }) => 
  <Switch>
    <AuthenticatedRoute path="/promos" component={Promos} props={childProps} />
    <AuthenticatedRoute path="/app/index.html" exact component={PartnerLink} props={childProps} />
    <AuthenticatedRoute path="/collectPersonalInfo" exact component={CollectPersonalInfo} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/more" exact component={More} props={childProps} />
    <AuthenticatedRoute path="/more/help" exact component={FAQ} props={childProps} />
    <AuthenticatedRoute path="/more/contact" exact component={ContactUs} props={childProps} />
    <AuthenticatedRoute path="/mycondicos" exact component={MyCondicos} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/attempt" exact component={AttemptCondico} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/attempt/attemptSummary" exact component={AttemptSummary} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/create" exact component={CreateCondico} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/create/createSummary" exact component={CreateSummary} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/pay" exact component={PayCondico} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/pay/paySummary" exact component={PaySummary} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/rescind" exact component={RescindCondico} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/rescind/rescindSummary" exact component={RescindSummary} props={childProps} />
    <AuthenticatedRoute path="/mycondicos/review" exact component={ReviewCondico} props={childProps} />
    <AuthenticatedRoute path="/paymentMethod" exact component={PaymentMethod} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps} />
    <AuthenticatedRoute path="/status" component={Status} props={childProps} />
    <AuthenticatedRoute path="/stripe" exact component={StripeElementsWrapper} props={childProps} />
    <AuthenticatedRoute path="/terms/auth" exact component={TermsAndConditionsAuth} props={childProps} />
    <AuthenticatedRoute path="/tutorial" exact component={TutorialScreens} props={childProps} />
    <AuthenticatedRoute path="/users" component={Users} props={childProps} />
    <UnauthenticatedRoute path="/forgotpassword" exact component={ForgotPassword} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/top" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/oneA" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/oneB" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/oneC" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/two" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/three" exact component={Condico} props={childProps} />
    <UnauthenticatedRoute path="/" exact component={Condico} props={childProps} />
    <AppliedRoute path="/more/about" exact component={About} props={childProps} />
    <AppliedRoute path="/more/terms" exact component={TermsAndConditions} props={childProps} />
    <AppliedRoute path="/terms" exact component={TermsAndConditions} props={childProps} />
    <AppliedRoute path="/fees" exact component={FeeSchedule} props={childProps} />
    <AppliedDefaultRoute component={NotFound} props={childProps} />
  </Switch>;
  