import React from "react";
import { Button, Modal} from "react-bootstrap";

import config from "../config.js";
import "./GenericModal.css";
import newImg from "../assets/modalImages/popoverCreated.png";
import rescindedImg from "../assets/modalImages/popoverRescinded.png";
import attemptedImg from "../assets/modalImages/popoverAttempted.png";
import completedImg from "../assets/modalImages/popoverCompleted.png";
import unsubscribedImg from "../assets/modalImages/popoverUnsubscribed.png";
import infoImg from "../assets/modalImages/popoverInfo.png";
import paymentImg from "../assets/modalImages/popoverPayment.png";
import utilityFunctions from "../utilityFunctions";

const GenericModal = ({ bulletPoints: bullets, condicoData, alertTitle, includeBullet, imageType, partnerAmount, handleCloseModalPopover, ...rest }) => {
    var initiatorBusiness = undefined;
    var partnerBusiness = undefined;
    if (!!condicoData) {
        if (!!condicoData.initiator.business) {
            initiatorBusiness = condicoData.initiator.business;
        }
        if (!!condicoData.partner.business) {
            partnerBusiness = condicoData.partner.business;
        }
    }
    return (
        <div>
            <Modal show={true} onHide={handleCloseModalPopover}>
            <Modal.Header closeButton>
                <Modal.Title>{alertTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modalbox">
                    <div className="modalImage">
                        <img src={displayImageForType(imageType)} height="45" width="45" alt="stateImage" /><br />
                    </div>
                    <div>
                        {   !!condicoData ?
                            bullets.map((bullet,i) => <span key={i}>
                            {
                                attachBullet(includeBullet, utilityFunctions.performStringReplacements(
                                    bullet,
                                    condicoData.description,
                                    condicoExpiry(condicoData),
                                    "4222",
                                    condicoData.initiator.firstname,
                                    condicoData.initiator.lastname,
                                    initiatorBusiness,
                                    condicoData.initiator.email,
                                    condicoData.initiator.partnersIntentFirstname,
                                    condicoData.initiator.partnersIntentLastname,
                                    condicoData.partner.email,
                                    partnerBusiness,
                                    condicoData.initiator.amount,
                                    partnerAmount,
                                    condicoAmt(condicoData)
                                ))
                            }
                            <br /></span> ) :
                            bullets.map((bullet,i) => <span key={i}>
                            {
                                attachBullet(includeBullet, bullet)
                            }
                            <br /></span> )
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className= "ButtonContainer">
                    <Button
                        className="whiteButton"
                        block
                        bsSize="large"
                        onClick={handleCloseModalPopover}
                    >
                        Got it
                    </Button>
                </div>
            </Modal.Footer>
            </Modal>
        </div>
    )
};

function condicoExpiry(condicoData) {
    var condicoExpiry = undefined;
    if (!!condicoData.expiry) {
        condicoExpiry = condicoData.expiry;
    }
    return condicoExpiry;
}


function condicoAmt(condicoData) {
    var condicoAmt = undefined;
    if (!!condicoData.payment && !!condicoData.payment.condicoAmount) {
        condicoAmt = condicoData.payment.condicoAmount
    }
    return condicoAmt;
}

function attachBullet(include, str){
    if (include) {
        return "\u{2022} " + str;
    }
    return str;
}

function displayImageForType(imageType) {
    var img;
    switch (imageType) {
        case config.ConfirmationModalImageType.created:
            return newImg;
        case config.ConfirmationModalImageType.rescinded:
            return rescindedImg;
        case config.ConfirmationModalImageType.completed:
            return completedImg;
        case config.ConfirmationModalImageType.attempted:
            return attemptedImg;
        case config.ConfirmationModalImageType.info:
            return infoImg;
        case config.ConfirmationModalImageType.payment:
            return paymentImg;
        case config.ConfirmationModalImageType.unsubscribed:
            return unsubscribedImg;
        default:
        utilityFunctions.log("no matching image type");
    }
    return img;
}

export default GenericModal;