import { API } from "aws-amplify";
import React, {Component} from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';

import config from '../config'
import utilityFunctions from "../utilityFunctions";

class StripeElementsWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
          sources: undefined
        }
    }

    async componentDidMount() {
        this.props.hideLoader(false);
        try {
          let retrieveCardsResponse = await API.get("payments", "/card")
          utilityFunctions.log(`retrieveCardsResponse ${JSON.stringify(retrieveCardsResponse)}`);
          this.setState({
            allSources: retrieveCardsResponse.sources
          });
        } catch (e) {
          if (!!e.response && !!e.response.data) {
            if(e.response.data.message === "Not authorized") {
              utilityFunctions.log("NOT AUTHORIZED");
              this.props.handleLogout();
              return;
            }
            this.props.handleSetAppError();
            return
          }
          this.props.handleSetAppError();
        }
        this.props.hideLoader(true);
    }

    render() {
        return (
        <StripeProvider apiKey={config.getEnvironmentConfig(config.Environment).stripePublishableKey}>
            <div className="example">
              <Elements>
                  <CheckoutForm childProps={this.props}/>
              </Elements>
            </div>
        </StripeProvider>
        );
    }
}

export default StripeElementsWrapper;