import { Auth, API } from "aws-amplify";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Breadcrumb, Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup, Modal, OverlayTrigger, Popover, Radio, Row } from "react-bootstrap";
import rescindedImg from "../assets/myCondico/cell/rescinded.png";
import completedImg from "../assets/myCondico/cell/completed.png";
import config from "../config";
import "./CreateCondico.css";
import utilityFunctions from "../utilityFunctions";

export default class CreateCondico extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.floorChosen = this.floorChosen.bind(this);
    this.ceilingChosen = this.ceilingChosen.bind(this);
    this.handleCloseCCModal = this.handleCloseCCModal.bind(this);
    this.handleClosePromoModal = this.handleClosePromoModal.bind(this);
    this.state = {
      displayAmount: 0,
      creationData: undefined,
      validForm: false,
      partnerConfirmationEmail: '',
      hasEnteredAmount: false,
      defaultCCExists: undefined,
      displayMissingCreditCardModal: false,
      displayPromoDetailsModal: false
    };
  }

  async componentWillMount() {
    utilityFunctions.isAuthenticated(this.props);
    utilityFunctions.log("createCondico componentWillMount");
    try {
      let retrieveCardsResponse = await API.get("payments", "/card")
      utilityFunctions.log(`retrieveCardsResponse ${JSON.stringify(retrieveCardsResponse)}`);
      if (retrieveCardsResponse.sources.length > 0) {
        utilityFunctions.log(`retrieveCardsResponse.sources[0] ${retrieveCardsResponse.sources[0]}`);
        this.setState({
          defaultCCExists: true
        });
        this.props.setLast4(retrieveCardsResponse.sources[0].last4);
      }
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
  }

  // need to store then retrieve from session storage displayAmt and partners email
  async componentDidMount() {
    utilityFunctions.log("createCondico componentDidMount");
    if (!!this.props.userInfo) {
      if (!!this.props.userInfo.business && !this.props.userInfo.business.verified) {
        utilityFunctions.log("unverified business and should not be at create so pushing back");
        this.props.history.push('/mycondicos');
      } else {
        var creationData = {};
        try {
          let user = await Auth.currentAuthenticatedUser();
          if (!!user) {
            if (!!this.props.userInfo && !!this.props.cognitoIdentityId) {
              let cachedData = utilityFunctions.retrieveCondicoDataFromSessionStorage(this.props.cognitoIdentityId);
              utilityFunctions.log(`createCondico componentDidMount 2 ${JSON.stringify(cachedData)}`);
              if (!!cachedData) {
                utilityFunctions.log("createCondico componentDidMount 3");
                creationData = cachedData.creationData;
                utilityFunctions.log(`createCondico componentDidMount 4 ${JSON.stringify(creationData)}`);
                delete creationData.promo;
                utilityFunctions.log("createCondico componentDidMount 5");
                this.setState({ creationData: creationData, displayAmount: cachedData.displayAmount, partnerConfirmationEmail: cachedData.partnerConfirmationEmail  }, () => this.validateForm());
                utilityFunctions.log("createCondico componentDidMount 6");
                utilityFunctions.log(user);
                return;
              }
            }
            utilityFunctions.log("createCondico componentDidMount A");
            creationData = {
              action: config.CondicoAction.create,
              random: this.arc4random(),
              initiator: {
                firstname: user.attributes['custom:givNameCus'],
                lastname: user.attributes['custom:famName_cus'],
                email: user.attributes.email,
                partnersIntentFirstname: '',
                partnersIntentLastname: '',
                amount: 0
              },
              partner: {
                email: ''
              },
              description: '',
              isComplete: false,
              app: 'web'
            };
            if (!!this.props.creationDataUnauthenticated) {
              utilityFunctions.log(`creationDataUnauthenticated not empty in create condico so taking that value for pre filling form ${JSON.stringify(this.props.creationDataUnauthenticated)}`);
              creationData = this.props.creationDataUnauthenticated;
              this.props.clearCreationDataUnauthenticated();
              creationData.initiator.firstname = user.attributes['custom:givNameCus'];
              creationData.initiator.lastname = user.attributes['custom:famName_cus'];
              creationData.initiator.email = user.attributes.email;
              this.setState({ partnerConfirmationEmail: creationData.partner.email, creationData, displayAmount: this.props.unAuthDisplayAmt }, () => this.validateForm());
            } else {
              utilityFunctions.log(`creationDataUnauthenticated empty`);
              this.setState({ creationData }, () => this.validateForm());
            }
            utilityFunctions.log(user);
          }
        }
        catch (e) {
          utilityFunctions.log(e);
          if (e !== 'No current user') {
            alert(e);
          }
        }
      }
    }
  }

  arc4random() {
    return Math.random()*4294967296;
  }

  validateForm() {
    var creationData = this.state.creationData;
    if (!!this.state.creationData) {
      if (this.getPartnerFirstnameValidationState() === "success" && 
          this.getPartnerLastnameValidationState() === "success" && 
          this.getPartnerEmailValidationState() === "success" &&
          this.getPartnerConfirmationEmailValidationState() === "success" &&
          this.getMatterValidationState() === "success" &&
          (!!this.state.creationData.initiator.acceptFloor || !!this.state.creationData.initiator.payCeiling ) &&
          this.getAmountValidationState() === "success" ) {
            utilityFunctions.log(`validation passed. ${this.getPartnerFirstnameValidationState()}`);
            creationData.isComplete = true;
            this.setState({validForm: true, creationData}, () => this.props.setCondicoData({creationData: this.state.creationData, displayAmount: this.state.displayAmount, partnerConfirmationEmail: this.state.partnerConfirmationEmail }));
            return
          }
    }
    utilityFunctions.log("validation failed");
    creationData.isComplete = false;
    this.setState({validForm: false, creationData}, () => this.props.setCondicoData({creationData: this.state.creationData, displayAmount: this.state.displayAmount, partnerConfirmationEmail: this.state.partnerConfirmationEmail }));
    return;
  }

  handlePartnerFirstnameChange = event => {
    var creationData = this.state.creationData;
    creationData.initiator.partnersIntentFirstname = event.target.value;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getPartnerFirstnameValidationState() {
    const length = this.state.creationData.initiator.partnersIntentFirstname.length;
    if (length > 1 && utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentFirstname)) return 'success';
    else if (length > 1 && !utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentFirstname)) return 'error';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handlePartnerLastnameChange = event => {
    var creationData = this.state.creationData;
    creationData.initiator.partnersIntentLastname = event.target.value;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getPartnerLastnameValidationState() {
    const length = this.state.creationData.initiator.partnersIntentLastname.length;
    if (length > 1 && utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentLastname)) return 'success';
    else if (length > 1 && !utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentLastname)) return 'error';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handlePartnerEmailChange = event => {
    var creationData = this.state.creationData;
    creationData.partner.email = event.target.value;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getPartnerEmailValidationState() {
    const length = this.state.creationData.partner.email.length;
    if ((utilityFunctions.isEmail(this.state.creationData.partner.email)) && (this.state.creationData.partner.email.toLowerCase() !== this.state.creationData.initiator.email.toLowerCase())) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handlePartnerConfirmationEmailChange = event => {
    var partnerConfirmationEmail = event.target.value;
    this.setState({partnerConfirmationEmail}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getPartnerConfirmationEmailValidationState() {
    const length = this.state.partnerConfirmationEmail.length;
    if (utilityFunctions.isEmail(this.state.partnerConfirmationEmail) && (this.state.partnerConfirmationEmail.toLowerCase() === this.state.creationData.partner.email.toLowerCase())) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  handleMatterChange = event => {
    var creationData = this.state.creationData;
    creationData.description = event.target.value;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  getMatterValidationState() {
    const length = this.state.creationData.description.length;
    if ( length <= config.MAX_MATTER_LENGTH && length >= config.MIN_MATTER_LENGTH ) return 'success';
    else if ( length > 0 ) return 'error';
    return null;
  }

  floorChosen() {
    var creationData = this.state.creationData;
    creationData.initiator.acceptFloor = true;
    delete creationData.initiator.payCeiling;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`accepting floor`);
  }

  ceilingChosen() {
    var creationData = this.state.creationData;
    creationData.initiator.payCeiling = true;
    delete creationData.initiator.acceptFloor;
    this.setState({creationData}, () => this.validateForm());
    utilityFunctions.log(`accepting ceiling`);
  }

  handleAmountChange = event => {
    var creationData = this.state.creationData;
    var displayAmount = this.state.displayAmount;
    if (event.target.value !== '') {
      var amt = event.target.value;
      // replace all previous occurances of styled commas with empty character ex. 5,000,000 becomes 5000000
      amt = amt.replace(/[,]+/g, '');
      // remove all preceeding zeros ex. 00050 becomes 50
      amt = amt.replace(/^0+/, '');
      //check if input contains only 0-9 and 1 period. Otherwise dont update the field
      var notInSet = /[^0-9.]/.test(amt);
      var periodCount = (amt.match(/\./g) || []).length;
      utilityFunctions.log(`inSet ${!notInSet} periodCount ${periodCount}`);
      if (!notInSet && periodCount <= 1) {
        // check that input only contains 2 digits after period
        if (periodCount === 1) {
          
          utilityFunctions.log(`decimal count is 1 ${amt}`);
          var periodSplit = amt.split('.');
          
          // check that we dont have more than 2 digits on right of decimal ex. 00.004722
          if (periodSplit[1].length <= 2) {
            utilityFunctions.log(`right side of decimal is less than 2`);
            
            // handle case for creation data amount where right side doesnt have value yet or full amount
            if (periodSplit[1].length === 2) {
              creationData.initiator.amount = `${periodSplit[0]}${periodSplit[1]}`;
            } else if (periodSplit[1].length === 1) {
              creationData.initiator.amount = `${periodSplit[0]}${periodSplit[1]}0`;
            } else if (periodSplit[1].length === 0) {
              creationData.initiator.amount = `${periodSplit[0]}00`;
            }
            creationData.initiator.amount = parseInt(creationData.initiator.amount, 10);
            amt = `${periodSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${periodSplit[1]}`;
            displayAmount = amt;
            this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
            return;
          } else {
           utilityFunctions.log('user entered more than 2 digits right of decimal so not updating');
          }
        } else if (periodCount === 0) {
          creationData.initiator.amount = parseInt(`${amt}00`, 10);
          utilityFunctions.log('decimal count is 0 so just formating a integer value with commas');
          amt = amt.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          displayAmount = amt;
          this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
        } else {
          utilityFunctions.log('user entered 2nd period so not updating');
        }
      }
    } else {
      displayAmount = 0;
      creationData.initiator.amount = 0;
      this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
    };
  }

  getAmountValidationState() {
    if ( !!this.state.creationData.initiator.amount && this.state.creationData.initiator.amount > 0 ) return 'success';
    else if ( this.state.creationData.initiator.amount === 0 && this.state.hasEnteredAmount ) return 'error';
    return null;
  }

  handlePromoChange = event => {
    this.setState({promoCode: event.target.value.toUpperCase()});
    var creationData = this.state.creationData;
    delete creationData.promo;
    this.setState({creationData});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  isPromoValid = async event => {
    event.preventDefault();
    utilityFunctions.log("calling promo api /isValid path");
    this.props.hideLoader(false);
    try {
      let promoResponse = await this.validatePromo();
      this.checkResult(promoResponse);
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
      }
      this.props.handleSetAppError();
    }
  }

  validatePromo() {
    return API.get("promos", `/promos?isValid=${this.state.promoCode}`);
  }

  checkResult(promoResponse) {
    utilityFunctions.log(`promo response ${JSON.stringify(promoResponse)}`);
    this.setState({promo: promoResponse.promo, displayPromoDetailsModal: true});
    // display modal here
    this.props.hideLoader(true);
    if (promoResponse.promo.isValid) {
      var creationData = this.state.creationData;
      creationData.promo = {codeId: this.state.promoCode};
      this.setState({creationData});
    }
  }

  getPromoValidationState() {
    if ( !!this.state.creationData.promo ) return 'success';
    else if ( this.state.promoCode !== undefined && this.state.promoCode !== "" ) return null;
    return null;
  }

  handleFormSubmit = event => {
    event.preventDefault();
    if ((!!this.state.defaultCCExists && this.state.defaultCCExists) || (this.state.creationData.promo && this.state.promo.isValid && this.state.promo.skipCC)) {
      utilityFunctions.log("submitting form, seg to summary")
      this.props.setCondicoData({creationData: this.state.creationData, displayAmount: this.state.displayAmount, partnerConfirmationEmail: this.state.partnerConfirmationEmail })
      this.props.history.push("/mycondicos/create/createSummary");
    } else {
      this.setState({displayMissingCreditCardModal: true})
    }
  }

  handleClosePromoModal() {
    this.setState({ displayPromoDetailsModal: false });
  }

  handleCloseCCModal() {
    this.setState({ displayMissingCreditCardModal: false }, () => this.props.history.push("/profile"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  navigateToFeeSchedule(props) {
    this.props.history.push("/fees");
  }

  render() {
    const willingPopover = (
      <Popover id="modal-popover" title="Willing to accept/pay?">
        The amount you enter below remains confidential and is used only by our system to determine eligibility or perform a settlement. If your looking to receive a payout choose the first option, otherwise choose 'to pay'
      </Popover>
    );
    const matterPopover = (
      <Popover id="modal-popover" title="How to enter your matter?">
        Describe the dispute in a way that will make it clear to the other side what you are referring to.  For example, if the negotiation concerns unsatisfied invoice No. 123 rendered by ABC Company for $100, the description of the subject matter of the negotiation might read: “Satisfaction of invoice number 123 rendered by ABC Company in the amount of $100." By way of a further example, if the negotiation concerned payment for the painting of the garage at 123 Side St. on August 1, 2015, the description of the subject matter of the negotiation might read: "Payment for painting the garage at 123 Side St. on August 1, 2015." And if the negotiation concerned the settlement of a lawsuit, the description of the subject matter of the negotiation might read: "Full settlement of the action commenced in the City of Toronto under Court File No. CV-1600001
      </Popover>
    );
    return (
      !!this.state.creationData && this.props.userInfo ?
      <div style={{fontFamily: 'Roboto, helvetica'}}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
          <Breadcrumb.Item active>Build Your Condico</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <form onSubmit={this.handleFormSubmit}>
            {!!this.props.userInfo.business ? `${this.props.userInfo.business.name}, enter the information below:` : `${this.state.creationData.initiator.firstname}, enter the information below:`}<br/>
            <FormGroup
              controlId="partnersIntentFirstname"
              validationState={this.getPartnerFirstnameValidationState()}
            >
              <ControlLabel>Other Side's First Name:</ControlLabel>
              <FormControl
                type="text"
                onChange={this.handlePartnerFirstnameChange}
                value={this.state.creationData.initiator.partnersIntentFirstname}
                placeholder="min 2 characters"
              />
              <FormControl.Feedback />
              { (this.getPartnerFirstnameValidationState() === "success" || this.getPartnerFirstnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: Min 2, A-z, no special char</HelpBlock> } 
            </FormGroup>
            <FormGroup
              controlId="partnersIntentLastname"
              validationState={this.getPartnerLastnameValidationState()}
            >
              <ControlLabel>Other Side's Last Name:</ControlLabel>
              <FormControl
                type="text"
                onChange={this.handlePartnerLastnameChange}
                value={this.state.creationData.initiator.partnersIntentLastname}
                placeholder="min 2 characters"
              />
              <FormControl.Feedback />
              { (this.getPartnerLastnameValidationState() === "success" || this.getPartnerLastnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: Min 2, A-z, no special char</HelpBlock> } 
            </FormGroup>
            <FormGroup
              controlId="partnersEmail"
              validationState={this.getPartnerEmailValidationState()}
            >
              <ControlLabel>Other Side's Email:</ControlLabel>
              <FormControl
                type="email"
                onChange={this.handlePartnerEmailChange}
                value={this.state.creationData.partner.email}
                placeholder="name@domain.com"
              />
              <FormControl.Feedback />
              { (this.getPartnerEmailValidationState() === "success" || this.getPartnerEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. name@domain.com</HelpBlock> }
            </FormGroup>
            {
              utilityFunctions.isEmail(this.state.creationData.partner.email) && 
              <FormGroup
                controlId="partnersConfirmationEmail"
                validationState={this.getPartnerConfirmationEmailValidationState()}
              >
                <ControlLabel>Confirm Other Side's Email:</ControlLabel>
                <FormControl
                  type="email"
                  onChange={this.handlePartnerConfirmationEmailChange}
                  value={this.state.partnerConfirmationEmail}
                  placeholder="name@domain.com"
                />
                <FormControl.Feedback />
                { (this.getPartnerConfirmationEmailValidationState() === "success" || this.getPartnerConfirmationEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Send to the right person. Make sure emails match</HelpBlock> } 
              </FormGroup>
            }
            <ControlLabel>Matter To Resolve:</ControlLabel><OverlayTrigger rootClose trigger="click" overlay={matterPopover} placement="bottom"><img style={{marginLeft: "5px"}} src={require("../assets/myCondico/infoBtn.png")} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger>
            <FormGroup 
              controlId="matter"
              validationState={this.getMatterValidationState()}
              >
              <FormControl
                onChange={this.handleMatterChange}
                value={this.state.creationData.description}
                componentClass="textarea"
              />
              <FormControl.Feedback />
              { (this.getMatterValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock style={(this.getMatterValidationState() === "success" || this.getMatterValidationState() === null) ? {color: 'green'} : {color: 'red'}}>{`Char min ${config.MIN_MATTER_LENGTH}, max ${config.MAX_MATTER_LENGTH}. Char remaining: ${config.MAX_MATTER_LENGTH - this.state.creationData.description.length}`}</HelpBlock> }
            </FormGroup>
            As initiator of this condico, I am willing <OverlayTrigger rootClose trigger="click" overlay={willingPopover} placement="top"><img src={require("../assets/myCondico/infoBtn.png")} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger>
            <FormGroup>
              <Radio checked={this.state.creationData.initiator.acceptFloor !== undefined ? true : false} onClick={this.floorChosen} name="radioGroupWilling" >
                to accept (a minimum of)
              </Radio>
              <Radio checked={this.state.creationData.initiator.payCeiling !== undefined ? true : false} onClick={this.ceilingChosen} name="radioGroupWilling">
                to pay (a maximum of)
              </Radio>
            </FormGroup>
            <FormGroup
              controlId="amount"
              validationState={this.getAmountValidationState()}
            >
              <InputGroup>
                <InputGroup.Addon>(CAD) C$</InputGroup.Addon>
                <FormControl
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      ReactDOM.findDOMNode(this.refs['amtField']).blur();
                    }
                  }}
                  ref="amtField"
                  autoComplete="off"
                  type="text"
                  onChange={this.handleAmountChange}
                  value={String(this.state.displayAmount)}
                />
                <FormControl.Feedback />
                { (this.getAmountValidationState() === "success" || this.getAmountValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. amount $1.00</HelpBlock> }
              </InputGroup>
              to resolve this matter.
            </FormGroup>
            <i>Promotions</i>
            <div style={{display: "flex", flexDirection:"row", justifyContent: "flex-start"}}>
              <FormGroup
                controlId="promo"
                validationState={this.getPromoValidationState()}
              >
                <InputGroup>
                  <FormControl
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        ReactDOM.findDOMNode(this.refs['promoField']).blur();
                      }
                    }}
                    ref="promoField"
                    autoComplete="off"
                    type="text"
                    placeholder="ENTER PROMO CODE"
                    onChange={this.handlePromoChange}
                    value={this.state.promoCode}
                    style={{width: "280px"}}
                  />
                  <FormControl.Feedback />
                  <button
                      disabled={!!this.state.creationData.promo || this.state.promoCode === undefined || this.state.promoCode === ""}
                      block
                      bsSize="large"
                      color={(this.state.creationData.promo === undefined || this.state.promoCode === undefined || this.state.promoCode === "") ? config.ThemeColor : 'white'}
                      onClick={(e) => this.isPromoValid(e)}
                      style={{borderColor: "transparent", backgroundColor: "lightGrey"}}
                    >
                      <span style={{ fontWeight: 'bold', color: (this.state.creationData.promo === undefined || this.state.promoCode === undefined || this.state.promoCode === "") ? config.ThemeColor : 'white'}}>Apply</span>
                    </button>
                </InputGroup>
              </FormGroup>
            </div>
            <div className= "ButtonContainer">
              <Button
                type="submit"
                className="button"
                disabled={!this.state.validForm}
                block
                bsSize="large"
              >
                <font face="Roboto, helvetica" color="white">Continue</font>
              </Button>
            </div>
          </form>
          <div className="static-modal">
            <Modal show={this.state.displayMissingCreditCardModal}>
              <Modal.Header>
                <Modal.Title>Missing Credit Card Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please add credit card information to your profile.  There’s no charge to initiate a condico, but charges apply where a settlement is achieved, according to the <button onClick={() => this.navigateToFeeSchedule()} style={{backgroundColor: 'transparent', border: 'none', color:"var(--green_theme)"}}>Fee Schedule</button>.</Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleCloseCCModal}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="static-modal">
            <Modal centered show={this.state.displayPromoDetailsModal}>
              <Modal.Header>
                <Modal.Title>
                  <Row className="show-grid">
                    <Col xs={2} md={2} lg={2}>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center"}}>
                        {!!this.state.promo && !!this.state.promo.organization ? <img style={{marginBottom:"-20px"}} src={completedImg} height="35" width="35" alt="stateImage" /> : <img style={{marginBottom:"-20px"}} src={rescindedImg} height="35" width="35" alt="promoResult" />}<br />
                        {!!this.state.promo && !!this.state.promo.organization ? this.state.promo.organization : 'Promo Not Applied'}
                      </div>
                    </Col>
                    <Col xs={2} md={2} lg={2}>
                    </Col>
                  </Row>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!!this.state.promo ? this.state.promo.reason : ""}
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleClosePromoModal}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}