import { API } from "aws-amplify";
import React, { Component } from "react";
import { Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button, ListGroup, ListGroupItem, Modal } from "react-bootstrap";

import "./MyCondicos.css";
import config from "../config.js";
import { myCondicoFilterTypes } from "../components/FiltersView.js";
import newImg from "../assets/myCondico/cell/pending.png";
import rescindedImg from "../assets/myCondico/cell/rescinded.png";
import attemptImg from "../assets/myCondico/cell/attempted.png";
import completedImg from "../assets/myCondico/cell/completed.png";
import expiredImg from "../assets/myCondico/cell/expired.png";
import pendingPaymentImg from "../assets/myCondico/cell/pendingPayment.png";
import FiltersView from "../components/FiltersView";
import utilityFunctions from "../utilityFunctions";

export default class MyCondicos extends Component {
  
  constructor(props) {
    super(props);
    this.sortActiveCondicos = this.sortActiveCondicos.bind(this);
    this.sortInactiveCondicos = this.sortInactiveCondicos.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleNewFilters = this.handleNewFilters.bind(this);
    this.handleCondicoClick = this.handleCondicoClick.bind(this);
    this.handleCloseUnverifiedBusinessModal = this.handleCloseUnverifiedBusinessModal.bind(this);
    this.state = {
      showFiltersBlock: true,
      allActiveTransactions: [],
      allInactiveTransactions: [],
      filteredActiveCondicos: [],
      filteredInactiveCondicos: [],
      isChildLoading: true,
      appliedFilters: {
        showInvited: true,
        showInitiated: true,
        sortBy: myCondicoFilterTypes.sortByDate,
        range: myCondicoFilterTypes.rangeAll,
        dateRange: {
          starting: "",
          ending: ""
        }
      },
      showingActive: true,
      fetchingInitialOrRefreshCondicos: false,
      fetchingInactiveCondicos: false,
      fetchingRange: false,
      showUnverifiedBusinessModal: false
    }
  }

  async componentWillMount() {
    utilityFunctions.log("myCondicos componentWillMount");
    utilityFunctions.isAuthenticated(this.props);
    var setActive = true;
    if (this.props.onloadSetSegCntrlToActive !== undefined) {
      if (!this.props.onloadSetSegCntrlToActive) {
        utilityFunctions.log(`appear if false`)
        setActive = this.props.onloadSetSegCntrlToActive;
      }
    } else if (!this.props.lastShowedActive) {
      utilityFunctions.log("did not come from Home view so checking app state for active inactive");
      setActive = false;
    }
    utilityFunctions.log(`setActive is ${setActive}`);
    this.setState({showingActive: setActive, isChildLoading: true});
  }

  async componentDidMount() {
    this.props.hideLoader(false);
    this.addListener()
    utilityFunctions.log("myCondicos-componentDidMount");
    if (this.props.appliedFilters.showInitiated || this.props.appliedFilters.showInvited) {
      utilityFunctions.log(`this.props.appliedFilters.showInitiated || this.props.appliedFilters.showInvited is true on myCondico mount`);
      if (!!this.props.myInitialLoadAndRefreshCondicos) {
        utilityFunctions.log("already have initial load of condicos so just need to refresh");
        if (this.props.appliedFilters.showInitiated || this.props.appliedFilters.showInvited) {
          this.setState({
            allActiveTransactions: this.props.allActiveTransactions, 
            allInactiveTransactions: this.props.allInactiveTransactions, 
            myInitialLoadAndRefreshCondicos: this.props.myInitialLoadAndRefreshCondicos, 
            myInactiveScrollCondicos: this.props.myInactiveScrollCondicos,
            appliedFilters: this.props.appliedFilters
          }, () => {
            if (this.state.appliedFilters.range === myCondicoFilterTypes.rangeAll) {
              this.grabMyCondicos("refresh");
            } else {
              this.grabMyCondicos("range");
            }
          });
        }
      } else {
        utilityFunctions.log("dont have initial load of condicos so need to do initialLoad");
        this.setState({
          appliedFilters: this.props.appliedFilters
        }, () => this.grabMyCondicos("initialLoad"));
      }
    } else {
      utilityFunctions.log(`this.props.appliedFilters.showInitiated || this.props.appliedFilters.showInvited is false on myCondico mount`);
      if (!!this.props.myInitialLoadAndRefreshCondicos) {
        utilityFunctions.log("already have initial load of condicos but not refreshing since init/invit filters not set");
        this.setState({
          allActiveTransactions: this.props.allActiveTransactions, 
          allInactiveTransactions: this.props.allInactiveTransactions, 
          myInitialLoadAndRefreshCondicos: this.props.myInitialLoadAndRefreshCondicos, 
          myInactiveScrollCondicos: this.props.myInactiveScrollCondicos,
          appliedFilters: this.props.appliedFilters
        });
      } else {
        this.setState({appliedFilters: this.props.appliedFilters});
      }
      this.props.hideLoader(true);
    }
  }

  componentWillUnmount() {
    this.removeListener()
  }

  addListener() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  removeListener() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  listenScrollEvent = (event) => {
    //lastScrollY = window.scrollY;
    let path = this.props.location.pathname.replace('/', '');
    if (path === 'myCondicos') {
      var windowY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (this.state.showingActive) {
        this.props.setMyCondicosScrollYForActive(windowY);
      } else if (!this.state.showingActive) {
        this.props.setMyCondicosScrollYForInActive(windowY);
      }
      utilityFunctions.log(`windowY ${windowY}`);

      // detecting when scroll ends for showing or hiding filters block:
      this.setState({
        showFiltersBlock: false
      })
      window.clearTimeout( this.state.showFiltersBlock );
      var isScrolling = setTimeout(function() {
        utilityFunctions.log( 'Scrolling has stopped.' );
        this.setState({showFiltersBlock: true});
      }.bind(this), 50);
      utilityFunctions.log(`isScrolling ${isScrolling}`);
      const wrappedElement = document.getElementById('scrollList');
      if (this.isBottom(wrappedElement)) {
        utilityFunctions.log('header bottom reached');
        if (!this.state.showingActive && !!this.state.myInactiveScrollCondicos && !!this.state.myInactiveScrollCondicos.scrollKeys) {
          if (!!this.state.myInactiveScrollCondicos.scrollKeys.initiatorTbl || !!this.state.myInactiveScrollCondicos.scrollKeys.partnerTbl) {
              utilityFunctions.log(`retrieving more inactive items becasue we are less than 90% from bottom and init last eval not nil (String(describing: myInactiveScrollCondicos?.inactiveTransactions?.initiatorLastEvaluatedKey)) OR part last eval key not nil (String(describing: myInactiveScrollCondicos?.inactiveTransactions?.partnerLastEvaluatedKey))`)
              this.grabMyCondicos("inactiveScroll");
          }
        }
      }
    }
  }

  isBottom(el) {
    let inactiveCallingPoint = parseInt(window.innerHeight * 1.2, 10);
    utilityFunctions.log(`window.innerHeight ${window.innerHeight} el.getBoundingClientRect().bottom ${el.getBoundingClientRect().bottom} 80% ${inactiveCallingPoint}`)
    return el.getBoundingClientRect().bottom <= inactiveCallingPoint;
  }

  getMyCondicos(queryString) {
    utilityFunctions.log("retrieving transctions in myCondicos");
    return API.get("transactions", `/transactions/${queryString}`);
  }

  async grabMyCondicos(apiType) {
    if ((apiType === "initialLoad" || apiType === "refresh") && this.state.fetchingInitialOrRefreshCondicos) {
      utilityFunctions.log("fetch already in progress - returning pre-emptively")
      return
    }
    if (apiType === "inactiveScroll" && this.state.fetchingInactiveCondicos) {
      utilityFunctions.log("fetch already in progress - returning pre-emptively")
      return
    }
    this.props.hideLoader(false);
    // ?? should we do the rest after state sets fetching condicos. Should we also split this var into 2 - 1 for initial/refresh and 1 for inactive scroll??
    utilityFunctions.log("invoking api grab myCondicos");
    var startLastRefreshDateTime = undefined;
    var queryString = "";
    switch (apiType) {
      case 'initialLoad':
        this.setState({fetchingInitialOrRefreshCondicos: true});
        startLastRefreshDateTime = (new Date()).toISOString();
        queryString += "?apiType=initialLoad";
        this.props.setEndRefreshDateTime(undefined);
        utilityFunctions.log(`setting query string for initialLoad ${queryString}`);
        break;
      case 'range':
        this.setState({fetchingRange: true});
        utilityFunctions.log(`starting string ${this.state.appliedFilters.dateRange.starting}`);
        var startingDate = new Date(this.state.appliedFilters.dateRange.starting);
        var tzDifference = startingDate.getTimezoneOffset();
        var newStartingDate = new Date(startingDate.getTime() + (tzDifference * 1000 * 60));
        utilityFunctions.log(`timezone adjusted newStartingDate date ${newStartingDate.toISOString()}`);
        var endingDate = new Date(this.state.appliedFilters.dateRange.ending + "T23:59:59.999Z");
        var newEndingDate = new Date(endingDate.getTime() + (tzDifference * 1000 * 60));
        utilityFunctions.log(`timezone adjusted newEndingDate date ${newEndingDate.toISOString()}`);
        queryString += `?apiType=range&starting=${newStartingDate.toISOString()}&ending=${newEndingDate.toISOString()}`;
        utilityFunctions.log(`setting query string for range ${queryString}`);
        break;
      case 'inactiveScroll':
        this.setState({fetchingInactiveCondicos: true});
        queryString += "?apiType=inactiveScroll"
        if (!!this.state.myInactiveScrollCondicos && !!this.state.myInactiveScrollCondicos.scrollKeys && !!this.state.myInactiveScrollCondicos.scrollKeys.initiatorTbl) {
          queryString += "&initiatorDateTimeLastEvaluatedKey=" + this.state.myInactiveScrollCondicos.scrollKeys.initiatorTbl.dateTime;
        }
        if (!!this.state.myInactiveScrollCondicos && !!this.state.myInactiveScrollCondicos.scrollKeys && !!this.state.myInactiveScrollCondicos.scrollKeys.partnerTbl) {
          queryString += "&partnerDateTimeLastEvaluatedKey=" + this.state.myInactiveScrollCondicos.scrollKeys.partnerTbl.dateTime + "&userIdLastEvaluatedKey=" + this.state.myInactiveScrollCondicos.scrollKeys.partnerTbl.userId;
        }
        utilityFunctions.log(`setting query string for inactiveScroll ${queryString}`);
        break;
      case 'refresh':
        this.setState({fetchingInitialOrRefreshCondicos: true});
        startLastRefreshDateTime = (new Date()).toISOString();
        queryString += "?apiType=refresh";
        if (!!this.props.endRefreshDateTime) {
          queryString += `&lastRefresh=${this.props.endRefreshDateTime}`
          this.props.setEndRefreshDateTime(undefined);
        }
        // this is the key for the last active entry obtained from init table and part table - 1. Scan occurs in reverse so we ensure that we get updates for all active transctions since last Read.
        if (!!this.state.myInitialLoadAndRefreshCondicos && !!this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes && !!this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes.initiatorTbl) {
          queryString += "&initiatorDateTimeLastEvaluatedKey=" + this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes.initiatorTbl;
        } else {
          utilityFunctions.log("missing refresh key");
          this.props.handleSetAppError();
          return;
        }
        if (!!this.state.myInitialLoadAndRefreshCondicos && !!this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes && !!this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes.partnerTbl) {
          queryString += "&partnerDateTimeLastEvaluatedKey=" + this.state.myInitialLoadAndRefreshCondicos.refreshDateTimes.partnerTbl;
        } else {
          utilityFunctions.log("missing refresh key");
          this.props.handleSetAppError();
          return;
        }
        break;
      default:
        utilityFunctions.log("unrecognized api type");
        this.props.handleSetAppError();
    }
    utilityFunctions.log(`applied filters at point of deciding to include init or part table ${JSON.stringify(this.state.appliedFilters)}`)
    if (this.state.appliedFilters.showInitiated) {
      utilityFunctions.log("queryingInitiatorTable");
      queryString += "&queryInitiatorTable=true"
    }
    if (this.state.appliedFilters.showInvited) {
      utilityFunctions.log("queryingPartnerTable");
      queryString += "&queryPartnerTable=true"
    }
    utilityFunctions.log(`setting query string for refresh ${queryString}`);
    try {
      let response = await this.getMyCondicos(queryString);
      utilityFunctions.log(`obtained api response ${JSON.stringify(response)}`);
      var manualScroll = true;
      if (!!response.myCondicos && !!response.myCondicos.success && response.myCondicos.success) {
        switch (apiType) {
          case 'initialLoad':
            utilityFunctions.log("response is for initial Load");
            this.props.setEndRefreshDateTime(startLastRefreshDateTime);
            if (!this.state.fetchingInitialOrRefreshCondicos) {
              return;
            }
            let activeCondicos, inactiveCondicos;
            if (!!response.myCondicos.activeTransactions && !!response.myCondicos.inactiveTransactions) {
              this.setState({myInitialLoadAndRefreshCondicos: response.myCondicos, myInactiveScrollCondicos: response.myCondicos});
              this.props.setMyInitialLoadAndRefreshCondicos(response.myCondicos);
              this.props.setMyInactiveScrollCondicos(response.myCondicos);
              activeCondicos = response.myCondicos.activeTransactions.condicos.length === 0 ? [] : response.myCondicos.activeTransactions.condicos;
              this.props.setAllActiveTransactions(activeCondicos);
              inactiveCondicos = response.myCondicos.inactiveTransactions.condicos.length === 0 ? [] : response.myCondicos.inactiveTransactions.condicos;
              this.props.setAllInactiveTransactions(inactiveCondicos);
              if (this.state.showingActive) {
                this.setState({fetchingInitialOrRefreshCondicos: false, allActiveTransactions: activeCondicos, allInactiveTransactions: inactiveCondicos}, () => this.sortActiveCondicos(manualScroll));
              } else {
                this.setState({fetchingInitialOrRefreshCondicos: false, allActiveTransactions: activeCondicos, allInactiveTransactions: inactiveCondicos}, () => this.sortInactiveCondicos(manualScroll));
              }
            }
            break;
          case 'range':
            utilityFunctions.log("response is for range.some");
            if (!this.state.fetchingRange) {
              return;
            }
            this.setState({myInitialLoadAndRefreshCondicos: response.myCondicos, myInactiveScrollCondicos: response.myCondicos});
            this.props.setMyInitialLoadAndRefreshCondicos(response.myCondicos);
            this.props.setMyInactiveScrollCondicos(response.myCondicos);
            if (!!response.myCondicos.activeTransactions && !!response.myCondicos.activeTransactions.condicos) {
              let condicos = response.myCondicos.activeTransactions.condicos.length === 0 ? [] : response.myCondicos.activeTransactions.condicos;
              this.setState({fetchingRange: false, allActiveTransactions: condicos}, () => this.sortActiveCondicos(manualScroll));
              this.props.setAllActiveTransactions(condicos);
            }
            if (!!response.myCondicos.inactiveTransactions && !!response.myCondicos.inactiveTransactions.condicos) {
              let condicos = response.myCondicos.inactiveTransactions.condicos.length === 0 ? [] : response.myCondicos.inactiveTransactions.condicos;
              this.setState({fetchingRange: false, allInactiveTransactions: condicos}, () => this.sortInactiveCondicos(manualScroll));
              this.props.setAllInactiveTransactions(condicos);
            }
            break;
          case 'inactiveScroll':
            utilityFunctions.log("response is for inactive scroll");
            if (!this.state.fetchingInactiveCondicos) {
              return;
            }
            this.setState({myInactiveScrollCondicos: response.myCondicos});
            this.props.setMyInactiveScrollCondicos(response.myCondicos);
            if (!!response.myCondicos.inactiveTransactions && !!response.myCondicos.inactiveTransactions.condicos && response.myCondicos.inactiveTransactions.condicos.length > 0) {
              let condicos = this.state.allInactiveTransactions.concat(response.myCondicos.inactiveTransactions.condicos);
              this.setState({fetchingInactiveCondicos: false, allInactiveTransactions: condicos}, () => this.sortInactiveCondicos(manualScroll));
              this.props.setAllInactiveTransactions(condicos);
              //this.handleLoadOfActiveCondicos();
            }
            break;
          case 'refresh':
            this.props.setEndRefreshDateTime(startLastRefreshDateTime);
            utilityFunctions.log("response is for refresh");
            if (!this.state.fetchingInitialOrRefreshCondicos) {
              return;
            }
            if (!!response.myCondicos.noUpdates && response.myCondicos.noUpdates) {
              utilityFunctions.log("no updates from refresh api");
              this.setState({fetchingInitialOrRefreshCondicos: false}, () => {
                this.sortActiveCondicos(manualScroll);
                this.sortInactiveCondicos(manualScroll);
              });
            } else {
              utilityFunctions.log("updates exist from refresh api");
              this.setState({myInitialLoadAndRefreshCondicos: response.myCondicos});
              this.props.setMyInitialLoadAndRefreshCondicos(response.myCondicos);
              var activeEmpty = false, inactiveEmpty = false;
              if (!!response.myCondicos.activeTransactions && !!response.myCondicos.activeTransactions.condicos) {
                this.setState({fetchingInitialOrRefreshCondicos: false, allActiveTransactions: response.myCondicos.activeTransactions.condicos}, () => this.sortActiveCondicos(manualScroll));
                this.props.setAllActiveTransactions(response.myCondicos.activeTransactions.condicos);
              } else {
                activeEmpty = true;
              }
              if (!!response.myCondicos.inactiveTransactions && !!response.myCondicos.inactiveTransactions.condicos && response.myCondicos.inactiveTransactions.condicos.length > 0 ) {
                var inactiveMyCondicos = this.state.myInactiveScrollCondicos;
                inactiveMyCondicos.inactiveTransactions.totalCount = response.myCondicos.inactiveTransactions.totalCount;
                this.props.setMyInactiveScrollCondicos(inactiveMyCondicos);
                this.mergeAintoBNoDupUnsortedArrays(this.state.allInactiveTransactions, response.myCondicos.inactiveTransactions.condicos, (condicos) => {
                  this.setState({fetchingInitialOrRefreshCondicos: false, allInactiveTransactions: condicos}, () => this.sortInactiveCondicos(manualScroll));
                  this.props.setAllInactiveTransactions(condicos);
                });
              } else {
                inactiveEmpty = true;
              }
              if (activeEmpty && inactiveEmpty) {
                this.setState({fetchingInitialOrRefreshCondicos: false});
              }
            }
            break;
          default:
            utilityFunctions.log("unrecognized response api type");
            this.props.handleSetAppError();
        }
      } else {
        utilityFunctions.log("myCondicos api error");
        this.props.handleSetAppError();
      }
    } catch (e) {
      utilityFunctions.log(`catch of mycondicos grab MyCondicos - apiType ${apiType} ${e}`);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
    this.setState({isChildLoading: false});
  }

  mergeAintoBNoDupUnsortedArrays(condicoArrayA, condicoArrayB, callback) {
    // take snapshot of myInitiatedCondicos loop through each and active initiated and merge them. Take thesse condicos and whichever lastEval key is latest and assign into initiatedActiveMyCondicos
    var mergedCondicos = condicoArrayA;
    if (!!condicoArrayA && !!condicoArrayB) {
      utilityFunctions.log("A & B not nil-merging with A & B not nil")
      for (var i = 0; i < condicoArrayB.length; i++) {
        let index = this.containsCondico(condicoArrayA, condicoArrayB[i]);
        if (index !== undefined) {
          utilityFunctions.log(`index found for matching condico ${index}`);
          mergedCondicos[index] = condicoArrayB[i];
        } else {
          utilityFunctions.log(`index NOT found for matching condico so pushing onto stack ${JSON.stringify(condicoArrayB[i])}`);
          mergedCondicos.push(condicoArrayB[i]);
        }
      }
    } else if ( condicoArrayA === undefined || condicoArrayA === null) {
      utilityFunctions.log("B & A is nil-merging with B nil");
      mergedCondicos = condicoArrayB;
    } else if ( condicoArrayB === undefined || condicoArrayB === null ) {
      utilityFunctions.log("A & B nil-merging with B nil");
      mergedCondicos = condicoArrayA;
    } else {
      utilityFunctions.log("merging with A & B nil");
      mergedCondicos = [];
    }
    return callback(mergedCondicos);
  }

  containsCondico(condicoArray, condico) {
    utilityFunctions.log(`contains func input array ${JSON.stringify(condicoArray)} condico ${JSON.stringify(condico)}`);
    var containsValue = undefined;
    for (var i = 0; i < condicoArray.length; i++) {
        if (condicoArray[i].userId === condico.userId && condicoArray[i].dateTime === condico.dateTime) {
            utilityFunctions.log("good")
            containsValue = i;
            return containsValue;
        }
    }
    utilityFunctions.log('containsCondico func - looped through all items and condico is not matching any in array');
    return containsValue;
  }

  getStateDescription(condico) {
    let isInitiator = (this.props.cognitoIdentityId === condico.userId);
    var associatesName = this.getAssociatesName(condico);
    switch (condico.state) {
      case config.CondicoState.new:
      case config.CondicoState.attempted:
        if (isInitiator) {
          return `Response from ${associatesName} pending`;
        } else {
          if (!!condico.lastFailedAttempt) {
            if (!!condico.initiator.payCeiling && condico.initiator.payCeiling === true) {
              return `To resolve this matter, ${condico.initiator.firstname} is prepared to pay less than your last offer indicated you were prepared to accept`;
            } else {
              return `To resolve this matter, ${condico.initiator.firstname} is prepared to accept more than your last offer indicated you were prepared to pay`;
            }
          }
          return "Action Needed";
        }
      case config.CondicoState.rescinded:
        return "Rescinded";
      case config.CondicoState.completed:
        return "Completed";
      case config.CondicoState.expired:
        return "Expired";
      case config.CondicoState.pendingPayment:
        return "Completed, but release of settlement figure delayed pending fee payment";
      default:
        utilityFunctions.log("no state defined in cell");
    }
  }

  displayImageForState(condico) {
    var img;
    let isInitiator = (this.props.cognitoIdentityId === condico.userId);
        switch (condico.state) {
          case config.CondicoState.new:
            img = newImg;
            if (!isInitiator) {
              img = attemptImg;
            }
            break;
          case config.CondicoState.rescinded:
            img = rescindedImg;
            break;
          case config.CondicoState.attempted:
            img = newImg;
            if (!isInitiator) {
              img = attemptImg;
            }
            break;
          case config.CondicoState.completed:
            img = completedImg;
            break;
          case config.CondicoState.expired:
            img = expiredImg;
            break;
          case config.CondicoState.pendingPayment:
            img = pendingPaymentImg;
            break;
          default:
            utilityFunctions.log("no state defined in cell");
        }
        return img;
  }

  getAssociatesName(condico) {
    var name = undefined;
    if (condico.userId === this.props.cognitoIdentityId) {
      if (!!condico.partner.firstname && !!condico.partner.lastname) {
        name = condico.partner.firstname + " " + condico.partner.lastname;
      } else {
        name = condico.initiator.partnersIntentFirstname + " " + condico.initiator.partnersIntentLastname;
      }
      if (!!condico.partner.business) {
        name = `${name} from ${condico.partner.business}`
      }
      return name;
    } else {
      name = condico.initiator.firstname + " " + condico.initiator.lastname;
      if (!!condico.initiator.business) {
        name = `${name} from ${condico.initiator.business}`;
      }
      return name;
    }
  }

  showVerified(condico) {
    if (condico.userId === this.props.cognitoIdentityId) {
      if (!!condico.partner.business) {
        return true;
      }
    } else if (!!condico.initiator.business) {
      return true;
    }
    return false;
  }

  handleCloseUnverifiedBusinessModal() {
    this.setState({ showUnverifiedBusinessModal: false });
  }

  handleCondicoClick = (event, path, condico) => {
    event.preventDefault();
    utilityFunctions.log("about to seg");
    if (condico === undefined) {
      utilityFunctions.log("seg to create");
      if (!!this.props.userInfo.business && !this.props.userInfo.business.verified) {
        this.setState({showUnverifiedBusinessModal: true});
      } else {
        this.props.history.push(path);
      }
    } else {
      this.props.handleCondicoSeging(condico);
      utilityFunctions.log("non create so need to perform check to where to seg");
    }
  }

  // Render Functions
  render() {
    return (
        !this.props.completedStartup ? <div style={{marginTop: '30px'}}>Loading...</div> :
        <div style={{marginTop: '30px', fontFamily: 'Roboto, helvetica'}} className="MyCondicos">
          {
            this.state.showFiltersBlock ? <div id="filtersBlock">{this.renderFiltersBlock()}</div> : null
          }
          <div style={{paddingTop:"30px"}}>
            {this.renderCondicos()}
          </div>
          <div className="static-modal">
            <Modal show={this.state.showUnverifiedBusinessModal}>
              <Modal.Header>
                <Modal.Title>Business account not yet verified:</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please send us an email to info@mycondico.com from your business or company email account.  Once we have verified your business or company email account, you will be free to build a condico.</Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleCloseUnverifiedBusinessModal}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
    );
  }

  renderCondicos() {
    let condicos = this.state.showingActive ? this.state.filteredActiveCondicos : this.state.filteredInactiveCondicos;
    return (
        <div className="condicos">
            <ListGroup id="scrollList">
              {!this.state.isChildLoading && this.props.completedStartup && this.renderCondicosList(condicos)}
            </ListGroup>
        </div>
    );
  }

  renderCondicosList(condicos) {
    let length = condicos.length;
    if (length === 0) {
      return (
        <div>
          <ListGroupItem
            key="new"
          >
            <div className="Create">    
              <MaterialButton onClick={(e) => this.handleCondicoClick(e, "/mycondicos/create", undefined)} variant="fab" mini style={{color: config.ThemeColor}} aria-label="Add" >
                <AddIcon />
              </MaterialButton>
              <h4 style={{fontFamily: 'Roboto, helvetica'}}>Create a new condico</h4>
            </div>
          </ListGroupItem>
          {this.renderEmptyCell()}
        </div>
      );
    } else {
      return [{}].concat(condicos).map(
        (condico, i) =>
          i !== 0
            ? this.renderListItem(condico)
            : <ListGroupItem
                key="new"
              >
                <div className="Create">    
                  <MaterialButton onClick={(e) => this.handleCondicoClick(e, "/mycondicos/create", undefined)} variant="fab" mini style={{color: config.ThemeColor}} aria-label="Add" >
                    <AddIcon />
                  </MaterialButton>
                  <h4 style={{fontFamily: 'Roboto, helvetica'}}>Create a new condico</h4>
                </div>
              </ListGroupItem>
      );
    }
  }

  renderEmptyCell(){
    return (
        <div className="emptyCell">
          {
            (this.state.showingActive) ? <h3>Your active transactions will be displayed here.</h3> : <h3>Your inactive transactions will be displayed here.</h3>
          }
          <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => this.grabMyCondicos("refresh")}><img src={ require("../assets/refresh.png") } height="30" width="30" alt="stateImage" /></button>
        </div>
    );
  }

  renderFiltersBlock() {
    return (
      <FiltersView
        handleRefresh={this.handleRefresh}
        handleNewFilters={this.handleNewFilters}
        currentlyAppliedFilters={this.state.appliedFilters}
        showingActive={this.state.showingActive}
      ></FiltersView>
    );
  }

  renderListItem(condico) {
    return (
      <ListGroupItem
          key={condico.userId + condico.dateTime}
          onClick={(e) => this.handleCondicoClick(e, `/mycondicos/${condico.userId}`, condico)}
        >
          { 
            (condico.state !== config.CondicoState.attempted && condico.state !== config.CondicoState.completed && condico.state !== config.CondicoState.expired && condico.state !== config.CondicoState.new && condico.state !== config.CondicoState.pendingPayment && condico.state !== config.CondicoState.rescinded) ? 
            this.renderInProgressTransaction(condico) :
            this.renderCondicoCell(condico)
          }
      </ListGroupItem>
    )
  }

  renderInProgressTransaction(condico) {
    return (
      <div>
        <span className="mycondicoCustomText">
          {`--Transaction with Id# ${condico.transaction} is processing. Refresh for updates.--`}
        </span>
      </div>
    );
  }

  renderCondicoCell(condico) {
    return (
      <div>
        <div className="condicoCell">
          <div className="mainContent">
            <h3 style={{marginRight: -2}}>Created:</h3><span>{`${new Date(condico.dateTime).toLocaleTimeString().toString()}, ${new Date(condico.dateTime).toDateString()}`}</span><br />
            <img src={this.displayImageForState(condico)} style={{marginLeft:"8px"}} height="18" width="18" alt="stateImage" /> <h3>{this.getStateDescription(condico)}</h3><br />
            <h3>Description of dispute:</h3><span>{condico.description}</span><br />
            <h3>Partner:</h3><span>{`${this.getAssociatesName(condico)} `}</span>
            {
                  this.showVerified(condico) && <img src={require("../assets/myCondico/cell/verified3x.png")} height="12" width="12" alt="verifiedCompany" />
            }
            <br />
            <div>
              <h3>Your Position:</h3><span>{utilityFunctions.getDisplayAmount(this.props.cognitoIdentityId, condico)}</span>
              {
                ( (condico.state === config.CondicoState.attempted || condico.state === config.CondicoState.new) && (condico.userId !== this.props.cognitoIdentityId) ) && <img src={require("../assets/myCondico/cell/attention@3x.png")} height="18" width="10" style={{paddingLeft:"5px"}} alt="attentionImage" />
              }
              <br />
            </div>
          </div>
          <div className="disclosureIndicatorBox">
            <img src={require("../assets/myCondico/cell/disclosureIndicator@3x.png")} height="20" width="12" alt="stateImage" />
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          {
            (condico.state !== config.CondicoState.completed && condico.state !== config.CondicoState.rescinded && condico.state !== config.CondicoState.expired) ? 
            <div>
              <img src={require("../assets/myCondico/cell/expire@3x.png")} height="12" width="12" style={{paddingRight:"3px"}} alt="expiresImage" />
              <span className="mycondicoCustomText">
                expires: {`${new Date(condico.expiry).toLocaleTimeString().toString()}, ${new Date(condico.expiry).toDateString()}`}
              </span>
            </div> : (condico.state === config.CondicoState.expired) ?
            <div>
              <span className="mycondicoCustomText">
                expired: {`${new Date(condico.expiry).toLocaleTimeString().toString()}, ${new Date(condico.expiry).toDateString()}`}
              </span>
            </div> : <span></span>
          }
          <span style={{marginTop: 5}} className="mycondicoCustomText">
            ID#: {condico.transaction}
          </span>
        </div>
      </div>
    );
  }

  handleRefresh() {
    this.state.appliedFilters.range === myCondicoFilterTypes.rangeSome ? this.grabMyCondicos("range") : this.grabMyCondicos("refresh");
  }

  handleNewFilters(appliedFilters, showingActive) {
    this.removeListener();
    this.props.setOnloadSetSegCntrlToActive(showingActive);
    this.props.setAppliedFilters(appliedFilters);
    utilityFunctions.log("filters view returned filters being applied");
    var resetModel = false
    utilityFunctions.log(`handleNewFilters: this.state.appliedFilters ${JSON.stringify(this.state.appliedFilters)} input: appliedFilters ${JSON.stringify(appliedFilters)} showingActive ${showingActive}`);
    if ((this.state.appliedFilters.showInitiated && !appliedFilters.showInitiated) ||
       (!this.state.appliedFilters.showInitiated && appliedFilters.showInitiated) ||
       (this.state.appliedFilters.showInvited && !appliedFilters.showInvited) ||
       (!this.state.appliedFilters.showInvited && appliedFilters.showInvited) || 
       (this.state.appliedFilters.range === myCondicoFilterTypes.rangeAll && appliedFilters.range === myCondicoFilterTypes.rangeSome) || 
       (this.state.appliedFilters.range === myCondicoFilterTypes.rangeSome && appliedFilters.range === myCondicoFilterTypes.rangeAll)){
         utilityFunctions.log("RESETTING data model");
        this.setState({allActiveTransactions: [], allInactiveTransactions: [], myInitialLoadAndRefreshCondicos: undefined, myInactiveScrollCondicos: undefined, filteredActiveCondicos: [], filteredInactiveCondicos: [] })
        this.props.setMyInitialLoadAndRefreshCondicos(undefined);
        this.props.setMyInactiveScrollCondicos(undefined);
        this.props.setAllActiveTransactions(undefined);
        this.props.setAllInactiveTransactions(undefined);
        resetModel = true
    }
    if (appliedFilters.range === myCondicoFilterTypes.rangeSome && 
       (appliedFilters.dateRange.starting !== this.state.appliedFilters.dateRange.starting || appliedFilters.dateRange.ending !== this.state.appliedFilters.dateRange.ending)) {
        resetModel = true;
    }
    var manualScroll = true; var activeShown = false;
    if (showingActive !== undefined && showingActive) {
      this.props.setLastShowedActive(true);
      activeShown = true;
    } else if (showingActive !== undefined && !showingActive) {
      this.props.setLastShowedActive(false);
    }
    if (resetModel) {
      manualScroll = false;
    }
    if (!resetModel) {
      utilityFunctions.log("unwind from filters and data set unchanged")
      if (showingActive !== undefined && showingActive) {
        this.setState({appliedFilters, showingActive: activeShown}, () => this.sortActiveCondicos(manualScroll));
      } else if (showingActive !== undefined && !showingActive) {
        this.setState({appliedFilters, showingActive: activeShown}, () => this.sortInactiveCondicos(manualScroll));
      } else {
        this.setState({appliedFilters});
      }
    } else {
        utilityFunctions.log("unwind from filters and data model changed")
        if (appliedFilters.showInitiated || appliedFilters.showInvited) {
          if (appliedFilters.range === myCondicoFilterTypes.rangeSome) {
            utilityFunctions.log("unwind from filters and api request required becasue date range is range.some");
            this.setState({appliedFilters, showingActive: activeShown, fetchingInitialOrRefreshCondicos: false, fetchingInactiveCondicos: false}, () => this.grabMyCondicos("range"));
          } else {
            utilityFunctions.log("unwind from filters and api request required becasue init or invited was changed");
            this.setState({appliedFilters, showingActive: activeShown, fetchingInitialOrRefreshCondicos: false, fetchingInactiveCondicos: false}, () => this.grabMyCondicos("initialLoad"));
          }
        } else {
          utilityFunctions.log("unwind from filters both init and invited empty so just reload tables")
          if (this.state.showingActive) {
            this.setState({appliedFilters, showingActive: activeShown}, () => this.sortActiveCondicos(manualScroll));
          } else {
            this.setState({appliedFilters, showingActive: activeShown}, () => this.sortInactiveCondicos(manualScroll));
          }
        }
    }
  }

  // Filter Functions
  sortActiveCondicos(manuallyScroll) {
    var filteredCondicos = this.state.allActiveTransactions
      .sort((previousCondico, nextCondico) => {
        if (this.state.appliedFilters.sortBy === myCondicoFilterTypes.sortByDate) {
          return new Date(nextCondico.dateTime) - new Date(previousCondico.dateTime);
        } else {
          utilityFunctions.log(`${config.CondicoState.new} config.CondicoState.new`)
          if(previousCondico.state === nextCondico.state) {
            return 0;
          } 
          if (previousCondico.state === config.CondicoState.pendingPayment && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.new && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.attempted && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment)  {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.completed && nextCondico.state !== config.CondicoState.completed && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.rescinded && nextCondico.state !== config.CondicoState.rescinded && nextCondico.state !== config.CondicoState.completed && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          }
          if (previousCondico.state === config.CondicoState.expired && nextCondico.state !== config.CondicoState.expired && nextCondico.state !== config.CondicoState.rescinded && nextCondico.state !== config.CondicoState.completed && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          }
          return 1;
        }
      });
      if (this.state.showingActive && manuallyScroll) {
          this.setState({filteredActiveCondicos: filteredCondicos}, () => this.scrollTo());
          return;
      }
      this.setState({filteredActiveCondicos: filteredCondicos});
  }

  sortInactiveCondicos(manuallyScroll) {
    var filteredCondicos = this.state.allInactiveTransactions
      .sort((previousCondico, nextCondico) => {
        if (this.state.appliedFilters.sortBy === myCondicoFilterTypes.sortByDate) {
          return new Date(nextCondico.dateTime) - new Date(previousCondico.dateTime);
        } else {
          utilityFunctions.log(`${config.CondicoState.new} config.CondicoState.new`)
          if(previousCondico.state === nextCondico.state) {
            return 0;
          } 
          if (previousCondico.state === config.CondicoState.pendingPayment && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.new && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.attempted && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment)  {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.completed && nextCondico.state !== config.CondicoState.completed && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          } 
          if (previousCondico.state === config.CondicoState.rescinded && nextCondico.state !== config.CondicoState.rescinded && nextCondico.state !== config.CondicoState.completed && nextCondico.state !== config.CondicoState.attempted && nextCondico.state !== config.CondicoState.new && nextCondico.state !== config.CondicoState.pendingPayment) {
            return -1;
          }
          return 1;
        }
      });
      if (!this.state.showingActive && manuallyScroll) {
          this.setState({filteredInactiveCondicos: filteredCondicos}, () => this.scrollTo());
          return;
      }
      this.setState({filteredInactiveCondicos: filteredCondicos});
  }

  onNextFrame(callback) {
    setTimeout(function () {
        window.requestAnimationFrame(callback)
    }, 0)
  }

  scrollTo() {
    //window.scrollTo(0,1200)
    //return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    //wait for a paint to do scrolly stuff

    this.onNextFrame( () => {
      //and scroll them!
        //node.scrollTop = node.scrollHeight;
        if (this.state.showingActive && this.props.myCondicosScrollYForActive !== undefined) {
          utilityFunctions.log(`scroll to in mycondicos for active 0,${this.props.myCondicosScrollYForActive}`);
          window.scrollTo(0,this.props.myCondicosScrollYForActive);
        } else if (this.state.showingActive && this.props.myCondicosScrollYForActive === undefined) {
          utilityFunctions.log(`scroll to in mycondicos for active 0,0`);
          window.scrollTo(0,0);
        } else if (!this.state.showingActive && this.props.myCondicosScrollYForInActive !== undefined) {
          utilityFunctions.log(`scroll to in mycondicos for inactive 0,${this.props.myCondicosScrollYForInActive}`);
          window.scrollTo(0,this.props.myCondicosScrollYForInActive);
        } else if (!this.state.showingActive && this.props.myCondicosScrollYForInActive === undefined) {
          utilityFunctions.log(`scroll to in mycondicos for inactive 0,0`);
          window.scrollTo(0,0);
        }
        this.addListener();
    })
  }
}