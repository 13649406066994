import { API } from "aws-amplify";
import React, { Component } from "react";
import { FormControl, FormGroup, ListGroup, Modal, Nav, NavItem, Radio } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ReactJson from 'react-json-view'

import utilityFunctions from "../../utilityFunctions";

export default class Status extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        selectedPath: undefined,
        usersEmail: undefined,
        cognitoIdentityId: undefined,
        transactionId: undefined,
        initOrPartner: undefined
    }
    utilityFunctions.log(`constructor Admin`);
    this.handleAdminFunctionChange = this.handleAdminFunctionChange.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
  }

  handleAdminFunctionChange(path) {
    utilityFunctions.log('handleAdminFunctionChange');
    if (path === '/status/postRefund') {
        utilityFunctions.log('handleAdminFunctionChange /status/postRefund');
        this.setState({selectedPath: '/status/postRefund'}, () => this.makeApiRequestPost('admin', '/transactions'));
    } else if (path === '/status/getTransactionStatus') {
        utilityFunctions.log('handleAdminFunctionChange /status/getTransactionStatus');
        this.setState({selectedPath: '/status/getTransactionStatus'}, () => this.makeApiRequestGet('admin', '/transactions'));
    }
  }

  async makeApiRequestGet(api, path) {
    try {
        let apiResponse = await API.get(api, `${path}?usersEmail=${this.state.usersEmail}&transaction=${this.state.transactionId}&initOrPartner=${this.state.initOrPartner}`);
        utilityFunctions.log(`GET apiResponse ${JSON.stringify(apiResponse)}`);
        if (apiResponse.Items.length > 0) {
          this.setState({
            apiResponse: {
              transaction: apiResponse.Items
            }
          });
        }
      } catch (e) {
        if (!!e.response && !!e.response.data) {
          if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
          }
          this.props.handleSetAppError();
          return
        }
        this.props.handleSetAppError();
      }
  }

  handleCloseModalPopover() {
    this.setState({showQueryBox: false, showRefundBox: false});
  }

  handleUserEmailChange = event => {
    this.setState({usersEmail: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  handleTransactionChange = event => {
    this.setState({transactionId: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  handleAmountChange = event => {
    this.setState({refundAmount: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  initatorChosen() {
    this.setState({initOrPartner: 'initiator'});
    utilityFunctions.log(`searching initiator`);
  }

  typeChosen(type) {
    this.setState({initOrPartner: type});
    utilityFunctions.log(`searching ${type}`);
  }

  async makeApiRequestPost(api, path) {
    utilityFunctions.log("POSTING refund...");
    this.props.hideLoader(false);
    try {
      let apiResponse = await this.refund({
        userId: this.state.apiResponse.transaction[this.state.apiResponse.transaction.length - 1].value,
        dateTime: this.state.apiResponse.transaction[1].value,
        amount: this.state.refundAmount
    });
        utilityFunctions.log(`api response for POST refund is ${JSON.stringify(apiResponse)}`);
        this.checkResult(apiResponse);
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
      }
      this.props.handleSetAppError();
    }
  }

  refund(data) {
    return API.post("admin", "/transactions", {
      body: data
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`response is ${response}`);
    if(!!response.refunded && response.refunded) {
        this.setState({
            apiResponse: {
                refund: response
            }
        });
    } else {
      utilityFunctions.log("failure");
    }
  }

  clear() {
    utilityFunctions.log('clearing app state');
    this.setState({
      selectedPath: undefined,
      initOrPartner: undefined,
      refundAmount: undefined,
      transactionId: undefined,
      usersEmail: undefined,
      apiResponse: undefined,
      showQueryBox: false,
      showRefundBox: false
    })
  }

  render() {
    if (this.props.userInfo !== undefined && this.props.userInfo.type !== "admin") {
      this.props.history.push('/profile');
      return (<div>NOT AUTHORIZED</div>)
    };
    return (
      this.props.completedStartup ?  
      <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Nav style={{backgroundColor: 'white', width: '100px'}} className="flex-column">
                <LinkContainer to="/status/getTransactionStatus">
                    <NavItem>
                      <div >
                        <button onClick={() => this.setState({showQueryBox: true})} style={{color: this.state.selectedPath === '/status/getTransactionStatus' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Query Transaction</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/status/getCognitoIdentityId">
                    <NavItem>
                      <div >
                        <button onClick={() => this.setState({showRefundBox: true})} style={{color: this.state.selectedPath === '/status/postRefund' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Refund Fee</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
            </Nav>
            <div style={{height: '500px', width: '90%', border: '1px solid lightGrey 0px border-bottom', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                <h3 style={{alignSelf: 'center'}}><u>{this.state.selectedPath === '/status/postRefund' ? 'ISSUE REFUND' : this.state.selectedPath === '/status/getTransactionStatus' ? 'TRANSACTION STATUS' : 'CLICK SIDE OPTION'}</u></h3><hr /><br />
                <button onClick={() => this.clear()} style={{color: 'red', backgroundColor: 'transparent', marginRight: '50px' }}><font face="Roboto, helvetica" >Clear</font></button>
                {
                  this.state.apiResponse ? 
                  <ListGroup>
                    <ReactJson src={this.state.apiResponse} />
                  </ListGroup> :
                  <div style={{margin: '20px', alignSelf: 'flex-start'}}>
                    <div>{`Instructions:`}</div><br />
                    <div>{`1) You may query a transaction at anytime but to issue a refund you must first perform a query then choose to refund. The refund option uses details that are pulled from the query result.`}</div><br />
                    <div>{`2) Refunds are done using an integer amount. The last 2 digits represent cents. For example 15025, has 25 cents in the amount $150.25.`}</div><br />
                  </div>
                }
            </div>
            <div></div>
        </div><br />
        <div style={{display: 'flex', justifyContent: 'left'}}>
            {
                this.state.showQueryBox &&
                <Modal show={this.state.showQueryBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Users ID, Transaction ID and User Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl
                            onChange={this.handleUserEmailChange}
                            placeholder="Users email used at signup"
                        />
                        <FormControl
                            onChange={this.handleTransactionChange}
                            placeholder="transaction ID"
                        />
                        <FormGroup>
                            <Radio onClick={() => this.typeChosen('initiator')} name="radioGroupWilling" >
                                is initiator
                            </Radio>
                            <Radio onClick={() => this.typeChosen('partner')} name="radioGroupWilling">
                                is partner
                            </Radio>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={this.handleCloseModalPopover} variant="secondary">Close</button>
                      <button onClick={() => {this.handleAdminFunctionChange('/status/getTransactionStatus'); this.handleCloseModalPopover()}} variant="primary">Save</button>
                    </Modal.Footer>
                </Modal>
            }
            {
                this.state.showRefundBox &&
                <Modal show={this.state.showRefundBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>Issuing Refund Per Below:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl
                            onChange={this.handleAmountChange}
                            placeholder="type amount no decimal ex. type 500 for $5.00"
                        />
                        <div>{`Transaction ID: ${!!this.state.apiResponse ? this.state.apiResponse.transaction[0].value : 'First Query a Transaction'}`}</div><br />
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={this.handleCloseModalPopover} variant="secondary">Close</button>
                      <button onClick={() => {this.handleAdminFunctionChange('/status/postRefund'); this.handleCloseModalPopover()}} variant="primary">Save</button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
      </div>
      : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}
