import { API, Auth } from "aws-amplify";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

import "./Home.css";
import utilityFunctions from "../utilityFunctions";

export default class PartnerLink extends Component {
  constructor(props) {
    super(props);
    this.handleCloseUnverifiedBusinessModal = this.handleCloseUnverifiedBusinessModal.bind(this);
    this.state = {
      partnerLinkData: undefined,
      showUnverifiedBusinessModal: false
    }
  }
  
  // we use 'will receive props' beasue App.js sttores the search in its state for this view
  async componentDidMount() {
    utilityFunctions.log(`search term at PartnerLinking is ${this.props.location.search} & we are authenticated? ${this.props.isAuthenticated}`);
    if (!!this.props.isAuthenticated && this.props.isAuthenticated) {
      if (!!this.props.partnerLinkData) {
        utilityFunctions.log("PartnerLink - componentDidMount: authenticated and have partner data");
        try {
          let user = await Auth.currentAuthenticatedUser();
          utilityFunctions.log(`user ${user.id}`)
          if (user !== null) {
            this.setState({
              partnerLinkData: {
                dateTime: this.props.partnerLinkData.dateTime,
                hashCode: this.props.partnerLinkData.hashCode,
                firstname: user.attributes['custom:givNameCus'],
                lastname: user.attributes['custom:famName_cus']
              }
            }, () => this.performPartnerLinkAPI());
            utilityFunctions.log(user);
          }
        }
        catch (e) {
          utilityFunctions.log(e);
          this.props.handleSetAppError();
          if (e !== 'No current user') {
            alert(e);
          }
        }
      } else {
        utilityFunctions.log("next props doesnt have partner linking data so pushing to home")
        this.props.history.push("/home");
      }
    }
  }

  async performPartnerLinkAPI() {
    utilityFunctions.log("performing partnerLinking");
    this.props.hideLoader(false);
    try {
      let response = await this.linkPartner({
          link: this.state.partnerLinkData
      });
      if (!!response.condico) {
        this.setState({
          partnerLinkData: undefined
        }, () => this.props.handleCondicoSeging(response.condico));
      } else {
        this.setState({
          partnerLinkData: undefined
        }, () => this.props.history.push("/home"));
      }
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          await Auth.signOut();
          if (!!this.props.userInfo) {
            if (!!this.props.userInfo.business && !this.props.userInfo.business.verified) {
              this.setState({showUnverifiedBusinessModal: true});
            } 
          }
          // note: we can add this back in once we return something different for unverified business in backend other than Not authorized. Either way, redirect to login calls this and most views check isAuthenticated()
          // this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  linkPartner(data) {
    utilityFunctions.log("linking Condico in PartnerLinks");
    return API.post("appLinks", "/partnerLink/1", {
        body: data.link
    });
  }

  handleCloseUnverifiedBusinessModal() {
    this.setState({ showUnverifiedBusinessModal: false }, this.props.history.push('/mycondicos'));
  }

  render() {
    return (
      <div>Please wait while we find your linked Condico...
          <div className="static-modal">
            <Modal show={this.state.showUnverifiedBusinessModal}>
              <Modal.Header>
                <Modal.Title>Business account not yet verified:</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please send us an email to info@mycondico.com from your business or company email account.  Once we have verified your business or company email account, you will be free to build a condico.</Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleCloseUnverifiedBusinessModal}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
      </div>
    );
  }
}