import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button } from "react-bootstrap";

import "./PaySummary.css";
import config from "../config.js";
import GenericModal from '../components/GenericModal';
import SummaryView from '../components/SummaryView';
import utilityFunctions from "../utilityFunctions";

export default class PaySummary extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
    this.state = {
      showModal: false,
      modalKind: '',
      payData: undefined,
      paidCondico: undefined
    }
  }

  componentWillMount() {
    if (!!this.props.payCondico) {
        this.setState({
            payData: {
                action: config.CondicoAction.pay,
                dateTime: this.props.payCondico.dateTime,
                initiatorId: this.props.payCondico.userId,
                version: this.props.payCondico.version
            }
        })
    }
  }

  async handleConfirmation() {
    utilityFunctions.log("confirmed payment");
    this.props.hideLoader(false);
    try {
      let payCondicoResponse = await this.payCondico({
        payData: this.state.payData
      });
      this.checkResult(payCondicoResponse);
    } catch (e) {
        this.props.hideLoader(true);
        if (!!e.response && !!e.response.data) {
          if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
          }
          this.props.handleSetAppError();
          return
        }
        this.props.handleSetAppError();
      }
  }

  payCondico(data) {
    return API.post("transactions", "/transactions/1", {
      body: data.payData
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`condico response is ${response}`);
    if (!!response.condico) {
        if (response.condico.state === config.CondicoState.completed) {
            utilityFunctions.log("success");
            this.setState({paidCondico: response.condico, showModal: true, modalKind: "success"}, () => this.props.removeCondicoFromAppStateDueToCompletedAction(config.CondicoAction.pay));
        } else if (response.condico.state === config.CondicoState.pendingPayment) {
            this.setState({paidCondico: response.condico, showModal: true, modalKind: "unpaid"}, () => this.props.removeCondicoFromAppStateDueToCompletedAction(config.CondicoAction.pay));
        }
    } else {
        utilityFunctions.log("failure");
        this.setState({showModal: false}, () => this.props.handleSetAppError());
    }
  }

  handleCloseModalPopover() {
    utilityFunctions.log("close modal called");
    this.setState({ showModal: false }, () => this.props.history.push("/mycondicos"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    var summaryBullets, confirmationBullets, alertTitle, imageType, confirmedCondicoData, partnerAmount;
    var includeBullets = true;
    if (!!this.props.appSettings && !!this.props.userInfo && !!this.props.payCondico && !!this.props.cognitoIdentityId) {
        if (this.props.cognitoIdentityId === this.props.payCondico.userId) {
            if (!!this.props.payCondico.initiator.payCeiling && this.props.payCondico.initiator.payCeiling) {
                summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.paySummaryIsInitiatorWithCeilingSummary;
            } else {
                summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.paySummaryIsInitiatorWithFloorSummary;
            }
        } else {
            partnerAmount = this.props.payCondico.successfulAttempt.amount;
            utilityFunctions.log(`is partner : ${partnerAmount}`)
            if (!!this.props.payCondico.initiator.payCeiling && this.props.payCondico.initiator.payCeiling) {
                summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.paySummaryIsPartnerWithCeilingSummary;
            } else {
                summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.paySummaryIsPartnerWithFloorSummary;
            }
        }
    }
    if (!!this.state.paidCondico && this.state.modalKind === "success") {
        alertTitle = "Payment Accepted - Condico!";
        includeBullets = false;
        confirmedCondicoData = this.state.paidCondico;
        imageType = config.ConfirmationModalImageType.completed;
        if (!!this.state.paidCondico.initiator.payCeiling && this.state.paidCondico.initiator.payCeiling) {
            confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnPaySuccessfulPaymentCeiling;
        } else if (!!this.state.paidCondico.initiator.acceptFloor && this.state.paidCondico.initiator.acceptFloor) {
            confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnPaySuccessfulPaymentFloor;
        } else {
            this.props.handleSetAppError();
        }
    } else if (!!this.state.paidCondico && this.state.modalKind === "unpaid") {
        alertTitle = "Payment Issue";
        includeBullets = true;
        confirmedCondicoData = this.state.paidCondico;
        imageType = config.ConfirmationModalImageType.payment;
        confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnPayFailedPayment;
    }   
    return (
      <div>
        { 
            ((this.state.showModal === true && !!confirmedCondicoData) || (!!this.props.payCondico)) ?
            <Breadcrumb>
            <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
                <Breadcrumb.Item  onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos/pay")} >Pay</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb> :
            <Breadcrumb>
                <Breadcrumb.Item href="/mycondicos">My Condicos</Breadcrumb.Item>
                <Breadcrumb.Item href="/mycondicos/pay">Pay</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb>
        }
        { 
            (this.state.showModal === true && !!confirmedCondicoData) ?
            <GenericModal
                bulletPoints={confirmationBullets}
                includeBullet={includeBullets}
                condicoData={confirmedCondicoData}
                partnerAmount={partnerAmount}
                imageType={imageType}
                alertTitle={alertTitle}
                handleCloseModalPopover={this.handleCloseModalPopover}
            /> : (!!this.props.payCondico) ?
            <div>
                <div className="Summary">
                <h2 style={{fontFamily: 'Roboto, helvetica'}}>Payment Confirmation</h2>
                For the dispute described as follows:
                <p style={{color: 'grey', fontFamily: 'Roboto, helvetica'}}><i>{this.props.payCondico.description}</i></p>
                {
                    !!this.props.appSettings && 
                    <SummaryView
                        childProps={this.props}
                        bulletPoints={summaryBullets}
                        partnerAmount={partnerAmount}
                        condicoData={this.props.payCondico}
                        last4={this.props.last4}
                    ></SummaryView>
                }
                </div>
                <div className= "ButtonContainer">
                    <Button
                        className="button"
                        block
                        bsSize="large"
                        onClick={() => this.handleConfirmation()}
                    >
                        Confirm
                    </Button>
                </div>
            </div> :
            <div>
                You must select a condico in pending payment state and ensure default creditcard is available
            </div>
        }
      </div> 
    );
  }
};