import React, { Component } from "react";
import { Button, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Col, Collapse, Grid, Row, ToggleButton, ToggleButtonGroup, Well } from "react-bootstrap";

import DatePicker from './DatePicker';
import "./FiltersView.css";
import config from "../config";
import utilityFunctions from "../utilityFunctions";

class FiltersView extends Component {
  
  constructor(props) {
    super(props);
    this.toggleSortBy = this.toggleSortBy.bind(this);
    this.toggleActiveInactive = this.toggleActiveInactive.bind(this);
    this.toggleShowInitiated = this.toggleShowInitiated.bind(this);
    this.toggleShowInvited = this.toggleShowInvited.bind(this);
    this.toggleDateRange = this.toggleDateRange.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.state = {
      subviewAppliedFilters: {
        showInvited: true,
        showInitiated: true,
        sortBy: myCondicoFilterTypes.sortByDate,
        range: myCondicoFilterTypes.rangeAll,
        dateRange: {
            starting: '',
            ending: ''
        }
      },
      subviewShowingDateRangeView: false,
      subviewShowingActive: true,
      applyButtonDisabled: true,
      dateRangeToLarge: false
    }
  }

  componentWillMount() {
    utilityFunctions.log("filters componentWillMount - WINDOW : ",window);
    this.copyCurrentFiltersIntoState(this.props.currentlyAppliedFilters);
    this.setState({height: window.innerHeight,width:window.innerWidth});
  }

  componentWillReceiveProps(nextProps) {
    utilityFunctions.log("filters componentWillReceiveProps - WINDOW : ",window);
    if (nextProps !== this.props) {
        utilityFunctions.log(`next props ${JSON.stringify(nextProps)}`)
        this.copyCurrentFiltersIntoState(nextProps.currentlyAppliedFilters);
    }
  }

  copyCurrentFiltersIntoState(currentFilters) {
    utilityFunctions.log("copyCurrentFiltersIntoState")
    var today = utilityFunctions.formatDate(new Date());
    var filters = this.state.subviewAppliedFilters;
    if (!!currentFilters.dateRange.starting) {
        if (currentFilters.dateRange.starting === "") {
            filters.dateRange.starting = today;
        } else {
            filters.dateRange.starting = currentFilters.dateRange.starting;
        }
    } else {
        filters.dateRange.starting = today;
    }
    if (!!currentFilters.dateRange.ending) {
        if (currentFilters.dateRange.ending === "") {
            let starting = filters.dateRange.starting;
            filters.dateRange.ending = starting;
        } else {
            filters.dateRange.ending = currentFilters.dateRange.ending;
        }
    } else {
        filters.dateRange.ending = today;
    }
    currentFilters.showInitiated ? filters.showInitiated = true : filters.showInitiated = false;
    currentFilters.showInvited ? filters.showInvited = true : filters.showInvited = false;
    currentFilters.sortBy === myCondicoFilterTypes.sortByDate ? filters.sortBy = myCondicoFilterTypes.sortByDate : filters.sortBy = myCondicoFilterTypes.sortByState;
    currentFilters.range === myCondicoFilterTypes.rangeAll ? filters.range = myCondicoFilterTypes.rangeAll : filters.range = myCondicoFilterTypes.rangeSome;
    var showingActive = this.props.showingActive === true ? true : false;
    var dayDifference = this.dateDifference(new Date(currentFilters.dateRange.starting), new Date(currentFilters.dateRange.ending)) > config.MAX_DAY_SEARCH_LENGTH;
    var showDateRangeBox = false;
    if (currentFilters.range === myCondicoFilterTypes.rangeSome) {
        showDateRangeBox = true;
    }
    this.setState({subviewAppliedFilters: filters, subviewShowingActive: showingActive, dateRangeToLarge: dayDifference, subviewShowingDateRangeView: showDateRangeBox});
  }

  render() {
    const { classes } = this.props;
    var direction = "column";
    if (this.state.width >= 967) {
        direction = "row";
    };
    var alpha = this.state.applyButtonDisabled ? '0.3' : '1.0';
    return (
      <div style={{float:"left"}}>
            <Grid>
                <Row>
                    <Col xs={4} md={4} lg={4} style={{marginRight: "5px"}}>
                        <div style={{marginLeft: "auto", position:"fixed", top:"90px", zIndex:"10"}}>
                            {
                                (this.state.subviewAppliedFilters.range === myCondicoFilterTypes.rangeSome ||
                                    this.state.subviewAppliedFilters.sortBy === myCondicoFilterTypes.sortByState ||
                                    !this.state.subviewAppliedFilters.showInitiated ||
                                    !this.state.subviewAppliedFilters.showInvited) ?
                                    <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => this.setState({ showingFilterBox: !this.state.showingFilterBox })}><img src={ require("../assets/myCondico/filterApplied@3x.png") } height="20" width="20" alt="appliedFilterImage" /></button> :
                                    <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => this.setState({ showingFilterBox: !this.state.showingFilterBox })}><img src={ require("../assets/myCondico/filterUnapplied@3x.png") } height="20" width="20" alt="unappliedFilterImage" /></button>
                            }
                        </div>
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                        <div style={{position:"fixed", top:"90px", zIndex:"10"}}>
                            <ToggleButtonGroup
                                value={this.state.subviewShowingActive}
                                onChange={this.toggleActiveInactive}
                                bsSize="small"
                                type="radio"
                                name="activeOptions"
                            >
                                <ToggleButton style={{color:"#97D352", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={true}>Active</ToggleButton>
                                <ToggleButton style={{color:"#97D352", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={false}>Inactive</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div></div>
                    </Col>
                    <Col xs={4} md={4} lg={4} style={{marginLeft: "45px"}}>
                        <div style={{position:"fixed", top:"90px", zIndex:"10", marginLeft:"150px"}}>
                            <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => this.props.handleRefresh()}><img src={ require("../assets/refresh.png") } height="25" width="25" alt="stateImage" /></button>
                        </div>
                    </Col>
                </Row>
            </Grid>
            <Collapse in={this.state.showingFilterBox}>
                <div style={{position:"fixed", top:"90px", zIndex:"10"}}>
                    <Well>
                        <div className="FilterTitles">
                            <Button style={{color:"#808080", fontFamily:"Roboto, helvetica", fontSize:"16px"}} onClick={() => this.cancel()}>Cancel</Button>
                            <Button style={{color:"#808080", fontFamily:"Roboto, helvetica", fontSize:"16px"}} onClick={() => this.reset()}>Reset</Button>
                        </div>
                        <FormGroup row={direction === "row"}>
                            <div>Show:
                                <div className="Switches">
                                    <div style={{marginTop:"15px"}}>Condicos I initiated:</div>
                                        <FormControlLabel
                                                style={{paddingLeft:"10px", marginTop:"5px"}}
                                                control={
                                                    <Switch
                                                        classes={{
                                                            switchBase: classes.iOSSwitchBase,
                                                            bar: classes.iOSBar,
                                                            icon: classes.iOSIcon,
                                                            iconChecked: classes.iOSIconChecked,
                                                            checked: classes.iOSChecked,
                                                        }}
                                                        checked={this.state.subviewAppliedFilters.showInitiated} onChange={() => this.toggleShowInitiated()} />
                                                }
                                            />
                                </div>
                                <div className="Switches">
                                    <div style={{marginTop:"15px"}}>Condicos other initiated:</div>
                                        <FormControlLabel
                                                style={{paddingLeft:"10px", marginTop:"5px"}}
                                                control={
                                                    <Switch
                                                        classes={{
                                                            switchBase: classes.iOSSwitchBase,
                                                            bar: classes.iOSBar,
                                                            icon: classes.iOSIcon,
                                                            iconChecked: classes.iOSIconChecked,
                                                            checked: classes.iOSChecked,
                                                        }}
                                                        checked={this.state.subviewAppliedFilters.showInvited} onChange={() => this.toggleShowInvited()} />
                                                }
                                            />
                                </div>
                            </div>
                        </FormGroup>
                        <div style={{paddingBottom:"10px"}}>
                            <div>Sort by:</div>
                            <ToggleButtonGroup
                                value={this.state.subviewAppliedFilters.sortBy}
                                onChange={this.toggleSortBy}
                                bsSize="small"
                                type="radio"
                                name="sortOptions"
                                justified
                            >
                                <ToggleButton style={{color:"#000", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={myCondicoFilterTypes.sortByDate}>Date</ToggleButton>
                                <ToggleButton style={{color:"#000", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={myCondicoFilterTypes.sortByState}>Status</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <div>Date Created:</div>
                            <ToggleButtonGroup
                                value={this.state.subviewAppliedFilters.range}
                                onChange={this.toggleDateRange}
                                bsSize="small"
                                type="radio"
                                name="dateRange"
                                justified
                            >
                                <ToggleButton style={{color:"#000", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={myCondicoFilterTypes.rangeAll}>All</ToggleButton>
                                <ToggleButton style={{color:"#000", fontFamily:"Roboto, helvetica", fontSize:"16px"}} value={myCondicoFilterTypes.rangeSome}>MM/DD - MM/DD</ToggleButton>
                            </ToggleButtonGroup><br />
                            { this.state.subviewShowingDateRangeView && 
                                <div>
                                    <div className="DatePickers">
                                        <DatePicker
                                            label="starting"
                                            dateHandler={this.dateChanged}
                                            value={this.state.subviewAppliedFilters.dateRange.starting}
                                        ></DatePicker> to:
                                        <DatePicker
                                            label="ending"
                                            dateHandler={this.dateChanged}
                                            value={this.state.subviewAppliedFilters.dateRange.ending}
                                        ></DatePicker>
                                    </div>
                                    <div>
                                        {
                                              this.state.dateRangeToLarge && <h5 style={{color:"red", paddingTop:"3px", paddingLeft:"5px"}}>* maximum search range is 14 days</h5>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <button
                            className="filtersButton"
                            disabled={this.state.applyButtonDisabled || this.state.dateRangeToLarge}
                            onClick={() => {
                                    let newObj = JSON.parse(JSON.stringify(this.state));
                                    this.props.handleNewFilters(newObj.subviewAppliedFilters, newObj.subviewShowingActive)
                                    this.setState({showingFilterBox: false, applyButtonDisabled: true})
                                }
                            }
                            style= {{opacity: alpha}}
                        >
                            Apply
                        </button>
                    </Well>
                </div>
            </Collapse>
      </div> 
    );
  }

  dateDifference(date1, date2) {
    var one_day=1000*60*60*24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms/one_day);
  }

  dateChanged(event, label) {
    var appliedFilters = this.state.subviewAppliedFilters;
    utilityFunctions.log(`dateChanged - event.target.value ${event.target.value} label ${label}`);
    utilityFunctions.log(`current starting is ${new Date(appliedFilters.dateRange.starting).toUTCString()} ending is ${new Date(appliedFilters.dateRange.ending).toUTCString()}`)
    var date = new Date(event.target.value);
    utilityFunctions.log(`inputted date from picker is ${date}`);
    if (label === "starting") {
        utilityFunctions.log(`setting start date ${event.target.value}`)
        appliedFilters.dateRange.starting = event.target.value;
        if (date > new Date(appliedFilters.dateRange.ending)) {
            utilityFunctions.log(`starting date is greater than ending`)
            appliedFilters.dateRange.starting = appliedFilters.dateRange.ending;
        }
    } 
    if (label === "ending") {
        utilityFunctions.log(`setting end date ${event.target.value}`)
        appliedFilters.dateRange.ending = event.target.value;
        if (date < new Date(appliedFilters.dateRange.starting)) {
            utilityFunctions.log(`ending date is lower than starting`)
            appliedFilters.dateRange.ending = appliedFilters.dateRange.starting;
        }
    }
    var dayDifference = this.dateDifference(new Date(appliedFilters.dateRange.starting), new Date(appliedFilters.dateRange.ending)) > config.MAX_DAY_SEARCH_LENGTH;
    utilityFunctions.log(`date difference is over max limit: ${dayDifference}`);
    appliedFilters.range = myCondicoFilterTypes.rangeSome;
    this.setState({subviewAppliedFilters: appliedFilters, applyButtonDisabled: dayDifference, dateRangeToLarge: dayDifference});
  }

  reset() {
      utilityFunctions.log("reset");
      var appliedFilters = {
        showInvited: true,
        showInitiated: true,
        sortBy: myCondicoFilterTypes.sortByDate,
        range: myCondicoFilterTypes.rangeAll,
        dateRange: {
            starting: '',
            ending: ''
        }
      }
    this.setState({ subviewAppliedFilters: appliedFilters, subviewShowingDateRangeView: false, applyButtonDisabled: false })
  }

  cancel() {
    this.copyCurrentFiltersIntoState(this.props.currentlyAppliedFilters);
    this.setState({showingFilterBox: false});
  }

  toggleActiveInactive() {
      utilityFunctions.log(`ahead of toggle active showingActive is: ${this.state.subviewShowingActive}`)
    let newObj = JSON.parse(JSON.stringify(this.state));
    this.setState({subviewShowingActive: !this.state.subviewShowingActive}, () => this.props.handleNewFilters(newObj.subviewAppliedFilters, this.state.subviewShowingActive));
  }

  toggleShowInitiated() {
    utilityFunctions.log('toggleShowInitiated clicked');
    var appliedFilters = this.state.subviewAppliedFilters;
    appliedFilters.showInitiated = !appliedFilters.showInitiated;
    this.setState({subviewAppliedFilters: appliedFilters, applyButtonDisabled: false});
  }

  toggleShowInvited() {
    utilityFunctions.log('toggleShowInvited clicked');
    var appliedFilters = this.state.subviewAppliedFilters;
    appliedFilters.showInvited = !appliedFilters.showInvited;
    this.setState({subviewAppliedFilters: appliedFilters, applyButtonDisabled: false});
  }

  toggleSortBy(sort) {
    utilityFunctions.log(`sortType was: ${JSON.stringify(this.state.subviewAppliedFilters.sortBy)}`)
    var appliedFilters = this.state.subviewAppliedFilters;
    appliedFilters.sortBy = sort;
    this.setState({subviewAppliedFilters: appliedFilters, applyButtonDisabled: false});
  }
  
  toggleDateRange(range) {
    utilityFunctions.log('toggleShowDateRangeView clicked');
    var appliedFilters = this.state.subviewAppliedFilters;
    appliedFilters.range = range;
    var showingDateRangeView = false;
    if (range === myCondicoFilterTypes.rangeSome) {
      showingDateRangeView = true;
    }
    this.setState({subviewAppliedFilters: appliedFilters, subviewShowingDateRangeView: showingDateRangeView, applyButtonDisabled: false});
  }
}

const styles = theme => ({
    iOSSwitchBase: {
      '&$iOSChecked': {
        color: theme.palette.common.white,
        '& + $iOSBar': {
          backgroundColor: '#333',
        },
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp,
      }),
    },
    iOSChecked: {
      transform: 'translateX(15px)',
      '& + $iOSBar': {
        opacity: 1,
        border: 'none',
      },
    },
    iOSBar: {
      borderRadius: 13,
      width: 42,
      height: 26,
      marginTop: -13,
      marginLeft: -21,
      border: 'solid 1px',
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
      width: 24,
      height: 24,
    },
    iOSIconChecked: {
      boxShadow: theme.shadows[1],
    },
  });


FiltersView.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export const myCondicoFilterTypes = Object.freeze({
    sortByDate: "sortByDate", 
    sortByState: "sortByState",
    showInitiated: "showInitiated",
    showInvited: "showInvited", 
    rangeAll: "rangeAll",
    rangeSome: "rangeSome"
});

export default withStyles(styles)(FiltersView);