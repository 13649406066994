import { Auth, API } from "aws-amplify";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Button, ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";

import "./CollectPersonalInfo.css";
import config from "../config";
import GenericModal from '../components/GenericModal';
import utilityFunctions from "../utilityFunctions";

  export default class CollectPersonalInfo extends Component {

    constructor(props) {
        super(props);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
        this.state = {
            currentUser: undefined,
            firstname: undefined,
            lastname: undefined,
            isBusiness: undefined,
            businessName: undefined,
            showOnLoadModal: true,
            validForm: false
        }
    }

    _handleKeyPress(e, value) {
        if (e.key === 'Enter') {
          utilityFunctions.log(`ENTER! on value ${value}`);
          if (value === 'firstname') {
            ReactDOM.findDOMNode(this.refs['lastname']).focus();
          } else {
            return;
          }
        } 
      }

    async componentWillMount() {
        this.props.hideLoader(false);
        utilityFunctions.log("CollectPersonalInfo willFocus");
        try {
            let user = await Auth.currentAuthenticatedUser();
            this.setState({currentUser: user});
            this.props.hideLoader(true);
        } catch (e) {
            this.props.hideLoader(true);
            if (!!e.response && !!e.response.data) {
                if(e.response.data.message === "Not authorized") {
                    utilityFunctions.log("NOT AUTHORIZED");
                    this.props.handleLogout();
                    return;
                }
                this.props.handleSetAppError();
                return
            }
            this.props.handleSetAppError();
        }
        try {
            let user = await Auth.currentUserInfo();
            utilityFunctions.log(`user id ${user.id}`);
            utilityFunctions.log(`user ${JSON.stringify(user)}`);
            if (user !== null) {
              if (!!user.attributes['custom:famName_cus']) {
                utilityFunctions.log('have personal Info @ collect');
                if (this.props.partnerLinkData === undefined) {
                    this.props.handlePersonalInfoProvided(undefined, true, (success) => {
                      if (!success) {
                        this.props.handleSetAppError();
                        return;
                      }
                      this.props.history.push("/home");
                    });
                } else {
                    this.props.handlePersonalInfoProvided(undefined, true, (success) => {
                        if (!success) {
                            this.props.handleSetAppError();
                        }
                    });
                }
              }
            }
          }
          catch (e) {
            utilityFunctions.log(e);
            if (e !== 'No current user') {
              alert(e);
            }
          }
    }

    handleFormSubmit = async event => {
        event.preventDefault();
        utilityFunctions.log("handleSubmit user attributes");
        this.props.hideLoader(false);
        try {
            let result = await Auth.updateUserAttributes(this.state.currentUser, {
                'custom:givNameCus': this.state.firstname,
                'custom:famName_cus': this.state.lastname
            });
            if (result === "SUCCESS") {
                utilityFunctions.log(`name update result ${result}`);
                if (this.state.isBusiness) {
                    this.handleBusinessName();
                } else {
                    this.props.hideLoader(true);
                    utilityFunctions.log("no business so notify App.js and if partner link is null seg to /home");
                    if (this.props.partnerLinkData === undefined) {
                        this.props.handlePersonalInfoProvided(undefined, true, (success) => {
                          if (!success) {
                            this.props.handleSetAppError();
                            return;
                          }
                          this.props.history.push("/home");
                        });
                    } else {
                        this.props.handlePersonalInfoProvided(undefined, true, (success) => {
                            if (!success) {
                            this.props.handleSetAppError();
                            }
                        });
                    }
                }
            } else {
                utilityFunctions.log("failure");
                this.props.handleSetAppError();
                this.props.history.push("/");
            }
        } catch (e) {
            this.props.hideLoader(true);
            if (!!e.response && !!e.response.data) {
                if(e.response.data.message === "Not authorized") {
                    utilityFunctions.log("NOT AUTHORIZED");
                    this.props.handleLogout();
                    return;
                }
                this.props.handleSetAppError();
                return
            }
            this.props.handleSetAppError();
        }
    }

    async handleBusinessName() {
        utilityFunctions.log("sending business name...");
        try {
          let businessResponse = await this.updateProfileBusinessName({
            businessData: {
                action: 'addBusiness',
                businessName: this.state.businessName
            }
          });
          utilityFunctions.log(`profile udate with business name response is ${businessResponse}`);
          this.checkResult(businessResponse);
        } catch (e) {
            this.props.hideLoader(true);
            utilityFunctions.log(`error catch at handleBusinessName with error ${e} `);
            if (!!e.response && !!e.response.data) {
                if(e.response.data.message === "Not authorized") {
                utilityFunctions.log("NOT AUTHORIZED");
                this.props.handleLogout();
                return;
                }
            }
            this.props.handleSetAppError();
        }
      }
    
      updateProfileBusinessName(data) {
        utilityFunctions.log(`updateProfileBusinessName called for POST with data ${JSON.stringify(data)}`);
        return API.post("users", "/profile/1", {
          body: data.businessData
        });
      }
    
      checkResult(response) {
        this.props.hideLoader(true);
        utilityFunctions.log(`checking response ${JSON.stringify(response)}`);
        if(!!response.userInfo) {
            if (!!response.userInfo.business && response.userInfo.business.name !== '')
            utilityFunctions.log("success");
            if (this.props.partnerLinkData === undefined) {
                this.props.handlePersonalInfoProvided(response.userInfo, true, (success) => {
                  if (!success) {
                    this.props.handleSetAppError();
                    return;
                  }
                  this.props.history.push("/home");
                });
            } else {
                this.props.handlePersonalInfoProvided(response.userInfo, true, (success) => {
                    if (!success) {
                    this.props.handleSetAppError();
                    }
                });
            }
        } else {
          utilityFunctions.log("failure");
          this.props.handleSetAppError();
        }
      }

      validateForm() {
        utilityFunctions.log(`validateForm CollectPersonalInfo ${this.getFirstnameValidationState()} ${this.getLastnameValidationState()} ${this.getBusinessValidationState()}`);
        if (this.getFirstnameValidationState() === "success" &&
            this.getLastnameValidationState() === "success" &&
            this.getBusinessValidationState() === "success") {
            this.setState({validForm: true,});
            return
        }
        utilityFunctions.log("validation failed");
        this.setState({validForm: false});
        return;
      }

    getFirstnameValidationState() {
        if (!!this.state.firstname) {
            const length = this.state.firstname.length;
            if (length > 1 && utilityFunctions.isAlpha(this.state.firstname)) return 'success';
            else if (length > 1 && !utilityFunctions.isAlpha(this.state.firstname)) return 'error';
            else if ( length > 0 ) return 'error';
            return null;
        } else {
            return null;
        }
    }

    handleFirstnameChange = event => {
        this.setState({firstname: event.target.value}, () => this.validateForm());
        utilityFunctions.log(`event.target.value ${event.target.value}`);
    }

    getLastnameValidationState() {
        if (!!this.state.lastname) {
            const length = this.state.lastname.length;
            if (length > 1 && utilityFunctions.isAlpha(this.state.lastname)) return 'success';
            else if (length > 1 && !utilityFunctions.isAlpha(this.state.lastname)) return 'error';
            else if ( length > 0 ) return 'error';
            return null;
        } else {
            return null;
        }
    }

    handleLastnameChange = event => {
        this.setState({lastname: event.target.value}, () => this.validateForm());
        utilityFunctions.log(`event.target.value ${event.target.value}`);
    }

    handleCloseModalPopover() {
        utilityFunctions.log("close modal called");
        this.setState({ showOnLoadModal: false });
    }

    individualSelected = event => {
        this.setState({isBusiness: false}, () => {
            this.validateForm();
        });
        utilityFunctions.log(`individual selected`);
    }

    businessSelected = event => {
        this.setState({isBusiness: true}, () => {
            this.validateForm();
        });
        utilityFunctions.log(`business selected`);
    }

    handleBusinessChange = event => {
        this.setState({businessName: event.target.value}, () => {
            this.validateForm();
        });
        utilityFunctions.log(`event.target.value ${event.target.value}`);
    }

    getBusinessValidationState() {
        if (this.state.isBusiness !== undefined && this.state.isBusiness) {
          utilityFunctions.log("getBusinessValidationState is Business true");
          if (!!this.state.businessName) {
              utilityFunctions.log("business name provided");
              const length = this.state.businessName.length;
              if (length > 1 && utilityFunctions.isAlphaNumeric(this.state.businessName)) return 'success';
              else if (length > 1 && !utilityFunctions.isAlphaNumeric(this.state.businessName)) return 'error';
              else if ( length > 0 ) return 'error';
              return null;
          } else {
              utilityFunctions.log("business name not provided");
              return null;
          }
        } else if (this.state.isBusiness !== undefined && !this.state.isBusiness) {
          utilityFunctions.log("not business and validation passed");
          return 'success';
        } else {
          utilityFunctions.log("business or individual not chosen yet");
          return null;
        }
    }
  render() {
    return (
        <div style={{flexGrow: 1, flexDirection: 'column'}}>
            {
                this.state.showOnLoadModal ?
                <GenericModal
                    bulletPoints={["We need to collect your first and last name so that we can customize the notices we send to you and the other party involved in any of your Condico transactions."]}
                    includeBullet={false}
                    condicoData={undefined}
                    partnerAmount={undefined}
                    imageType={config.ConfirmationModalImageType.info}
                    alertTitle={'A little information, please?'}
                    handleCloseModalPopover={this.handleCloseModalPopover}
                /> :
                !!this.state.currentUser ?
                <div style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start'}}>
                    <div style={{height: 56, backgroundColor: 'grey'}}>
                        <h2 style={{fontSize: 22, alignSelf: 'center', fontFamily: 'Roboto, helvetica', fontWeight: 'bold', paddingTop: 8, paddingLeft: 8}}>Details</h2>
                    </div>
                    <form onSubmit={this.handleFormSubmit}>
                        <FormGroup
                            controlId="firstname"
                            validationState={this.getFirstnameValidationState()}
                        >
                            <ControlLabel>First Name:</ControlLabel>
                            <FormControl
                                onKeyPress={(e) => this._handleKeyPress(e, 'firstname')}
                                type="text"
                                placeholder='firstname'
                                onChange={this.handleFirstnameChange}
                                value={this.state.firstname}
                            />
                            <FormControl.Feedback />
                            { (this.getFirstnameValidationState() === "success" || this.getFirstnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>min 2 chars: [a-Z], [-.space] only</HelpBlock> }
                        </FormGroup>
                        <FormGroup
                            controlId="lastname"
                            validationState={this.getLastnameValidationState()}
                        >
                            <ControlLabel>Last Name:</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder='firstname'
                                onChange={this.handleLastnameChange}
                                value={this.state.lastname}
                            />
                            <FormControl.Feedback />
                            { (this.getLastnameValidationState() === "success" || this.getLastnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>min 2 chars: [a-Z], [-.space] only</HelpBlock> }
                        </FormGroup>
                        <div style={{display:'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 25}}>
                            <ControlLabel>Are you an individual or a business?</ControlLabel>
                            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 25}}>
                                <Button style={(this.state.isBusiness !== undefined && (this.state.isBusiness === false)) ? {backgroundColor:"#97D352", color: "white", fontFamily:"Roboto, helvetica", fontSize:"16px"} : {fontFamily:"Roboto, helvetica", fontSize:"16px"}}  onClick={this.individualSelected} bsSize="large" variant="primary">Individual</Button>
                                <Button style={(this.state.isBusiness !==undefined && (this.state.isBusiness === true)) ? {marginLeft: "25px", backgroundColor:"#97D352", color: "white", fontFamily:"Roboto, helvetica", fontSize:"16px"} : {marginLeft: "25px", fontFamily:"Roboto, helvetica", fontSize:"16px"}} onClick={this.businessSelected} bsSize="large" variant="primary">Business</Button>
                            </div>
                        </div>
                        {
                            (this.state.isBusiness !== undefined && this.state.isBusiness) && <FormGroup
                                controlId="businessName"
                                validationState={this.getBusinessValidationState()}
                            >
                                <ControlLabel>Business Name:</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder='name'
                                    onChange={this.handleBusinessChange}
                                    value={this.state.businessName}
                                />
                                <FormControl.Feedback />
                                { (this.getBusinessValidationState() === "success" || this.getBusinessValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>min 2 chars: [a-Z, 0-9], [-.space] only</HelpBlock> }
                            </FormGroup>
                        }
                        <div className= "ButtonContainer">
                            <Button
                                type="submit"
                                className="button"
                                disabled={!this.state.validForm}
                                block
                                bsSize="large"
                            >
                                Continue
                            </Button>
                        </div>
                    </form>
                </div> :
                <div style={{marginTop: '30px'}}>Loading...</div>
            }
        </div>
    );
  }
}