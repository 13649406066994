import { Auth, API } from "aws-amplify";
import React, { Component, useState } from "react";
import ReactDOM from 'react-dom';
import { Breadcrumb, Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup, Modal, OverlayTrigger, Popover, Radio, Row } from "react-bootstrap";
import rescindedImg from "../assets/myCondico/cell/rescinded.png";
import completedImg from "../assets/myCondico/cell/completed.png";
import config from "../config";
import "../containers/CreateCondico.css";
import utilityFunctions from "../utilityFunctions";

export default class CreateCondicoUnAuthForm extends Component {
    constructor(props) {
        super(props);
        this.floorChosen = this.floorChosen.bind(this);
        this.ceilingChosen = this.ceilingChosen.bind(this);
        this.state = {
          displayAmount: 0,
          creationData: {
            action: config.CondicoAction.create,
            random: this.arc4random(),
            initiator: {
              partnersIntentFirstname: '',
              partnersIntentLastname: '',
              amount: 0
            },
            partner: {
              email: ''
            },
            description: '',
            isComplete: false,
            app: 'web'
          },
          validForm: false,
          partnerConfirmationEmail: '',
          hasEnteredAmount: false
        };
      }
 
    render() {
            const willingPopover = (
                <Popover id="modal-popover" title="Willing to accept/pay?">
                The amount you enter below remains confidential and is used only by our system to determine eligibility or perform a settlement. If your looking to receive a payout choose the first option, otherwise choose 'to pay'
                </Popover>
            );
            const matterPopover = (
                <Popover id="modal-popover" title="How to enter your matter?">
                Describe the dispute in a way that will make it clear to the other side what you are referring to.  For example, if the negotiation concerns unsatisfied invoice No. 123 rendered by ABC Company for $100, the description of the subject matter of the negotiation might read: “Satisfaction of invoice number 123 rendered by ABC Company in the amount of $100." By way of a further example, if the negotiation concerned payment for the painting of the garage at 123 Side St. on August 1, 2015, the description of the subject matter of the negotiation might read: "Payment for painting the garage at 123 Side St. on August 1, 2015." And if the negotiation concerned the settlement of a lawsuit, the description of the subject matter of the negotiation might read: "Full settlement of the action commenced in the City of Toronto under Court File No. CV-1600001
                </Popover>
            );
            return (
                <div style={{fontFamily: 'Roboto, helvetica'}}>
                <div>
                    <form>
                    {` Enter the information below:`}<br/>
                    <FormGroup
                        controlId="partnersIntentFirstname"
                        validationState={this.getPartnerFirstnameValidationState()}
                        >
                        <ControlLabel>Other Side's First Name:</ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handlePartnerFirstnameChange}
                            value={this.state.creationData.initiator.partnersIntentFirstname}
                            placeholder="min 2 characters"
                        />
                        <FormControl.Feedback />
                        { (this.getPartnerFirstnameValidationState() === "success" || this.getPartnerFirstnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: Min 2, A-z, no special char</HelpBlock> } 
                    </FormGroup>
                    <FormGroup
                        controlId="partnersIntentLastname"
                        validationState={this.getPartnerLastnameValidationState()}
                        >
                        <ControlLabel>Other Side's Last Name:</ControlLabel>
                        <FormControl
                            type="text"
                            onChange={this.handlePartnerLastnameChange}
                            value={this.state.creationData.initiator.partnersIntentLastname}
                            placeholder="min 2 characters"
                        />
                        <FormControl.Feedback />
                        { (this.getPartnerLastnameValidationState() === "success" || this.getPartnerLastnameValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Bad format: Min 2, A-z, no special char</HelpBlock> } 
                        </FormGroup>
                        <FormGroup
                        controlId="partnersEmail"
                        validationState={this.getPartnerEmailValidationState()}
                        >
                        <ControlLabel>Other Side's Email:</ControlLabel>
                        <FormControl
                            type="email"
                            onChange={this.handlePartnerEmailChange}
                            value={this.state.creationData.partner.email}
                            placeholder="name@domain.com"
                        />
                        <FormControl.Feedback />
                        { (this.getPartnerEmailValidationState() === "success" || this.getPartnerEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. name@domain.com</HelpBlock> }
                        </FormGroup>
                        {
                        utilityFunctions.isEmail(this.state.creationData.partner.email) && 
                        <FormGroup
                            controlId="partnersConfirmationEmail"
                            validationState={this.getPartnerConfirmationEmailValidationState()}
                        >
                            <ControlLabel>Confirm Other Side's Email:</ControlLabel>
                            <FormControl
                            type="email"
                            onChange={this.handlePartnerConfirmationEmailChange}
                            value={this.state.partnerConfirmationEmail}
                            placeholder="name@domain.com"
                            />
                            <FormControl.Feedback />
                            { (this.getPartnerConfirmationEmailValidationState() === "success" || this.getPartnerConfirmationEmailValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Send to the right person. Make sure emails match</HelpBlock> } 
                        </FormGroup>
                    }
                    <ControlLabel>Matter To Resolve:</ControlLabel><OverlayTrigger rootClose trigger="click" overlay={matterPopover} placement="bottom"><img style={{marginLeft: "5px"}} src={require("../assets/myCondico/infoBtn.png")} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger>
                    <FormGroup 
                    controlId="matter"
                    validationState={this.getMatterValidationState()}
                    >
                    <FormControl
                        onChange={this.handleMatterChange}
                        value={this.state.creationData.description}
                        componentClass="textarea"
                    />
                    <FormControl.Feedback />
                    { (this.getMatterValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock style={(this.getMatterValidationState() === "success" || this.getMatterValidationState() === null) ? {color: 'green'} : {color: 'red'}}>{`Char min ${config.MIN_MATTER_LENGTH}, max ${config.MAX_MATTER_LENGTH}. Char remaining: ${config.MAX_MATTER_LENGTH - this.state.creationData.description.length}`}</HelpBlock> }
                    </FormGroup>
                    As initiator of this condico, I am willing <OverlayTrigger rootClose trigger="click" overlay={willingPopover} placement="top"><img src={require("../assets/myCondico/infoBtn.png")} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger>
                    <FormGroup>
                    <Radio onClick={this.floorChosen} name="radioGroupWilling" >
                        to accept (a minimum of)
                    </Radio>
                    <Radio onClick={this.ceilingChosen} name="radioGroupWilling">
                        to pay (a maximum of)
                    </Radio>
                    </FormGroup>
                    <FormGroup
                    controlId="amount"
                    validationState={this.getAmountValidationState()}
                    >
                    <InputGroup>
                        <InputGroup.Addon>(CAD) C$</InputGroup.Addon>
                        <FormControl
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                            ReactDOM.findDOMNode(this.refs['amtField']).blur();
                            }
                        }}
                        ref="amtField"
                        autoComplete="off"
                        type="text"
                        onChange={this.handleAmountChange}
                        value={String(this.state.displayAmount)}
                        />
                        <FormControl.Feedback />
                        { (this.getAmountValidationState() === "success" || this.getAmountValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>Ex. amount $1.00</HelpBlock> }
                    </InputGroup>
                    to resolve this matter.
                    </FormGroup>
                    <div className= "ButtonContainer">
                        <Button
                        onClick={() => this.props.handleFormSubmit({unAuthDisplayAmt: this.state.displayAmount, creationData: this.state.creationData})}
                        disabled={!this.state.validForm}
                        className="button"
                        block
                        bsSize="large"
                        >
                        <font face="Roboto, helvetica" color="white">Continue</font>
                        </Button>
                    </div>
                    </form>
                </div>
                </div>
            );
        };

        arc4random() {
            return Math.random()*4294967296;
          }
        
          validateForm() {
            var creationData = this.state.creationData;
            if (!!this.state.creationData) {
              if (this.getPartnerFirstnameValidationState() === "success" && 
                  this.getPartnerLastnameValidationState() === "success" && 
                  this.getPartnerEmailValidationState() === "success" &&
                  this.getPartnerConfirmationEmailValidationState() === "success" &&
                  this.getMatterValidationState() === "success" &&
                  (!!this.state.creationData.initiator.acceptFloor || !!this.state.creationData.initiator.payCeiling ) &&
                  this.getAmountValidationState() === "success" ) {
                    utilityFunctions.log(`validation passed. ${this.getPartnerFirstnameValidationState()}`);
                    creationData.isComplete = true;
                    this.setState({validForm: true, creationData});
                    return
                  }
            }
            utilityFunctions.log("validation failed");
            creationData.isComplete = false;
            this.setState({validForm: false, creationData});
            return;
          }
        
          handlePartnerFirstnameChange = event => {
            var creationData = this.state.creationData;
            creationData.initiator.partnersIntentFirstname = event.target.value;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`event.target.value ${event.target.value}`);
          }
        
          getPartnerFirstnameValidationState() {
            const length = this.state.creationData.initiator.partnersIntentFirstname.length;
            if (length > 1 && utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentFirstname)) return 'success';
            else if (length > 1 && !utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentFirstname)) return 'error';
            else if ( length > 0 ) return 'error';
            return null;
          }
        
          handlePartnerLastnameChange = event => {
            var creationData = this.state.creationData;
            creationData.initiator.partnersIntentLastname = event.target.value;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`event.target.value ${event.target.value}`);
          }
        
          getPartnerLastnameValidationState() {
            const length = this.state.creationData.initiator.partnersIntentLastname.length;
            if (length > 1 && utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentLastname)) return 'success';
            else if (length > 1 && !utilityFunctions.isAlpha(this.state.creationData.initiator.partnersIntentLastname)) return 'error';
            else if ( length > 0 ) return 'error';
            return null;
          }
        
          handlePartnerEmailChange = event => {
            var creationData = this.state.creationData;
            creationData.partner.email = event.target.value;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`event.target.value ${event.target.value}`);
          }
        
          getPartnerEmailValidationState() {
            const length = this.state.creationData.partner.email.length;
            if ((utilityFunctions.isEmail(this.state.creationData.partner.email))) return 'success';
            else if ( length > 0 ) return 'error';
            return null;
          }
        
          handlePartnerConfirmationEmailChange = event => {
            var partnerConfirmationEmail = event.target.value;
            this.setState({partnerConfirmationEmail}, () => this.validateForm());
            utilityFunctions.log(`event.target.value ${event.target.value}`);
          }
        
          getPartnerConfirmationEmailValidationState() {
            const length = this.state.partnerConfirmationEmail.length;
            if (utilityFunctions.isEmail(this.state.partnerConfirmationEmail) && (this.state.partnerConfirmationEmail.toLowerCase() === this.state.creationData.partner.email.toLowerCase())) return 'success';
            else if ( length > 0 ) return 'error';
            return null;
          }
        
          handleMatterChange = event => {
            var creationData = this.state.creationData;
            creationData.description = event.target.value;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`event.target.value ${event.target.value}`);
          }
        
          getMatterValidationState() {
            const length = this.state.creationData.description.length;
            if ( length <= config.MAX_MATTER_LENGTH && length >= config.MIN_MATTER_LENGTH ) return 'success';
            else if ( length > 0 ) return 'error';
            return null;
          }
        
          floorChosen() {
            var creationData = this.state.creationData;
            creationData.initiator.acceptFloor = true;
            delete creationData.initiator.payCeiling;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`accepting floor`);
          }
        
          ceilingChosen() {
            var creationData = this.state.creationData;
            creationData.initiator.payCeiling = true;
            delete creationData.initiator.acceptFloor;
            this.setState({creationData}, () => this.validateForm());
            utilityFunctions.log(`accepting ceiling`);
          }
        
          handleAmountChange = event => {
            var creationData = this.state.creationData;
            var displayAmount = this.state.displayAmount;
            if (event.target.value !== '') {
              var amt = event.target.value;
              // replace all previous occurances of styled commas with empty character ex. 5,000,000 becomes 5000000
              amt = amt.replace(/[,]+/g, '');
              // remove all preceeding zeros ex. 00050 becomes 50
              amt = amt.replace(/^0+/, '');
              //check if input contains only 0-9 and 1 period. Otherwise dont update the field
              var notInSet = /[^0-9.]/.test(amt);
              var periodCount = (amt.match(/\./g) || []).length;
              utilityFunctions.log(`inSet ${!notInSet} periodCount ${periodCount}`);
              if (!notInSet && periodCount <= 1) {
                // check that input only contains 2 digits after period
                if (periodCount === 1) {
                  
                  utilityFunctions.log(`decimal count is 1 ${amt}`);
                  var periodSplit = amt.split('.');
                  
                  // check that we dont have more than 2 digits on right of decimal ex. 00.004722
                  if (periodSplit[1].length <= 2) {
                    utilityFunctions.log(`right side of decimal is less than 2`);
                    
                    // handle case for creation data amount where right side doesnt have value yet or full amount
                    if (periodSplit[1].length === 2) {
                      creationData.initiator.amount = `${periodSplit[0]}${periodSplit[1]}`;
                    } else if (periodSplit[1].length === 1) {
                      creationData.initiator.amount = `${periodSplit[0]}${periodSplit[1]}0`;
                    } else if (periodSplit[1].length === 0) {
                      creationData.initiator.amount = `${periodSplit[0]}00`;
                    }
                    creationData.initiator.amount = parseInt(creationData.initiator.amount, 10);
                    amt = `${periodSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${periodSplit[1]}`;
                    displayAmount = amt;
                    this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
                    return;
                  } else {
                   utilityFunctions.log('user entered more than 2 digits right of decimal so not updating');
                  }
                } else if (periodCount === 0) {
                  creationData.initiator.amount = parseInt(`${amt}00`, 10);
                  utilityFunctions.log('decimal count is 0 so just formating a integer value with commas');
                  amt = amt.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  displayAmount = amt;
                  this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
                } else {
                  utilityFunctions.log('user entered 2nd period so not updating');
                }
              }
            } else {
              displayAmount = 0;
              creationData.initiator.amount = 0;
              this.setState({displayAmount, creationData, hasEnteredAmount: true}, () => this.validateForm());
            };
          }
        
          getAmountValidationState() {
            if ( !!this.state.creationData.initiator.amount && this.state.creationData.initiator.amount > 0 ) return 'success';
            else if ( this.state.creationData.initiator.amount === 0 && this.state.hasEnteredAmount ) return 'error';
            return null;
          }
    }

  