import { API } from "aws-amplify";
import React, { Component } from "react";
import { FormControl, ListGroup, Modal, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ReactJson from 'react-json-view'

import utilityFunctions from "../../utilityFunctions";

export default class Promos extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        selectedPath: undefined,
        promoCodeLookup: undefined
    }
    utilityFunctions.log(`constructor Admin`);
    this.handleAdminFunctionChange = this.handleAdminFunctionChange.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
  }

  handleAdminFunctionChange(path) {
    utilityFunctions.log('handleAdminFunctionChange');
    if (path === '/promos/getAll') {
        utilityFunctions.log('handleAdminFunctionChange /promos/getAll');
        this.setState({selectedPath: '/promos/getAll'}, () => this.makeApiRequestGet('admin', '/promos'));
    } else if (path === '/promos/getPromo') {
        utilityFunctions.log('handleAdminFunctionChange /promos/getPromo');
        this.setState({selectedPath: '/promos/getPromo'}, () => this.makeApiRequestGet('admin', '/promos'));
    }
  }

  async makeApiRequestGet(api, path) {
    try {
        let apiResponse = await API.get(api, `${path}${this.state.selectedPath === '/promos/getAll' ? "" : `?promoCode=${this.state.promoCodeLookup}` }`);
        this.setState({promoCodeLookup: undefined});
        utilityFunctions.log(`GET apiResponse ${JSON.stringify(apiResponse)}`);
        if (apiResponse.Items.length > 0) {
          this.setState({
            apiResponse: {
              promoCodes: apiResponse.Items
            }
          });
        }
      } catch (e) {
        if (!!e.response && !!e.response.data) {
          if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
          }
          this.props.handleSetAppError();
          return
        }
        this.props.handleSetAppError();
      }
  }

  handleCloseModalPopover() {
    this.setState({showFullBox: false});
  }

  handlePromoCodeChange = event => {
    this.setState({promoCodeLookup: event.target.value});
    utilityFunctions.log(`event.target.value ${event.target.value}`);
  }

  clear() {
    utilityFunctions.log('clearing app state');
    this.setState({
      selectedPath: undefined,
      promoCodeLookup: undefined,
      apiResponse: undefined,
      showFullBox: false
    })
  }

  render() {
    if (this.props.userInfo !== undefined && this.props.userInfo.type !== "admin") {
      this.props.history.push('/profile');
      return (<div>NOT AUTHORIZED</div>)
    };
    return (
      this.props.completedStartup ?  
      <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Nav style={{backgroundColor: 'white', width: '100px'}} className="flex-column">
                <LinkContainer to="/promos/getAll">
                    <NavItem>
                      <div >
                        <button onClick={() => this.handleAdminFunctionChange('/promos/getAll')} style={{color: this.state.selectedPath === '/promos/getAll' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Get Promo Names</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/promos/getPromo">
                    <NavItem>
                      <div >
                        <button onClick={() => this.setState({showFullBox: true})} style={{color: this.state.selectedPath === '/promos/getPromo' ? '#669900' : 'grey', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Get Promo Details</font></button>
                      </div>
                    </NavItem>
                </LinkContainer>
            </Nav>
            <div style={{height: '500px', width: '90%', border: '1px solid lightGrey 0px border-bottom', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                <h3 style={{alignSelf: 'center'}}><u>{this.state.selectedPath === '/promos/getAll' ? 'ALL PROMOS' : this.state.selectedPath === '/promos/getPromo' ? 'PROMO DETAILS' : 'CLICK SIDE OPTION'}</u></h3><hr /><br />
                <button onClick={() => this.clear()} style={{color: 'red', backgroundColor: 'transparent', marginRight: '50px' }}><font face="Roboto, helvetica" >Clear</font></button>
                {
                  this.state.apiResponse ? 
                  <ListGroup>
                    <ReactJson src={this.state.apiResponse} />
                  </ListGroup> :
                  <div style={{margin: '20px', alignSelf: 'flex-start'}}>
                    <div>{`Instructions:`}</div><br />
                    <div>{`1) Choose 'Get Promo Names' first to retrieve PROMO IDs of all coupons in system.`}</div><br />
                    <div>{`2) Copy a PROMO CODE ID from step #1 and choose 'Get Promo Details' obtain specific info related to that coupon.`}</div><br />
                  </div>
                }
            </div>
            <div></div>
        </div><br />
        <div style={{display: 'flex', justifyContent: 'left'}}>
            {
                this.state.showFullBox &&
                <Modal show={this.state.showFullBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Promo Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormControl
                        onChange={this.handlePromoCodeChange}
                        placeholder="code"
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button onClick={this.handleCloseModalPopover} variant="secondary">Close</button>
                      <button onClick={() => {this.handleAdminFunctionChange('/promos/getPromo'); this.handleCloseModalPopover()}} variant="primary">Save</button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
      </div>
      : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}
