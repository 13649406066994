import React, { Component } from "react";
import { Button, Modal} from "react-bootstrap";

import "./VideoContainer.css";

export default class VideoContainer extends Component {

    constructor(props) {
        super(props);
        this.showVideo = this.showVideo.bind(this);
        this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
        this.state = {
            showFullBox: false
        }
    }

    showVideo() {
        this.setState({showFullBox: true});
    }

    handleCloseModalPopover() {
        this.setState({showFullBox: false});
    }

    render() {
      var videoSrc = "https://www.youtube.com/embed/" + 
          this.props.video + "?autoplay=" + 
          this.props.autoplay + "&rel=" + 
          this.props.rel + "&modestbranding=" +
          this.props.modest;
      return (
        <div style={{display: 'flex', justifyContent: !this.props.demo ? 'center' : 'left'}}>
            {
                this.state.showFullBox &&
                <Modal show={this.state.showFullBox} onHide={this.handleCloseModalPopover}>
                    <Modal.Header closeButton>
                        <Modal.Title>{!this.props.demo ? 'Negotiations without the dance.' : 'Instructional Video'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="videoBox">
                            <iframe title="DemoVideo" className="player" type="text/html" width="100%" height="100%" src={videoSrc} frameBorder="0" allowFullScreen="allowFullScreen"/>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            <Button style={{backgroundColor: 'transparent', border: 'none'}} onClick={this.showVideo}>
                {
                    !this.props.demo ?
                    <img style={{height: (document.documentElement.clientWidth <= 500) ? '100px' : '150px'}} src={ require(`../assets/condicoSite/playPowtoon.svg`) } alt="" /> :
                    <span>
                        <img style={{marginBottom: 4}} src={require("../assets/condicoSite/playButton.svg")} height="15" width="15" alt="playBtn" />
                        <font face="Roboto, sans-serif" size="3.5"><font face="Roboto, sans-serif" color="#99CC00"> Watch Short Video</font></font>
                    </span>
                }
                <br />
            </Button><br />
        </div>
      );
    }
  };