import React, { Component } from "react";
import { Breadcrumb, Col, Grid, Row } from "react-bootstrap";

import config from "../config.js";
import "./ReviewCondico.css";
import utilityFunctions from "../utilityFunctions";

const reviewStyles = {
    textHeaderLabel: {
      marginLeft: 12,
      fontSize: 17,
      color: '#232b2b',
      fontWeight: '700',
      textAlign: 'left',
      fontFamily: 'Roboto, helvetica'
    }, 
    textLabel: {
      marginLeft: 20,
      fontSize: 17,
      color: '#585858',
      fontWeight: '500',
      textAlign: 'left',
      fontFamily: 'Roboto, helvetica'
    }
  }

export default class ReviewCondico extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  contactCondico(condico) {
    if (!!condico) {
        this.props.setContactUsTransactionId({
            transactionId: condico.transaction,
            userId: condico.userId,
            dateTime: condico.dateTime
        });
    } else {
        return;
    }
  }

  render() {
    utilityFunctions.log("rendering")
    var willingText = "You were willing to accept: ", willingTextAmount = null;
    var reviewCondico, associateName, associateEmail, paidBy = "";
    if (!!this.props.reviewCondico) {
        reviewCondico = this.props.reviewCondico;
        var isInitiator = reviewCondico.userId === this.props.cognitoIdentityId;
        utilityFunctions.log("have reviewCondico")

        switch (reviewCondico.state) {
            case config.CondicoState.completed:
                if (isInitiator) {
                    if (!!reviewCondico.initiator.amount) {
                        if (!!reviewCondico.initiator.payCeiling && reviewCondico.initiator.payCeiling) {
                            willingText = "You were willing to pay: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.initiator.amount);
                        } else {
                            willingText = "You were willing to accept: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.initiator.amount);
                        }
                    }
                } else {
                    if( !!reviewCondico.successfulAttempt && !!reviewCondico.successfulAttempt.amount) {
                        if (!!reviewCondico.initiator.payCeiling && reviewCondico.initiator.payCeiling) {
                            willingText = "You were willing to accept: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.successfulAttempt.amount);
                        } else {
                            willingText = "You were willing to pay: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.successfulAttempt.amount);
                        }
                    }
                }
                break;
            case config.CondicoState.rescinded:
            case config.CondicoState.expired:
                if (isInitiator) {
                    if (!!reviewCondico.initiator.amount) {
                        if (!!reviewCondico.initiator.payCeiling && reviewCondico.initiator.payCeiling) {
                            willingText = "You were willing to pay: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.initiator.amount);
                        } else {
                            willingText = "You were willing to accept: ";
                            willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.initiator.amount);
                        }
                    }
                } else if (!!reviewCondico.lastFailedAttempt && !!reviewCondico.lastFailedAttempt.amount) {
                    willingText = "You had last attempted: ";
                    willingTextAmount = utilityFunctions.currencyInputFormatting(reviewCondico.lastFailedAttempt.amount);
                } else {
                    willingText = "You did not respond to this Condico";
                    willingTextAmount = "";
                }
                break;
            default:
                utilityFunctions.log("incorrect state within Review");

        }
        if (isInitiator) {
            if (!!reviewCondico.partner.firstname && !!reviewCondico.partner.lastname) {
                associateName = " " +  reviewCondico.partner.firstname + " " + reviewCondico.partner.lastname;
            } else {
                associateName =  " " + reviewCondico.initiator.partnersIntentFirstname + " " + reviewCondico.initiator.partnersIntentLastname;
            }
            if (!!reviewCondico.partner.business) {
                associateName = associateName + ` from ${reviewCondico.partner.business}`;
            }
            associateEmail = " " + reviewCondico.partner.email;
        } else {
            associateName = " " + reviewCondico.initiator.firstname + " " + reviewCondico.initiator.lastname;
            if (!!reviewCondico.initiator.business) {
                associateName = associateName + ` from ${reviewCondico.initiator.business}`;
            }
            associateEmail = "";
        }
        if (!!reviewCondico.payment && !!reviewCondico.payment.from) {
            if (reviewCondico.payment.from === "initiator") {
                paidBy = `by ${reviewCondico.initiator.firstname}`;
            } else if (reviewCondico.payment.from === "partner") {
                paidBy = `by ${reviewCondico.partner.firstname}`;
            } else {
                this.props.handleSetAppError();
            }
        }
    } else {
        utilityFunctions.log("prop not set")
    }
    if (!!willingTextAmount) {
        willingTextAmount = `$${willingTextAmount}`;
    }
    return (
        !!reviewCondico ? 
        <div style={{fontFamily: 'Roboto, helvetica'}}>
            <Breadcrumb>
            <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
            <Breadcrumb.Item active>Review</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div className="imageBox">
                    <Grid>
                        <Row className="show-grid">
                            <Col xs={0} sm={2} md={2} lg={2}>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8}>
                            {
                                reviewCondico.state === config.CondicoState.completed ? 
                                <img style={{objectFit: "cover"}} src={require("../assets/myCondico/reviewCondico/completed.jpeg")} height="380" width="100%" alt="stateImage" /> : reviewCondico.state === config.CondicoState.rescinded ? 
                                <img style={{objectFit: "cover"}} src={require("../assets/myCondico/reviewCondico/rescinded.jpeg")} height="380" width="100%" alt="stateImage" /> :
                                <img style={{objectFit: "cover"}} src={require("../assets/myCondico/reviewCondico/expired.jpeg")} height="380" width="100%" alt="stateImage" />
                            }
                            </Col>
                            <Col xs={0} sm={2} md={2} lg={2}>
                            </Col>
                        </Row>
                    </Grid>
                    <div className="stateBox" style={{borderRadius:"8px", position:"relative", margin:"-35px", width:"42%", height:"50px", backgroundColor:"var(--grey_theme"}}>
                        <div className="summaryBox">
                            <h2 style={{marginTop: "10px", marginRight: "4px", fontFamily: 'Roboto, helvetica'}}>{reviewCondico.state.charAt(0).toUpperCase() + reviewCondico.state.toLowerCase().slice(1)}</h2>
                            {
                                reviewCondico.state === config.CondicoState.completed ? 
                                <img style={{marginTop:"12px"}} src={require("../assets/myCondico/cell/completed.png")} height="30px" width="30px" alt="summaryImage" /> : reviewCondico.state === config.CondicoState.rescinded ? 
                                <img style={{marginTop:"12px"}} src={require("../assets/myCondico/cell/rescinded.png")} height="30px" width="30px" alt="summaryImage" /> :
                                <img style={{marginTop:"12px"}} src={require("../assets/myCondico/cell/expired.png")} height="30px" width="30px" alt="summaryImage" />
                            }
                        </div>
                    </div>
                </div><br />
                <Grid>
                    <Row className="show-grid">
                        <Col xs={0} sm={2} md={2} lg={2}>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={8}><br />
                            <div style={reviewStyles.textLabel}>{`${reviewCondico.description}`}</div><br />
                            <div style={reviewStyles.textHeaderLabel}>{`Partner details:`}</div>
                            <div style={reviewStyles.textLabel}>{associateName}</div>
                            <div style={reviewStyles.textLabel}>{associateEmail}</div>
                            <div style={reviewStyles.textHeaderLabel}>{`Created on:`}</div>
                            <div style={reviewStyles.textLabel}>{`${new Date(reviewCondico.dateTime).toLocaleTimeString().toString()}, ${new Date(reviewCondico.dateTime).toDateString()}`}</div>
                            {
                                reviewCondico.state === config.CondicoState.completed ? 
                                <div style={reviewStyles.textHeaderLabel}>{`Settled on:`}</div> : 
                                reviewCondico.state === config.CondicoState.rescinded ? 
                                <div style={reviewStyles.textHeaderLabel}>{`Rescinded on:`}</div> : 
                                <div style={reviewStyles.textHeaderLabel}>{`Expired on:`}</div>
                            }
                            {
                                reviewCondico.state === config.CondicoState.completed ?
                                <div style={reviewStyles.textLabel}>{`${new Date(reviewCondico.successfulAttempt.date).toLocaleTimeString().toString()}, ${new Date(reviewCondico.successfulAttempt.date).toDateString()}`}</div> :
                                reviewCondico.state === config.CondicoState.rescinded ?
                                <div style={reviewStyles.textLabel}>{`${new Date(reviewCondico.rescind.date).toLocaleTimeString().toString()}, ${new Date(reviewCondico.rescind.date).toDateString()}`}</div> :
                                <div style={reviewStyles.textLabel}>{`${new Date(reviewCondico.expiry).toLocaleTimeString().toString()}, ${new Date(reviewCondico.expiry).toDateString()}`}</div>
                            }
                            <div style={reviewStyles.textHeaderLabel}>{willingText}</div>
                            <div style={reviewStyles.textLabel}>{willingTextAmount}</div>
                            { 
                                reviewCondico.state === config.CondicoState.completed && 
                                <div>
                                    <div style={reviewStyles.textHeaderLabel}>{`Condico amount:`}</div>
                                    <div style={reviewStyles.textLabel}>{`$${utilityFunctions.currencyInputFormatting(reviewCondico.payment.condicoAmount)}`}</div>
                                </div>
                            }
                            { 
                                reviewCondico.state === config.CondicoState.completed && 
                                <div>
                                    <div style={reviewStyles.textHeaderLabel}>{`Fee paid:`}</div>
                                    <div style={reviewStyles.textLabel}>{`$${utilityFunctions.currencyInputFormatting(reviewCondico.payment.fee)} ${paidBy}`}</div>
                                </div>
                            }
                            <br />
                            <div style={{display: "flex", flexDirection: "row", marginLeft: -10}}>
                                <button onClick={() => this.contactCondico(reviewCondico)} style={{color:"var(--green_theme"}}><img src={require("../assets/moreTab/Envelope@3x.png")} height="20px" width="30px" alt="envelopeImage" />  Contact Condico about this transaction</button>
                            </div>
                        </Col>
                        <Col xs={0} sm={2} md={2} lg={2}>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}