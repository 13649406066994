import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button, OverlayTrigger, Popover } from "react-bootstrap";

import "./RescindCondico.css";
import utilityFunctions from "../utilityFunctions";

const rescindStyles = {
  textHeaderLabel: {
    marginLeft: 12,
    fontSize: 17,
    color: '#232b2b',
    fontWeight: '700',
    textAlign: 'left',
    fontFamily: 'Roboto, helvetica'
  }, 
  textLabel: {
    marginLeft: 20,
    fontSize: 17,
    color: '#585858',
    fontWeight: '500',
    textAlign: 'left',
    fontFamily: 'Roboto, helvetica'
  }
}

export default class RescindCondico extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.state = {
      authUserFirstLastname: undefined
    }
  }

  async componentWillMount() {
    utilityFunctions.isAuthenticated(this.props);
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (!!user) {
        this.setState({authUserFirstLastname: user.attributes['custom:givNameCus'] + " " + user.attributes['custom:famName_cus']})
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  handleContinue = event => {
    event.preventDefault();
    utilityFunctions.log("submitting form, seg to rescind summary")
    this.props.history.push("/mycondicos/rescind/rescindSummary");
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    var bubbleTxt = "You chose the option to receive a payment by the other party if there is room to settle. The amount is kept confidential and is used only by the system when determining if a settlement is possible.";
    var willingText = "to accept (a minimum of) ";
    var rescindCondico, partnerName;
    if (!!this.props.rescindCondico) {
        rescindCondico = this.props.rescindCondico;
        if (!!rescindCondico.initiator.payCeiling && rescindCondico.initiator.payCeiling) {
            willingText = "to pay (a maximum of) ";
            bubbleTxt = "You chose the option to make a payment to the other party if there is room to settle. The amount is kept confidential and is used only by the system when determining if a settlement is possible.";
        }
        if (!!rescindCondico.partner.firstname) {
            partnerName = rescindCondico.partner.firstname + " " + rescindCondico.partner.lastname;
        } else {
            partnerName = rescindCondico.initiator.partnersIntentFirstname + " " + rescindCondico.initiator.partnersIntentLastname;
        }
    }
    const popover = (
      <Popover id="modal-popover" title="Willing to accept/pay?">
        {bubbleTxt}
      </Popover>
    );
    return (
      !!rescindCondico && !!this.state.authUserFirstLastname  ? 
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
          <Breadcrumb.Item active>Rescind</Breadcrumb.Item>
        </Breadcrumb>
        <div>
            <div style={rescindStyles.textHeaderLabel}>Matter to resolve:</div><br />
            <div style={rescindStyles.textLabel}>{`  ${rescindCondico.description}`}</div><br />
            <div style={rescindStyles.textHeaderLabel}>{this.props.userInfo.business ? `${this.props.userInfo.business.name} is willing` : `I, ${this.state.authUserFirstLastname} am willing`}</div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={rescindStyles.textHeaderLabel}>{`${willingText} `}<span>&nbsp;&nbsp;</span></div><OverlayTrigger rootClose trigger="click" overlay={popover} placement="top"><img src={require("../assets/myCondico/infoBtn.png")} style={{marginTop: 5}} height="20" width="20" alt="infoPopoverBtn" /></OverlayTrigger><br />
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={rescindStyles.textHeaderLabel}><span>&nbsp;&nbsp;</span>{`CAD) C$:`}</div>
              <div style={rescindStyles.textLabel}>{`${utilityFunctions.currencyInputFormatting(rescindCondico.initiator.amount)}`}</div>
            </div>
            <div style={rescindStyles.textHeaderLabel}>{`to resolve this matter.`}</div><br />
            <div style={rescindStyles.textHeaderLabel}>{`Expires:`}</div>
            <div style={rescindStyles.textLabel}>{`${new Date(rescindCondico.expiry).toLocaleTimeString().toString()}, ${new Date(rescindCondico.expiry).toDateString()}`}</div>
            <div style={rescindStyles.textHeaderLabel}>{`Partner name:`}</div>
            <div style={rescindStyles.textLabel}>{!!rescindCondico.partner.business ? `${partnerName} from ${rescindCondico.partner.business}` : `${partnerName}`}</div>
            <div style={rescindStyles.textHeaderLabel}>{`Partner email:`}</div>
            <div style={rescindStyles.textLabel}>{rescindCondico.partner.email}</div><br /><br />
            <div className= "ButtonContainer">
              <Button
                className="button"
                onClick={this.handleContinue}
                block
                bsSize="large"
              >
                Continue
              </Button>
            </div>
        </div>
      </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}