import React, { Component } from "react";
import { Alert, Breadcrumb } from "react-bootstrap";
import FeeSchedule from "./FeeSchedule";

import utilityFunctions from "../utilityFunctions";
import config from "../config";

class TermsAndConditions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFees: false
        }
    }

    render() {
        var newProps = this.props;
        if (!!this.props.childProps) {
            newProps = this.props.childProps;
        }
        if (!!newProps.isAuthenticated && !!newProps.appSettings && (newProps.appSettings.eula.termsVersion !== "1" || newProps.appSettings.eula.feeVersion !== "1")) {
            utilityFunctions.log(`in auth terms and appSettings is NOT available ${JSON.stringify(newProps.appSettings)}`);
            return (
                <Alert style={{marginTop:"15px"}} bsStyle="danger" onDismiss={this.dismissError}>
                    <h4>Wrong Version</h4>
                    <p>
                        Sorry, refresh your browser to retrieve the latest Terms and Conditions.
                    </p>
                </Alert>
            )
        } else if (!!newProps.isAuthenticated && newProps.appSettings === undefined) {
            return <div style={{marginTop: '30px'}}>loading...</div>
        } else {
            utilityFunctions.log(`in auth terms and appSettings is available ${JSON.stringify(newProps.appSettings)}`);
            return (
                <div style={{fontFamily: 'Roboto, helvetica'}}>
                    {
                        (!!newProps.isAuthenticated && this.props.acceptingTerms === undefined && !!newProps.location && !this.state.showFees && !!newProps.location.pathname && newProps.location.pathname === "/more/terms") ? 
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={(e) => newProps.history.goBack()} >More</Breadcrumb.Item>
                            <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
                        </Breadcrumb> : (!!newProps.isAuthenticated && this.props.acceptingTerms === undefined && !!newProps.location && this.state.showFees && !!newProps.location.pathname && newProps.location.pathname === "/more/terms") ?
                            <Breadcrumb>
                            <Breadcrumb.Item onClick={(e) => newProps.history.goBack()} >More</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={(e) => this.setState({showFees: false})} >Terms and Conditions</Breadcrumb.Item>
                            <Breadcrumb.Item active>Fee Schedule</Breadcrumb.Item>
                        </Breadcrumb> : !!newProps.isAuthenticated && this.props.acceptingTerms === undefined ?
                            <Breadcrumb>
                            <Breadcrumb.Item onClick={(e) => newProps.history.goBack()} >Go Back</Breadcrumb.Item>
                            <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
                        </Breadcrumb> : null
                    }
                    { 
                        !this.state.showFees ?
                        <section className="wrapper style2 spotlights">
                            <section id="one">
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <div className="inner"><br />
                                        <font size="+2"><font color="#333333"> Terms and Conditions</font></font><br /><br />
                                        1. Condico is an automated tool for resolving a negotiation over a single dollar value.<br/>
                                        2. Condico is operated by Condico Corporation, an Ontario company. In these Terms and Conditions, where reference is made to "us" or "we," the reference is to Condico Corporation. Where reference is made to “you”, it refers to the individual user, or, where a user has registered a business account, the business the user represents. In exchange for the use of Condico, you accept and agree to the following terms and conditions, as may be modified from time to time with notice to you: 
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <font size="+2"><font color="#99CC00">I.</font> The Basics</font><br />
                                        1.	With Condico, each transaction has two parties: the payor and the payee.<br /><br />
                                        2.	In each transaction the payor is the person who will be paying the money at issue if a resolution to the issue under negotiation is achieved. The payee is the person who will be accepting that money in exchange for a release of all rights in connection with the matter under negotiation.<br/><br/>
                                        3.	Each transaction also has an initiator and partner. The initiator is the party that started the transaction, setting out the subject matter of the negotiation and naming the other party to the condico transaction called the partner. An initiator can be either a payor or payee.<br /><br />
                                        4.	Where a transaction results in a settlement, that’s called a “condico.”<br /><br />
                                </div>
                            </section>
                            <section id="xone">
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <font size="+2"><font color="#99CC00">II.</font> Registration</font><br/>
                                        1.	If you wish to use Condico, you need to register.  Registration is offered for individuals and businesses. <br/><br/>
                                        2.	To register as an individual, you must provide a first and last name, phone number and an email address at which we can contact you.  You also create a username and password for your account.  Where you register as a business, you provide the business name as well.<br/><br/>
                                        3.	If you register with Condico, you agree that we may store the information you entered and use it to contact you at all steps of a transaction, or otherwise at our discretion.<br/><br/>
                                        4.	Condico agrees not to distribute your registration information to any third party except as may be required by law and except, with respect to your first name, last name and email address, as may be required to facilitate the enforcement of a condico.<br/><br/>
                                        5.	You agree to enter only your own, true, first and last names when registering.  In the case of a business registration, you agree to use the legal business name, or trade name of your business. You also promise that the email address entered at the time of registration will be your email address, or in the case of a business registration, the email address of a representative authorized to settle the transaction.<br/><br/>
                                        6.	You agree not to share with others the password you enter when registering.<br/><br/>
                                </div>
                            </section>
                            <section id="one">
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <div className="inner">
                                        <font size="+2"><font color="#99CC00">III.</font> The Condico Transaction</font><br /><br />
                                            1.	The initiator starting a new transaction shall provide a brief and accurate description of the subject matter of the negotiation. For example, if the negotiation concerns unsatisfied invoice No. 123 rendered by ABC Company for $100, the description of the subject matter of the negotiation might read: " invoice number 123 rendered by ABC Company dated January 1, 2019." By way of a further example, if the negotiation concerned payment for the painting of the garage at 123 Side St. on January 1, 2019, the description of the subject matter of the negotiation might read: " painting the garage at 123 Side St. on January 1, 2019." And if the negotiation concerned the all-inclusive sum at which a lawsuit were to be settled, the description of the subject matter of the negotiation might read: " action commenced in the City of Toronto under Ontario Superior Court of Justice Court File No. CV-1600001."<br /><br />
                                            2.	Parties to a transaction agree that all figures referenced are in Canadian Dollars unless expressly stated otherwise in the description of the subject matter of the negotiation.<br /><br />
                                            3.  An initiator shall enter the real name and accurate contact email address for the partner to the initiator's transaction, to the best of the initiator's knowledge.<br /><br />
                                            4.	After the initiator has described the subject matter of the negotiation, and entered the partner's contact information, the initiator is invited to submit the transaction. At this stage, the entry of valid credit card information is required to continue, unless the initiator makes use of a promo code, the terms of which effectively permit the by-pass of credit card entry at this stage. We use a third party service provider, Stripe, to store and process credit card information entered and effect any financial transactions carried out in connection with Condico’s service.<br /><br />
                                            5.	With credit card information or a promo code entered, the initiator must then review and confirm its acceptance of the initiator’s offer. Confirming the offer results in Condico sending an email, on behalf of and as agent of the initiator, to the partner at the email address provided by the initiator. The email explains what the transaction is all about, and invites the partner to participate. Where you are the initiator, you take full responsibility for any liability that Condico may be incur in connection with what may be an unsolicited contacting of the partner, including responsibility for any legal fees arising out of Condico's defence of any allegations made against Condico, in an adjudicative proceeding or otherwise arising out of the email.<br /><br />
                                            6.	Condico is subject to Canadian legislation concerning the sending of commercial electronic messages commonly referred to as Canada’s Anti-Spam Legislation or “CASL." CASL permits Condico to send the above-noted email to the partner only where the initiator has, among other types of relationships, an existing business relationship or a personal relationship with the partner, as those relationships are defined in CASL. Accordingly, by using Condico you confirm that you have at least:<br />
                                                a.  A business relationship with the partner arising from:<br />
                                                    i.      the purchase or lease of a product, goods, a service, land or an interest or right in land, within the two-year period immediately before the day on which the initiator begins the transaction, by the partner from the initiator;<br />
                                                    ii.     the acceptance by the partner within the two-year period immediately before the day on which the initiator begins the transaction of a business, investment or gaming opportunity offered by the initiator;<br />
                                                    iii.    the bartering of anything mentioned in paragraph "(i)", above, between the partner and the initiator;<br />
                                                    iv.     a written contract entered into between the partner and the initiator in respect of a matter not referred to in paragraphs "(i)" to "(iii)", above, and the contract either continues to be in existence or expired within the two-year period immediately before the day on which the initiator begins the transaction; or<br />
                                                    v.      an inquiry or application, within the six-month period immediately before the day on which the initiator begins the transaction, made by the partner to the initiator in respect of anything mentioned in any of paragraphs "(i)" to "(iii)", above; or<br />
                                                b.  direct, voluntary, two-way communication with the partner and it would be reasonable to conclude that the initiator and the partner have a personal relationship, taking into consideration any relevant factors such as the sharing of interests, experiences, opinions and information evidenced in the communications, the frequency of communications, the length of time since the initiator and partner communicated or whether the initiator and partner have met in person.<br /><br />
                                            7.	Where you are the initiator, you understand that initiating a transaction may result in a binding agreement obligating you to pay the partner, in a case where you are the payor, or release the partner from all liability to you in connection with the subject matter of the transaction in a case where you are the payee.<br /><br />
                                            8.	On receipt of the email arising out of the creation of a new transaction, the partner may choose to participate in the transaction described in the email the partner has received. Where you are the partner, you accept that the email is being received from Condico in Condico's capacity as agent for the initiator. You understand that participating in a transaction may result in a binding agreement obligating you to pay the initiator, in a case where you are the payor, or release the initiator from all liability to you in connection with the subject matter of the transaction in a case where you are the payee.<br /><br />
                                            9.	Where a transaction results in a condico, you agree that the place of the contract (i.e., the binding agreement which is a condico), is the Province of Ontario, Canada.<br /><br />
                                            10. You acknowledge that Condico provides no representation that a release provided to the payor in a condico operates in all circumstances as a bar to future proceedings before an adjudicator in the payee's jurisdiction.<br /><br />
                                            11. You agree that the use of Condico is governed by the laws of the Province of Ontario, and that the courts of the Province of Ontario shall have exclusive jurisdiction over any dispute with Condico Corporation pertaining to, or arising out of your use of, Condico.<br /><br />
                                    </div>
                                </div>
                            </section>
                            <section id="one">
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <div className="inner">
                                        <font size="+2"><font color="#99CC00">IV.</font> Payment for Condicos</font><br /><br />
                                            1.  Where a condico is made, such that a consensual resolution of the subject of negotiations is achieved, subject to certain instances where a promo code is provided, we require payment from the initiator in accordance with Condico’s <a onClick={() => this.setState({showFees: true})}><font color="#99CC00">Fee Schedule</font></a> before the figure at which the negotiation has settled is released to either of the initiator or partner. You agree that we have no obligation to release any information about the condico to either party in advance of payment.<br /><br />
                                            2.	Where we have valid credit card information for the initiator at the time a condico is reached, payment of Condico's fee, if any, is made by charging the credit card the initiator has identified.<br /><br />
                                            3.  In circumstances where we do not have valid credit card information from the initiator at the time a condico is reached, and a fee applies, we give the option to the partner to pay Condico's fee so that the settlement figure can be released to both parties.<br /><br />
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <div className="inner">
                                        <font size="+2"><font color="#99CC00">V.</font> Proof of Settlement</font><br /><br />
                                            1.  Where a condico is achieved, you receive an email notifying you of the settlement.  That email may attach a certificate of settlement setting out the particulars of the condico.  The particulars include: (a) the names and email addresses of the parties to the condico (in the case of a business, the email address of the business representative who registered with Condico); (b) the time at which the offer that led to the settlement was made; (c) the time at which the offer was accepted; and (d) a description of the matter settled and the amount of the settlement.<br /><br />
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div style={{hyphens: "auto", textAlign:'justify'}} className="content">
                                    <div className="inner">
                                        <font size="+2"><font color="#99CC00">VI.</font> Promo Codes</font><br /><br />
                                            1.  Condico may offer any user the right to use a promo code.<br /><br />
                                            2.	While a promo code may offer a fee discount or fee waiver on transactions for some specified or indefinite period of time, Condico reserves the right to unilaterally set the terms of any offered promo code, and modify or replace such terms at any time.  Condico also reserves the right to withdraw a promo code at its sole discretion at any time.<br /><br />
                                            3.  Notwithstanding the foregoing, Condico will not modify the terms of, or withdraw, a promo code for use in a transaction that has already been created.
                                    </div>
                                </div>
                            </section>
                        </section> :
                        <div>
                            {(this.props.acceptingTerms !== undefined || (newProps.isAuthenticated === undefined || newProps.isAuthenticated === false) ) && <button style={{color: config.ThemeColor}} onClick={() => this.setState({showFees: false})}>Back to Terms and Conditions</button>}
                            <FeeSchedule />
                        </div>
                    }
                </div>
            );
        }   
    }
}

export default TermsAndConditions;