import { API } from "aws-amplify";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

import "./TermsAndConditionsAuth.css";
import TermsAndConditions from "../components/TermsAndConditions";
import utilityFunctions from "../utilityFunctions";

export default class TermsAndConditionsAuth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      termsChanged: false
    }
  }

  async handleConfirmation() {
    utilityFunctions.log("user accepted terms");
    this.props.hideLoader(false);
    try {
      let termsResponse = await this.acceptTerms();
      this.checkResult(termsResponse);
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
  }

  acceptTerms(data) {
    return API.post("users", "/terms/1?versioned=true", {
      body: {action: "termsAccepted"}
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`terms response is ${JSON.stringify(response)}`);
    if (!!response.userInfo.termsAndConditions) {
        utilityFunctions.log("success");
        if (this.props.partnerLinkData === undefined && !this.props.requirePersonalInfo) {
          this.props.handleTermsAcceptance(response.userInfo, true, (success) => {
            if (!success) {
              this.props.handleSetAppError();
              return;
            }
            this.props.history.push("/home");
          });
        } else if (this.props.partnerLinkData === undefined && this.props.requirePersonalInfo) {
          this.props.handleTermsAcceptance(response.userInfo, true, (success) => {
            if (!success) {
              this.props.handleSetAppError();
              return;
            }
            this.props.history.push("/collectPersonalInfo");
          });
        } else {
          this.props.handleTermsAcceptance(response.userInfo, true, (success) => {
            if (!success) {
              this.props.handleSetAppError();
            }
          });
        }
    } else {
        utilityFunctions.log("failure");
        this.props.handleSetAppError();
        this.props.history.push("/");
    }
  }

  async componentDidMount() {
    utilityFunctions.log("terms componentDidMount")
    //this.props.handleTermsAcceptance(true);
    if (!!this.props.userInfo && !!this.props.appSettings) {
        if (!!this.props.userInfo.termsAndConditions) {
            var userTermsArray = this.props.userInfo.termsAndConditions;
            var dateUserAcceptedTerms = userTermsArray[userTermsArray.length - 1]['termsAcceptedOn'];
            var dateTermsChanged = this.props.appSettings.eula["lastModified"];
            utilityFunctions.log(` new Date(dateUserAcceptedTerms) ${new Date(dateUserAcceptedTerms)}`);
            utilityFunctions.log(` new Date(dateTermsChanged) ${new Date(dateTermsChanged)}`);
            if (new Date(dateUserAcceptedTerms) <= new Date(dateTermsChanged)) {
                utilityFunctions.log("IN IF")
                this.setState({termsChanged: true});
              return;
            } 
        }
    } else {
        this.props.history.push("/");
        return;
    }
  }

  render() {
    return (
      <div className="Terms">
        {
            this.state.termsChanged ? 
            <h4>Our Terms Have Changed - scroll to bottom to accept &darr;</h4> : 
            <div>
              <h4 style={{textAlign: 'center'}}> Scroll to bottom to accept &darr;</h4>
            </div>
        }
        <div className="scrollView">
            <TermsAndConditions childProps={this.props} acceptingTerms={this.state.termsChanged ? "firstTime" : "newTerms"}/>
        </div>
        {
          !!this.props.appSettings && this.props.appSettings.eula.termsVersion === "1" && this.props.appSettings.eula.feeVersion === "1" && <div className= "ButtonContainer">
            <Button
              className="button"
              onClick={() => this.handleConfirmation()}
              block
              bsSize="large"
            >
              Accept Terms
            </Button>
          </div>
        }
      </div>
    );
  }
}