import React, { Component } from "react";

import utilityFunctions from "../utilityFunctions";

class SummaryView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          bulletsWithTextReplacements: undefined
        }
    }

    componentWillMount() {
        utilityFunctions.log("componentWillMount");
        utilityFunctions.isAuthenticated(this.props);
        var initiatorBusiness = undefined;
        var partnerBusiness = undefined;
        if (!!this.props.condicoData.initiator.business) {
            initiatorBusiness = this.props.condicoData.initiator.business;
        }
        if (!!this.props.condicoData.partner.business) {
            partnerBusiness = this.props.condicoData.partner.business;
        }
        var newBullets = this.props.bulletPoints.map((bullet) => {
            return utilityFunctions.performStringReplacements(
                bullet,
                this.props.condicoData.description,
                condicoExpiry(this.props.condicoData),
                this.props.last4,
                this.props.condicoData.initiator.firstname,
                this.props.condicoData.initiator.lastname,
                initiatorBusiness,
                this.props.condicoData.initiator.email,
                this.props.condicoData.initiator.partnersIntentFirstname,
                this.props.condicoData.initiator.partnersIntentLastname,
                this.props.condicoData.partner.email,
                partnerBusiness,
                this.props.condicoData.initiator.amount,
                this.props.partnerAmount,
                condicoAmt(this.props.condicoData)
            )
        });
        this.setState({bulletsWithTextReplacements: newBullets});
      }
    
    render() {
        return (
            <div>
                {
                !!this.state.bulletsWithTextReplacements && 
                <div>
                    {
                        this.renderBullets(this.state.bulletsWithTextReplacements)
                    }
                </div>
            }
            </div>
        )
    }

    renderBullets(bullets) {
        const bulletObjects = Object.values(bullets);
        var strParts = [];
        for (var i = 0; i < bulletObjects.length; i++){
            strParts.push(
            <div key={i}>
                {this.splitPart(bulletObjects[i], "terms")}
            </div>);
        }
        return(<div>{strParts}</div>);
    }

    splitPart(str) {
        var newStr = str.replaceAll("Terms and Conditions", "~?~");
        str = newStr.replaceAll("Fee Schedule", "~%~")
        var parts = str.split("~");
        for (var i = 0; i < parts.length; i += 1) {
            if (parts[i] === "?") {
                parts[i] = <button key={i} style={{backgroundColor: 'transparent', border: 'none', color:"var(--green_theme"}} onClick={() => this.props.childProps.history.push("/terms")}>Terms and Conditions</button>;
            } else if (parts[i] === "%") {
                parts[i] = <button key={i} style={{backgroundColor: 'transparent', border: 'none', color:"var(--green_theme"}} onClick={() => this.props.childProps.history.push("/fees")}>Fee Schedule</button>;
            }
        }
        return <div>{parts}</div>;
    }
}

function condicoExpiry(condicoData) {
    var condicoExpiry = undefined;
    if (!!condicoData.expiry) {
        condicoExpiry = condicoData.expiry;
    }
    return condicoExpiry;
}


function condicoAmt(condicoData) {
    var condicoAmt = undefined;
    if (!!condicoData.payment && !!condicoData.payment.condicoAmount) {
        condicoAmt = condicoData.payment.condicoAmount
    }
    return condicoAmt;
}

export default SummaryView;
