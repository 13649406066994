import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button, Modal } from "react-bootstrap";

import "./PayCondico.css";
import utilityFunctions from "../utilityFunctions";

export default class PayCondico extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.handleCloseCCModal = this.handleCloseCCModal.bind(this);
    this.state = {
      defaultCCExists: undefined,
      displayMissingCreditCardModal: false
    }
  }

  async componentWillMount() {
    utilityFunctions.isAuthenticated(this.props);
    utilityFunctions.log("payCondico componentWillMount");
    try {
      let retrieveCardsResponse = await API.get("payments", "/card")
      utilityFunctions.log(`retrieveCardsResponse ${JSON.stringify(retrieveCardsResponse)}`);
      if (retrieveCardsResponse.sources.length > 0) {
        utilityFunctions.log(`retrieveCardsResponse.sources[0] ${retrieveCardsResponse.sources[0]}`);
        this.setState({
          defaultCCExists: true
        });
        this.props.setLast4(retrieveCardsResponse.sources[0].last4);
      }
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
  }

  handleContinue = event => {
    event.preventDefault();
    if (!!this.state.defaultCCExists && this.state.defaultCCExists) {
      utilityFunctions.log("clicked continue, seg to pay summary")
      this.props.history.push("/mycondicos/pay/paySummary");
    } else {
      this.setState({displayMissingCreditCardModal: true})
    }
  }

  handleCloseCCModal() {
    this.setState({ displayMissingCreditCardModal: false }, () => this.props.history.push("/profile"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  navigateToFeeSchedule(props) {
    this.props.history.push("/fees");
  }
  
  render() {
    var payCondico, associateName;
    if (!!this.props.payCondico) {
        payCondico = this.props.payCondico;
        if (payCondico.userId === this.props.cognitoIdentityId) {
            if (!!payCondico.partner.firstname && !!payCondico.partner.lastname) {
                associateName = payCondico.partner.firstname + " " + payCondico.partner.lastname;
            } else {
                associateName =  payCondico.initiator.partnersIntentFirstname + " " + payCondico.initiator.partnersIntentLastname;
            }
            if (!!payCondico.partner.business) {
                associateName = associateName + ` from ${payCondico.partner.business}`;
            }
        } else {
            associateName = payCondico.initiator.firstname + " " + payCondico.initiator.lastname;
            if (!!payCondico.initiator.business) {
                associateName = associateName + ` from ${payCondico.initiator.business}`;
            }
        }
    }
    return (
      !!payCondico ? 
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
          <Breadcrumb.Item active>Pay</Breadcrumb.Item>
        </Breadcrumb>
        <div>
            Resolved matter: <br />
            <p>{payCondico.description}</p>
            Other party to the settlement:<br />
            <span style={{marginLeft: "10px"}}>{associateName}</span><br /><br />
            <h4>The settlement figure will be revealed to both parties to the negotiation on payment of the Condico’s fee.  To pay Condico’s fee, in accordance with the <button style={{backgroundColor: 'transparent', border: 'none', color:"var(--green_theme)"}} onClick={() => this.navigateToFeeSchedule()} >Fee Schedule</button>, click below</h4>
            <div className= "ButtonContainer">
              <Button
                className="button"
                onClick={this.handleContinue}
                block
                bsSize="large"
              >
                Continue
              </Button>
            </div>
        </div>
        <div className="static-modal">
            <Modal show={this.state.displayMissingCreditCardModal}>
              <Modal.Header>
                <Modal.Title>Missing Credit Card Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please add credit card information to your profile.  There’s no charge to initiate a condico, but charges apply where a settlement is achieved, according to the <button style={{backgroundColor: 'transparent', border: 'none', color:"var(--green_theme)"}} onClick={() => this.navigateToFeeSchedule()} >Fee Schedule</button>.</Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleCloseCCModal}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
      </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}