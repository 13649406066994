import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb } from "react-bootstrap";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideoContainer from "../components/VideoContainer";

import "./FAQ.css";
import utilityFunctions from "../utilityFunctions";

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: "#97D352",
    },
    heading: {
        fontSize: theme.typography.pxToRem(30),
        color: "#fff",
        fontWeight: theme.typography.fontWeightRegular,
    },
    expansion: {
        backgroundColor: "#fff",
    }
});

function FAQExpansionPanel(props) {
    var sortedFAQs;
    if(!!props.appSettings) {
        sortedFAQs = sortFAQ(props.appSettings.swiftFaqTexts);
    }
    const { classes } = props;
    return (
        !!props.appSettings && 
        <div style={{fontFamily: 'Roboto, helvetica'}}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={(e) => props.history.goBack()} >More</Breadcrumb.Item>
                <Breadcrumb.Item active>Help - FAQs</Breadcrumb.Item>
            </Breadcrumb>
            {renderTopLevelExpansionPanels(sortedFAQs, classes)}
        </div>
    );
}

const renderTopLevelExpansionPanels = (sortedFAQs, classes) => {
    var titles = [];
    for(let key in sortedFAQs) {
        titles.push(key);
        utilityFunctions.log(`key ${key}`)
    }
    titles.push("See Demo");
    return (
        <div>
            {
                titles.map((value,i) => 
                    i !== titles.length - 1 ?
                    <div key={i}> 
                        <ExpansionPanel className={classes.root}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>{value}</Typography>
                            </ExpansionPanelSummary>
                            {renderBottomLevelOfPanels(sortedFAQs[value],classes)}
                        </ExpansionPanel>
                    </div> : 
                    <div key={i}> 
                        <ExpansionPanel className={classes.root}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>{value}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansion}>
                                <div style={{backgroundColor: 'white'}}><VideoContainer video="FtrDtp27Gt0" autoplay="0" rel="0" modest="1" /></div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                )
            }
        </div>
    )
}

const renderBottomLevelOfPanels = (sortedAnswers, classes) => {
    return (
        <div>
            {
                sortedAnswers.map((value,i) => 
                    <div key={i}> 
                        <ExpansionPanelDetails className={classes.expansion}>
                            <Typography variant="display1">
                                <span>{value[0]}</span>
                            </Typography>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.expansion}>
                            <Typography variant="title">
                                <span>{value[1]}</span>
                            </Typography>
                        </ExpansionPanelDetails>
                    </div>
                )
            }
        </div>
    )
}

FAQExpansionPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

function sortFAQ(unorderedFaqs) {
    const orderedFaq = {};
    Object.keys(unorderedFaqs).sort().forEach(function(key) {
        var newKey = key.split(":")[1];
        orderedFaq[newKey] = sortSubProperties(unorderedFaqs[key]);
    });
    return orderedFaq;
}

function sortSubProperties(obj)
{
  // convert object into array
	var sortable=[];
	for(var key in obj)
		if(obj.hasOwnProperty(key))
			sortable.push([key, obj[key]]); // each item is an array in format [key, value]
	
	// sort items by value
	sortable.sort(function(a, b)
	{
		var x=a[1].toLowerCase(),
			y=b[1].toLowerCase();
		return x<y ? -1 : x>y ? 1 : 0;
    });

    // removing sort index that we have in strings i.e. 0: 1: etc...
    for (var i = 0; i < sortable.length; i++) { 
        //utilityFunctions.log(`sortable[i] ${sortable[i]}`)
        sortable[i][1] = sortable[i][1].split(":")[1];
    }
	return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
}
export default withStyles(styles)(FAQExpansionPanel);