import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Alert, Button, ControlLabel, FormGroup, FormControl, HelpBlock, InputGroup } from "react-bootstrap";

import config from "../config";
import "./ForgotPassword.css";
import utilityFunctions from "../utilityFunctions";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleRequestSubmit = this.handleRequestSubmit.bind(this);
    this.state = {
      email: "",
      codeSent: false,
      code: "",
      password: "",
      reset: false
    };
  }

  validateUpdateForm() {
    return this.getCodeValidationState() === "success" && this.getPasswordValidationState() === "success";
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  getCodeValidationState() {
    if ( !!this.state.code.length && this.state.code.length > 0 ) return "success";
    else if ( !!this.state.code.length && !!this.state.code.length === 0) return "error";
    return null;
  }

  getPasswordValidationState() {
    if (!!this.state.password && utilityFunctions.passwordIsOfMinStrength(this.state.password)) return 'success';
    else if (!!this.state.password && this.state.password.length > 0 ) return 'error';
    return null;
  }

  async handleRequestSubmit() {
    this.props.hideLoader(false);
    try {
        utilityFunctions.log("sending new password code...");
        await Auth.forgotPassword(this.state.email);
        this.setState({codeSent: true})
    } catch (e) {
        utilityFunctions.log("error on forgot password is: ", this.props.isAuthenticated);
        //if (e.message === "User is not confirmed.") {
          //Auth.resendSignUp('roy2');
        //}
        this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  async handleUpdateSubmit() {
    this.props.hideLoader(false);
    try {
        utilityFunctions.log("updating to new password ...");
        await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password);
        this.setState({reset: true});
    } catch (e) {
        utilityFunctions.log(`error on update password is: ${e}`);
        //if (e.message === "User is not confirmed.") {
          //Auth.resendSignUp('roy2');
        //}
        this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  navigateToLogin() {
    this.props.history.push("/login");
  }

  renderForm() {
    return (
      !this.state.codeSent && !this.state.reset ? 
      <form onSubmit={this.handleRequestSubmit} style={{paddingLeft: "10px"}}>
        <FormGroup 
          controlId="email" 
          bsSize="large"
        >
          <ControlLabel ><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Email or Username</font></font></ControlLabel>
          <InputGroup>
            <FormControl
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </InputGroup>
        </FormGroup>
        <Button
          className="button"
          block
          bsSize="large"
          onClick={this.handleRequestSubmit}
          >
            <font style={{fontWeight:"600", fontSize: '20px'}} face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "black" : "white"}>Recover Password</font>
          </Button>
      </form> : this.state.codeSent && !this.state.reset ?
      <form onSubmit={() => this.handleUpdateSubmit()}>
        <FormGroup 
          controlId="code" 
          bsSize="large"
          validationState={this.getCodeValidationState()}
        >
          <ControlLabel ><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>Code</font></font></ControlLabel>
          <InputGroup>
            <InputGroup.Addon>#</InputGroup.Addon>
            <FormControl
              type="text"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </InputGroup>
          <FormControl.Feedback />
              { (this.getCodeValidationState() === "success" || this.getCodeValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>a 4-7 digit code</HelpBlock> }
        </FormGroup>
        <FormGroup 
          controlId="password"
          bsSize="large"
          validationState={this.getPasswordValidationState()}
        >
          <ControlLabel ><font style={{fontWeight:"600", fontSize: '16px'}} ><font face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "white" : "black"}>New Password</font></font></ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
          <FormControl.Feedback />
              { (this.getPasswordValidationState() === "success" || this.getPasswordValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>* 8 chars, number, uppercase, lowercase, special char</HelpBlock> }
        </FormGroup>
        <Button
            className="button"
            block
            bsSize="large"
            disabled={!this.validateUpdateForm()}
            onClick={() => this.handleUpdateSubmit()}
            >
              <font style={{fontWeight:"600", fontSize: '20px'}} face="Roboto, helvetica" color={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "black" : "white"}>Update Password</font>
        </Button>
      </form> : 
      <Alert bsStyle="success" onDismiss={this.dismissError}>
        <h4>Password is reset</h4>
        <Button href="/login" onClick={() => this.navigateToLogin()} >Please try logging in</Button>
      </Alert>
    );
  }

  render() {
    return (
      <div className={config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? "ForgotPasswordLeadPages" : "ForgotPassword"}>
        {this.renderForm()}
      </div>
    );
  }
}