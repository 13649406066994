import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './registerServiceWorker';

import App from "./App";
import config from "./config";
import "./index.css";

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.getEnvironmentConfig(config.Environment).cognito.REGION,
      userPoolId: config.getEnvironmentConfig(config.Environment).cognito.USER_POOL_ID,
      identityPoolId: config.getEnvironmentConfig(config.Environment).cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.getEnvironmentConfig(config.Environment).cognito.APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "users",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayUsers.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayUsers.REGION
        },
        {
          name: "settings",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewaySettings.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewaySettings.REGION
        },
        {
          name: "data",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayData.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayData.REGION
        },
        {
          name: "transactions",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayTransactions.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayTransactions.REGION
        },
        {
          name: "payments",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayPayments.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayPayments.REGION
        },
        {
          name: "appLinks",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayAppLinks.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayAppLinks.REGION
        },
        {
          name: "contactUs",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayContactUs.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayContactUs.REGION
        },
        {
          name: "publicContactUs",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayPublicContactUs.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayPublicContactUs.REGION
        },
        {
          name: "promos",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayPromos.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayPromos.REGION
        },
        {
          name: "admin",
          endpoint: config.getEnvironmentConfig(config.Environment).apiGatewayAdmin.URL,
          region: config.getEnvironmentConfig(config.Environment).apiGatewayAdmin.REGION
        },
      ]
    }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
serviceWorker.unregister();