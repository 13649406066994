/* instructions: 
1) just change default Environment setting between development & production. 
2) select the correct indexHtmlOption and copy / paste over index.html
*reference: https://www.sistrix.com/ask-sistrix/google-index-google-bot-crawler/how-can-i-remove-a-url-on-my-website-from-the-google-index/#Option_1_Use_the_robots_meta-element_with_NOINDEX
* Ensure that if Terms has been updated that we increment the version # inside both TermsAndConditionsAuth.js and TermsAndConditions.js
*/
export default Object.freeze({
    Environment: "production",
    CondicoState: {"new":"NEW", "rescinded":"RESCINDED", "attempted":"ATTEMPTED", "completed":"COMPLETED", "expired":"EXPIRED", "pendingPayment":"PENDING_PAYMENT", "tempCompleted": "TEMP_COMPLETED"},
    CondicoAction: {"create":"CREATE", "rescind":"RESCIND", "attempt":"ATTEMPT", "pay":"PAY"},
    ConfirmationModalImageType: {"created":"CREATED", "rescinded":"RESCINDED", "completed":"COMPLETED", "attempted":"ATTEMPTED", "payment":"PAYMENT", "info":"INFO", "unsubscribed":"UNSUBSCRIBED"},
    ThemeColor: '#8ACF2E',
    ThemeColorRgba: 'rgba(138,207,46,0.3)',
    ThemeColorRgb: 'rgb(138,207,46)',
    LightGreyColor: '#D6D6D6',
    DarkGreyColor: '#a9a9a9',
    VeryDarkGreyColor: '#A0A0A0',
    NavBarColor: 'white',
    MAX_MATTER_LENGTH: 150,
    MIN_MATTER_LENGTH: 10,
    MAX_MESSAGE_LENGTH: 150,
    MIN_MESSAGE_LENGTH: 10,
    MAX_DAY_SEARCH_LENGTH: 14,
    getEnvironmentConfig: getEnvironmentConfig,
});

function getEnvironmentConfig(env) {
  //utilityFunctions.log(`env config is ${JSON.stringify(environmentConfig[env])}`)
  return environmentConfig[env];
}

const environmentConfig = {
    development: {
      environment:"dev",
      debug: true,
      stripePublishableKey: "pk_test_zvrvHJgrrhYYEOhX6LjKzUH2",
      apiGatewayUsers: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/users_api"
      },
      apiGatewaySettings: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/settings_api"
      },
      apiGatewayData: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/data_api"
      },
      apiGatewayTransactions: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/transactions_api"
      },
      apiGatewayPayments: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/payments_api"
      },
      apiGatewayAppLinks: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/appLinks_api"
      },
      apiGatewayContactUs: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/contactUs_api"
      },
      apiGatewayPublicContactUs: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/publicContactUs_api/publicContact"
      },
      apiGatewayPromos: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/promos_api"
      },
      apiGatewayAdmin: {
        REGION: "us-east-1",
        URL: "https://api.devmycondico.ca/admin_api"
      },
      cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_i4cIceEqu",
        APP_CLIENT_ID: "3kf3tq9r6mutdejapjrg60kof8",
        IDENTITY_POOL_ID: "us-east-1:1c691f07-1e8b-43ee-a5bc-b515189b305b"
      },
      marketingPage: {
        isEnabled: false,
        leadPagesRedirectionUrl: "https://mycondico.lpages.co/"
      }
    },
    production: {
      environment:"prod",
      debug: false,
      stripePublishableKey: "pk_live_uNL52NZyOpMDGf8iH7xQwhKN",
      apiGatewayUsers: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/users_api"
      },
      apiGatewaySettings: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/settings_api"
      },
      apiGatewayData: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/data_api"
      },
      apiGatewayTransactions: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/transactions_api"
      },
      apiGatewayPayments: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/payments_api"
      },
      apiGatewayAppLinks: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/appLinks_api"
      },
      apiGatewayContactUs: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/contactUs_api"
      },
      apiGatewayPublicContactUs: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/publicContactUs_api/publicContact"
      },
      apiGatewayPromos: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/promos_api"
      },
      apiGatewayAdmin: {
        REGION: "us-east-1",
        URL: "https://api.mycondico.ca/admin_api"
      },
      cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_s3ENFt5a0",
        APP_CLIENT_ID: "1khf37o70cuantimfai1uut465",
        IDENTITY_POOL_ID: "us-east-1:57771906-c637-4ba5-b164-8f983afc77b5"
      },
      marketingPage: {
        isEnabled: false,
        leadPagesRedirectionUrl: "https://welcome.mycondico.com"
      }
    }
}