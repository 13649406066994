import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button, FormGroup, FormControl, HelpBlock, Modal, Jumbotron } from "react-bootstrap";

import config from "../config";
import "./ContactUs.css";
import utilityFunctions from "../utilityFunctions";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleTransactionIdChange = this.handleTransactionIdChange.bind(this);
    this.state = {
        message: "",
        validForm: false,
        transactionId: undefined,
        userId: undefined,
        dateTime: undefined,
        smShow: false,
        previousWasReview: false
    }
  }

  async componentDidMount() {
    utilityFunctions.log("componentDidMount - ContactUs");
    if (!!this.props.contactUsTransactionDetails) {
        this.setState({previousWasReview: true, transactionId: this.props.contactUsTransactionDetails.transactionId, userId: this.props.contactUsTransactionDetails.userId, dateTime: this.props.contactUsTransactionDetails.dateTime}, () => this.props.setContactUsTransactionId())
    }
  }

  getTransactionIdValidationState() {
    if (this.state.transactionId !== undefined && this.state.transactionId !== null) {
        const length = this.state.transactionId.length;
        if (length > 0) return 'success';
        else return 'error';
    } else {
        return null;
    }
  }

  handleTransactionIdChange(e) {
    this.setState({ transactionId: e.target.value }, () => this.validateForm());
  }

  getMessageValidationState() {
    const length = this.state.message.length;
    if (length >= config.MIN_MESSAGE_LENGTH && length <= config.MAX_MESSAGE_LENGTH) return 'success';
    else if (length > 0) return 'error';
    return null;
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value }, () => this.validateForm());
  }

  validateForm() {
    if (this.getMessageValidationState() === "success" && 
        this.state.message.length !== 0 &&
        this.getTransactionIdValidationState() === "success" && 
        !!this.state.transactionId &&
        this.state.transactionId.length !== 0
    ) {
        this.setState({validForm: true});
        return;
    }
    this.setState({validForm: false});
    return;
  }

  async handleFormSubmit() {
    utilityFunctions.log("submitting form for message on contact us");
    let message = {
        description: this.state.message,
        transaction: this.state.transactionId
    }
    if (!!this.state.userId) {
        message.userId = this.state.userId;
        message.dateTime = this.state.dateTime;
    }
    try {
        let response = await this.sendMessage({
            messageData: message
          });
        utilityFunctions.log(`message response ${JSON.stringify(response)}`)
        if (response.contactUs.message === "success") {
            this.setState({ smShow: true })
        }
    } catch (e) {
        if (!!e.response && !!e.response.data) {
          if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
          }
          this.props.handleSetAppError();
          return
        }
        this.props.handleSetAppError();
      }
  }

  sendMessage(data) {
    return API.post("contactUs", "/contact", {
      body: data.messageData
    });
  }

  render() {
    let smClose = () => this.setState({ smShow: false }, () => this.props.history.push("/more"));
    return (
      this.props.completedStartup ?
      <div style={{fontFamily: 'Roboto, helvetica'}}>
        {
            this.state.previousWasReview ?
            <Breadcrumb>
                <Breadcrumb.Item onClick={(e) => this.props.history.goBack()} >Review</Breadcrumb.Item>
                <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb> :
            <Breadcrumb>
                <Breadcrumb.Item onClick={(e) => this.props.history.goBack()} >More</Breadcrumb.Item>
                <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb> 
        }
        <MySmallModal show={this.state.smShow} onHide={smClose} />
        <Jumbotron>
            <div className="contactUsJumbotron">
                <div className="infoDivider">
                    <div className="contactInfo">
                        <div>
                            <img style={{paddingBottom:"8px"}} src={ require("../assets/moreTab/Phone@3x.png") } height="27" width="22" alt="logo" /><a style={{paddingLeft:"23px", color:"var(--green_theme)"}} href="tel:6476164338" data-rel="external">Tel: 647-616-4338</a>
                        </div>
                        <div className="address">
                            <img src={ require("../assets/moreTab/Envelope@3x.png") } height="25" width="25" alt="logo" />
                            <div style={{paddingLeft:"20px"}}>
                                <span className="contactUsCustomText">
                                    14 Fairleigh Cresent<br />
                                    Toronto Ontario<br />
                                    M6C 3R8
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="transactionDivider">
                    <div className="contactUsCustomText">Or message us about a related transaction</div>
                    <form>
                        transaction:
                        <FormGroup
                            controlId="transactionId"
                            validationState={this.getTransactionIdValidationState()}
                        >
                            <FormControl
                                type="text"
                                value={this.state.transactionId}
                                placeholder="ID#"
                                onChange={this.handleTransactionIdChange}
                            />
                            <FormControl.Feedback />
                                { (this.getTransactionIdValidationState() === 'error') ? <HelpBlock>{`id# cannot be empty`}</HelpBlock> : <HelpBlock></HelpBlock> }
                        </FormGroup>
                    </form>
                </div>
                <div style={{marginTop:"20px"}} className="messageDivider">
                    <div className="messageRow">
                        <div className="contactUsCustomText">How can we help?</div>
                        <div>
                            <button
                                disabled={!this.state.validForm}
                                onClick={() => this.handleFormSubmit()}
                                style={{backgroundColor:"transparent", border:"transparent", marginRight:"-10px", color:`${this.state.validForm ? "green" : "black"}`}}
                            >
                                Click to send
                            </button>
                            <button 
                                disabled={!this.state.validForm}
                                onClick={() => this.handleFormSubmit()}
                                style={{backgroundColor:"transparent", border:"transparent"}}
                            >
                                <img src={ this.state.validForm ? require("../assets/moreTab/arrowGreenSend@3x.png") : require("../assets/moreTab/arrowBlackSend@3x.png") } height="17" width="17" alt="logo" />
                            </button>
                        </div>
                    </div>
                    <form>
                        <FormGroup
                            controlId="message"
                            validationState={this.getMessageValidationState()}
                        >
                            <FormControl
                                componentClass="textarea"
                                value={this.state.message}
                                placeholder="Enter message..."
                                onChange={this.handleMessageChange}
                            />
                            <FormControl.Feedback />
                                { (this.getMessageValidationState() === null) ? <HelpBlock></HelpBlock> : <HelpBlock>{`Char min 10, max 100. char remaining: ${config.MAX_MESSAGE_LENGTH - this.state.message.length}`}</HelpBlock> }
                        </FormGroup>
                    </form>
                </div>
            </div>
        </Jumbotron>
      </div> : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}

class MySmallModal extends React.Component {
    render() {
      return (
        <Modal
            {...this.props}
            bsSize="small"
            aria-labelledby="contained-modal-title-sm"
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-sm">Message Successfully Sent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5>We will be in touch shortly</h5>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
      );
    }
  }