import { API, Auth } from "aws-amplify";
import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Nav, Navbar, NavItem } from "react-bootstrap";

import "./App.css";
import config from "./config.js";
import { myCondicoFilterTypes } from "./components/FiltersView.js";
import Routes from "./Routes";
import utilityFunctions from "./utilityFunctions";

var Loader = require('react-loader');

const initialState = {
  cognitoIdentityId: undefined,
  isAuthenticated: false,
  isAuthenticating: true,
  completedStartup: false,
  appError: false,
  hideLoader: true,
  expanded: false,
  requireTermsAcceptance: false,
  requirePersonalInfo: false,
  firstTimeTerms: false,
  // Unauthenticated AppState Data:
  creationDataUnauthenticated: undefined,
  // AppState:
  userInfo: undefined,
  appSettings: undefined,
  creationData: undefined,
  rescindCondico: undefined,
  attemptCondico: undefined,
  attemptAmount: undefined,
  reviewCondico: undefined,
  payCondico: undefined,
  onloadSetSegCntrlToActive: undefined,
  partnerLinkData: undefined,
  myCondicosScrollYForActive: undefined,
  myCondicosScrollYForInActive: undefined,
  lastShowedActive: true,
  myInitialLoadAndRefreshCondicos: undefined,
  allActiveTransactions: undefined,
  myInactiveScrollCondicos: undefined,
  allInactiveTransactions: undefined,
  appliedFilters: {
    showInvited: true,
    showInitiated: true,
    sortBy: myCondicoFilterTypes.sortByDate,
    range: myCondicoFilterTypes.rangeAll,
    dateRange: {
      starting: "",
      ending: ""
    }
  },
  endRefreshDateTime: undefined,
  contactUsTransactionDetails: undefined,
  last4: undefined
}

class App extends Component {
  
  constructor(props){
    super(props);
    this.dismissError = this.dismissError.bind(this);
    this.setAppError = this.setAppError.bind(this);
    this.handleCondicoSeging = this.handleCondicoSeging.bind(this);
    this.removeCondicoFromAppStateDueToCompletedAction = this.removeCondicoFromAppStateDueToCompletedAction.bind(this);
    this.handleTermsAcceptance = this.handleTermsAcceptance.bind(this);
    this.handlePersonalInfoProvided = this.handlePersonalInfoProvided.bind(this);
    this.setContactUsTransactionId = this.setContactUsTransactionId.bind(this);
    this.setAppliedFilters = this.setAppliedFilters.bind(this);
    // myCondico VC
    this.setOnloadSetSegCntrlToActive = this.setOnloadSetSegCntrlToActive.bind(this);
    this.setMyInitialLoadAndRefreshCondicos = this.setMyInitialLoadAndRefreshCondicos.bind(this);
    this.setMyInactiveScrollCondicos = this.setMyInactiveScrollCondicos.bind(this);
    this.setMyCondicosScrollYForActive = this.setMyCondicosScrollYForActive.bind(this);
    this.setMyCondicosScrollYForInActive = this.setMyCondicosScrollYForInActive.bind(this);
    this.setLastShowedActive = this.setLastShowedActive.bind(this);
    this.setAllActiveTransactions = this.setAllActiveTransactions.bind(this);
    this.setAllInactiveTransactions = this.setAllInactiveTransactions.bind(this);
    this.setEndRefreshDateTime = this.setEndRefreshDateTime.bind(this);
    this.setLast4 = this.setLast4.bind(this);
    this.clearPartnerLink = this.clearPartnerLink.bind(this);
    this.unSelectNavItem = this.unSelectNavItem.bind(this);
    this.clearCreationDataUnauthenticated = this.clearCreationDataUnauthenticated.bind(this);
    this.state = initialState;
  }

  componentWillMount() {
    utilityFunctions.log("componentWillMount App.js");
    utilityFunctions.log(`this.props.location.search = ${this.props.location.search}`);
    let path = this.props.location.pathname.replace('/', '');
    utilityFunctions.log(`path is ${path}`);
    if (path === 'signup' || path === 'login' || path === 'more/about') {
      this.setState({navColor: config.VeryDarkGreyColor});
    } else {
      this.setState({navColor: 'transparent'});
    }
    this.setPartnerLink();
  }

  async componentDidMount() {
    utilityFunctions.log("APPLICATION APP.js did mount");
    this.addListener()
    try {
      if (await Auth.currentSession()) {
        utilityFunctions.log("APPLICATION APP.js current user session availaable");
        this.userHasAuthenticated(true);
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      utilityFunctions.log("APPLICATION APP.js current user session NOT available");
      if (e !== 'No current user') {
        alert(e);
      }
      this.userHasAuthenticated(false);
    }
  }

  componentWillUnmount() {
    this.removeListener()
  }

  addListener() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  removeListener() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  // used for nav bar color
  listenScrollEvent = (event) => {
    let path = this.props.location.pathname.replace('/', '');
    if (path === '' || path === 'top' || path === 'one' || path === 'two' || path === 'three' || path === 'signup' || path === 'login' || path === 'more/about' || path === 'oneA') {
      var windowY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      utilityFunctions.log(`windowY ${windowY}`);
      
      var isScrolling = setTimeout(function() {
        utilityFunctions.log( 'Scrolling has stopped.' );
        if (windowY > 20) {
          this.setState({navColor: config.VeryDarkGreyColor});
          utilityFunctions.log('set nav backgroundColor to lightGrey');
        } else {
          let path = this.props.location.pathname.replace('/', '');
          utilityFunctions.log(`path is ${path}`);
          if (path === 'signup' || path === 'login' || path === 'more/about') {
            utilityFunctions.log('set nav backgroundColor to lightGrey');
            this.setState({navColor: config.VeryDarkGreyColor});
          } else {
            utilityFunctions.log('set nav backgroundColor to trnsparent');
            this.setState({navColor: 'transparent'});
          }
        }
      }.bind(this), 50);
    } else {
      this.setState({navColor: config.VeryDarkGreyColor});
    }
    
  }

  handleLogout = async event => {
    utilityFunctions.log("APP.js handleLogout called");
    this.hideLoader(true);
    utilityFunctions.removeCreationDataFromSessionStorage(this.state.cognitoIdentityId);
    await Auth.signOut();

    // new
    //const currentUser = Auth.userPool.getCurrentUser()
    //await currentUser.signOut()
    this.hardReset();
  }

  hardReset = () => {
      // initial state except isAuthenticated is false
      this.setState({
        cognitoIdentityId: undefined,
        isAuthenticated: false,
        isAuthenticating: false,
        completedStartup: false,
        appError: false,
        hideLoader: true,
        expanded: false,
        requireTermsAcceptance: false,
        requirePersonalInfo: false,
        firstTimeTerms: false,
        // AppState:
        userInfo: undefined,
        appSettings: undefined,
        creationData: undefined,
        rescindCondico: undefined,
        attemptCondico: undefined,
        attemptAmount: undefined,
        reviewCondico: undefined,
        payCondico: undefined,
        onloadSetSegCntrlToActive: undefined,
        // ?? partnerLinkData: undefined,
        myCondicosScrollYForActive: undefined,
        myCondicosScrollYForInActive: undefined,
        lastShowedActive: true,
        myInitialLoadAndRefreshCondicos: undefined,
        allActiveTransactions: undefined,
        myInactiveScrollCondicos: undefined,
        allInactiveTransactions: undefined,
        appliedFilters: {
          showInvited: true,
          showInitiated: true,
          sortBy: myCondicoFilterTypes.sortByDate,
          range: myCondicoFilterTypes.rangeAll,
          dateRange: {
            starting: "",
            ending: ""
          }
        },
        endRefreshDateTime: undefined,
        contactUsTransactionDetails: undefined,
        last4: undefined
      }, () => this.checkStartupStatus());
  }

  setPartnerLink() {
    utilityFunctions.log(`setPartnerLink called with pathname ${this.props.location.pathname}`);
    if (this.props.location.pathname === "/app/index.html") {
      utilityFunctions.log(`have partner link dateTime ${this.props.location.search.split("?")[1].split("&")[1].split("=")[1]} hashCode ${this.props.location.search.split("?")[1].split("&")[0].split("=")[1]}`);
      this.setState({
        partnerLinkData: {
          dateTime: this.props.location.search.split("?")[1].split("&")[1].split("=")[1],
          hashCode: this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
        }
      });
    } else if (this.props.location.pathname === "/login") {
      utilityFunctions.log('path is /login at app.js ');
      var searchTerm = this.props.location.search.split("?");
      if (searchTerm.length > 0) {
        utilityFunctions.log(`searchTerm ${searchTerm}`);
        if (searchTerm[1] === 'redirect=/app/index.html') {
          utilityFunctions.log('searchTerm[1] === redirect=/app/index.html');
          utilityFunctions.log(`searchTerm[2].split("&")[1].split("=")[1] = ${searchTerm[2].split("&")[1].split("=")[1]}`);
          utilityFunctions.log(`searchTerm[2].split("&")[0].split("=")[1] = ${searchTerm[2].split("&")[0].split("=")[1]}`);
          this.setState({
            partnerLinkData: {
              dateTime: searchTerm[2].split("&")[1].split("=")[1],
              hashCode: searchTerm[2].split("&")[0].split("=")[1]
            }
          });
        }
      }
    }
  }

  userHasAuthenticated = (authenticated) => {
    if (authenticated) {
      this.getCognitoIdentityId();
      this.setPartnerLink();
      this.setState({navColor: config.VeryDarkGreyColor});
    } else {
      // initial state except isAuthenticated is false and partnerLinkData not removed
      if (config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled === true) {
        console.log(`CHECKING PATH FOR MAIN PAGE REDIRECT IN APP.js ${this.props.location.pathname}!`);
        if (this.props.location.pathname === '/' || this.props.location.pathname === '/top' || this.props.location.pathname === '/oneA' || this.props.location.pathname === '/two' || this.props.location.pathname === '/three' || this.props.location.pathname === '/about') {
          window.location = config.getEnvironmentConfig(config.Environment).marketingPage.leadPagesRedirectionUrl;
          console.log('CHECKED PATH!!!');
        } else {
          this.setState({ isAuthenticating: false }, () => this.checkStartupStatus());
        }
      } else {
        this.setState({ isAuthenticating: false }, () => this.checkStartupStatus());
      }
    }
  }

  async getCognitoIdentityId() {
    try {
      let user = await Auth.currentUserInfo();
      utilityFunctions.log(`user id ${user.id}`);
      utilityFunctions.log(`user ${JSON.stringify(user)}`);
      if (user !== null) {
        if (!!user.attributes['custom:famName_cus']) {
          utilityFunctions.log('have personal Info');
          this.setState({ isAuthenticated: true, isAuthenticating: false, cognitoIdentityId: user.id }, () => this.runAuthenticatedStartup());
          return;
        }
        this.setState({ isAuthenticated: true, isAuthenticating: false, cognitoIdentityId: user.id, requirePersonalInfo: true }, () => this.runAuthenticatedStartup());
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      this.hardReset();
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }
  
  hideLoader = hide => {
    this.setState({ hideLoader: hide });
  }

  // pulls down userInfo, Settings, CreateStripe customer if needed, Terms, About text, FAQ texts, SummaryView texts & does App refresh 
  runAuthenticatedStartup() {
    this.getUserInfo();
    this.getAppSettings();
  }

  getUserInfo = async () => {
    try {
      utilityFunctions.log("getting profile...");
      let user = await API.get("users", "/profile/1");
      if (!!user.userInfo) {
        utilityFunctions.log(`user is ${JSON.stringify(user)}`);
        if (!!user.userInfo.customerId) {
          this.setState({userInfo: user.userInfo}, () => this.checkStartupStatus());
        } else {
          this.setState({userInfo: user.userInfo}, () => this.createStripeCustomer());
        }
      } else {
        utilityFunctions.log("user info came back empty");
        this.checkStartupStatus();
      }
    } catch (e) {
        utilityFunctions.log("user error authenticated is: ", this.props.isAuthenticated);
        alert(e.message);
    }
  }

  getAppSettings = async () => {
    try {
      utilityFunctions.log("getting appSettings...");
      let settings = await API.get("settings", "/settings?version=1");
      if (!!settings.appSettings) {
        this.setState({appSettings: settings.appSettings}, () => this.checkStartupStatus());
      } else {
        utilityFunctions.log("app settings came back empty");
        this.checkStartupStatus();
      }
    } catch (e) {
        utilityFunctions.log("user error authenticated is: ", this.props.isAuthenticated);
        alert(e.message);
    }
  }

  createStripeCustomer = async () => {
    try {
      utilityFunctions.log("creating stripe customer...");
      let user = await API.post("payments", "/customer");
      if (!!user.userInfo) {
        if (!!user.userInfo.customerId) {
          utilityFunctions.log(`user is ${JSON.stringify(user)}`);
          this.setState({userInfo: user.userInfo}, () => this.checkStartupStatus());
        } else {
          utilityFunctions.log("stripe customer came back empty");
          this.checkStartupStatus();
        }
      } else {
        utilityFunctions.log("user info came back empty");
        this.checkStartupStatus();
      }
    } catch (e) {
        utilityFunctions.log("user error authenticated is: ", this.props.isAuthenticated);
        alert(e.message);
    }
  }

  checkStartupStatus() {
    utilityFunctions.log("checkStartupStatus started");
    utilityFunctions.log(`checkStartupStatus url search term is ${this.props.location.search}`);
    if (this.state.isAuthenticated && !!this.state.userInfo && !!this.state.userInfo.customerId && !!this.state.appSettings) {
      if (!!this.state.userInfo.termsAndConditions) {
        var userTermsArray = this.state.userInfo.termsAndConditions;
        var dateUserAcceptedTerms = userTermsArray[userTermsArray.length - 1]['termsAcceptedOn'];
        var dateTermsChanged = this.state.appSettings.eula["lastModified"];
        // only condition where we set as true
        if (new Date(dateUserAcceptedTerms) <= new Date(dateTermsChanged)) {
          utilityFunctions.log("checkStartupStatus: authenticated and user must accept terms");
          // can we remove the prop push and still go to home after terms has been accepted?
          this.setState({requireTermsAcceptance: true}, () => this.props.history.push("/terms/auth"));
          return;
        }
        utilityFunctions.log("authenticated, terms are good");
        if (this.state.requirePersonalInfo) {
          utilityFunctions.log('seging to collect personal info');
          this.props.history.push("/collectPersonalInfo");
          return;
        }
        utilityFunctions.log("authenticated, terms are good & have personal info");
        if (!!this.state.partnerLinkData) {
          this.setState({completedStartup: true}, () => this.props.history.push("/app/index.html"));
          return;
        }
        // user pre filled form. So 1. copy contents to creationData, clear creationDataUnAuth at CreateCondico and push to there. 
        utilityFunctions.log("authenticated, terms are good & have personal info and user had pre filled form so redirected to create a condico");
        if (!!this.state.creationDataUnauthenticated) {
          this.setState({creationData: this.state.creationDataUnauthenticated, completedStartup: true}, () => this.props.history.push("/mycondicos/create"));
          return;
        }
        this.setState({completedStartup: true});
        return;
      } 
      this.setState({requireTermsAcceptance: true, firstTimeTerms: true}, () => this.props.history.push("/tutorial"));
      utilityFunctions.log("checkStartupStatus: authenticated but terms needs to be accepted");
      return;
    }
    // We should add a check here that if all our http requests have come back + terms is accepted and we still dont have completed status then display AppError
    this.setState({completedStartup: false});
    utilityFunctions.log("checkStartupStatus: unauthenticated OR criteria FALSE in startup");
    return;
  }
  
  toggle = event => {
    utilityFunctions.log("Navbar Toggled");
    this.setState({ expanded: !this.state.expanded, selectedNavItem: true}, () => this.setNavColor());
  }

  selectedBrandMenuItem = event => {
    utilityFunctions.log("Navbar Toggled by BrandMenu");
    this.setState({ expanded: false, selectedNavItem: true}, () => this.setNavColor());
  }

  setNavColor() {
    utilityFunctions.log(`expanded is ${this.state.expanded}`);
    if (this.state.isAuthenticated) {
      this.setState({navColor: config.VeryDarkGreyColor});
    } else if (this.state.expanded) {
      this.setState({navColor: config.VeryDarkGreyColor});
    } else {
      let path = this.props.location.pathname.replace('/', '');
      if (path === 'oneA' || path === 'oneB' || path === 'oneC' || path === 'two' || path === 'three' || path === 'more/about' || path === 'login' || path === 'signup') {
        this.setState({navColor: config.VeryDarkGreyColor});
      } else {
        this.setState({navColor: 'transparent'});
      }
    }
  }

  setCreationDataUnauthenticated = unAuthCondicoFormData => {
    utilityFunctions.log(`setCreationDataUnauthenticated is called`);
    this.setState({ creationDataUnauthenticated: unAuthCondicoFormData.creationData, unAuthDisplayAmt: unAuthCondicoFormData.unAuthDisplayAmt }, () => this.props.history.push("/signup") );
  }

  setCondicoData = condicoData => {
    if (!!this.state.userInfo && !!this.state.cognitoIdentityId) {
      this.setState({ creationData: condicoData.creationData }, () => utilityFunctions.storeCondicoDataToSessionStorage(this.state.cognitoIdentityId, condicoData));
      return
    }
    this.setState({ creationData: condicoData.creationData });
  }

  dismissError() {
    this.setState({ appError: false });
  }

  setAppError() {
    this.setState({ appError: true });
  }

  handleCondicoSeging(condico) {
    if (condico.state === config.CondicoState.completed || condico.state === config.CondicoState.rescinded || condico.state === config.CondicoState.expired) {
      utilityFunctions.log("seg performed to review");
      this.setState({reviewCondico: condico}, () => this.props.history.push("/mycondicos/review"));
    } else if (condico.state === config.CondicoState.pendingPayment) {
      if ((condico.userId === this.state.cognitoIdentityId && condico.actionOptions["initiator"].includes("PAY")) || (condico.userId !== this.state.cognitoIdentityId && condico.actionOptions["partner"].includes("PAY")) ) {
        utilityFunctions.log("seg performed to payCondico");
        this.setState({payCondico: condico}, () => this.props.history.push("/mycondicos/pay"));
      } else {
        this.setAppError();
      }
    } else if (condico.state === config.CondicoState.new || condico.state === config.CondicoState.attempted) {
      if (condico.userId === this.state.cognitoIdentityId && condico.actionOptions["initiator"].includes("RESCIND")) {
        utilityFunctions.log("seg performed to rescindCondico");
        this.setState({rescindCondico: condico}, () => this.props.history.push("/mycondicos/rescind"));
      } else if (condico.userId !== this.state.cognitoIdentityId && condico.actionOptions["partner"].includes("ATTEMPT")) {
        utilityFunctions.log("seg performed to attemptCondico");
        this.setState({attemptCondico: condico}, () => this.props.history.push("/mycondicos/attempt"));
      } else {
        this.setAppError();
      }
    } else {
      this.setAppError();
    }
  }

  removeCondicoFromAppStateDueToCompletedAction(action) {
    if (action === config.CondicoAction.create) {
      utilityFunctions.removeCreationDataFromSessionStorage(this.state.cognitoIdentityId);
    }
    if (action === config.CondicoAction.rescind) {
      this.setState({rescindCondico: undefined});
    }
    if (action === config.CondicoAction.attempt) {
      this.setState({attemptCondico: undefined, attemptAmount: undefined});
    }
    if (action === config.CondicoAction.pay) {
      this.setState({payCondico: undefined});
    }
    // no action associated with review but we can clear this each time. In fact we could clear all each time if we wanted as user can only be viewing/actioning 1 condico at a time
    this.setState({reviewCondico: undefined});
  }

  setAttemptAmount = attemptAmount => {
    this.setState({ attemptAmount });
  }

  handleTermsAcceptance(userInfo, accepted, callback) {
    this.setState({userInfo, requireTermsAcceptance: !accepted}, () => {
      this.checkStartupStatus();
      callback(true);
    });
  }

  handlePersonalInfoProvided(userInfo, provided, callback) {
    utilityFunctions.log("handlePersonalInfoProvided");
    if (!!userInfo) {
      this.setState({userInfo, requirePersonalInfo: !provided}, () => {
        this.checkStartupStatus();
        callback(true);
      });
    } else {
      this.setState({requirePersonalInfo: !provided}, () => {
        this.checkStartupStatus();
        callback(true);
      });
    }
  }

  setOnloadSetSegCntrlToActive(value) {
    utilityFunctions.log(`setOnloadSetSegCntrlToActive value ${value}`)
    if (value !== undefined) {
      this.setState({onloadSetSegCntrlToActive: value}, () => this.props.history.push("/myCondicos"));
      return;
    }
    this.setState({onloadSetSegCntrlToActive: undefined});
  }

  setMyInitialLoadAndRefreshCondicos(myInitialLoadAndRefreshCondicos) {
    this.setState({myInitialLoadAndRefreshCondicos});
  }

  setMyInactiveScrollCondicos(myInactiveScrollCondicos) {
    this.setState({myInactiveScrollCondicos});
  }

  setAllActiveTransactions(allActiveTransactions) {
    this.setState({allActiveTransactions});
  }

  setAllInactiveTransactions(allInactiveTransactions) {
    this.setState({allInactiveTransactions});
  }

  setMyCondicosScrollYForActive(yValue) {
    this.setState({myCondicosScrollYForActive: yValue})
  }

  setMyCondicosScrollYForInActive(yValue) {
    this.setState({myCondicosScrollYForInActive: yValue})
  }

  setLastShowedActive(lastShowedActive) {
    this.setState({lastShowedActive})
  }

  setEndRefreshDateTime(newRefreshDateTime) {
    this.setState({endRefreshDateTime: newRefreshDateTime});
  }

  setContactUsTransactionId(transactionDetails) {
    if (!!transactionDetails) {
      this.setState({contactUsTransactionDetails: transactionDetails}, () => this.props.history.push("/more/contact"));
    } else {
      this.setState({contactUsTransactionDetails: undefined});
    }
  }

  setAppliedFilters(appliedFilters) {
    this.setState({appliedFilters});
  }

  setLast4(last4) {
    this.setState({last4});
  }

  clearPartnerLink() {
    this.setState({partnerLinkData: undefined});
  }

  unSelectNavItem() {
    this.setState({selectedNavItem: undefined});
  }

  clearCreationDataUnauthenticated() {
    utilityFunctions.log('clearing clearCreationDataUnauthenticated');
    this.setState({creationDataUnauthenticated: undefined});
  }

  render() {
    const childProps = {
      // next 2 items are used by Condico component to know when to scroll to page section / top, one, twwo etc..
      selectedNavItem: this.state.selectedNavItem,
      unSelectNavItem: this.unSelectNavItem,
      cognitoIdentityId: this.state.cognitoIdentityId,
      isAuthenticated: this.state.isAuthenticated,
      completedStartup: this.state.completedStartup,
      userHasAuthenticated: this.userHasAuthenticated,
      handleSetAppError: this.setAppError,
      hideLoader: this.hideLoader,
      handleLogout: this.handleLogout,
      handleTermsAcceptance: this.handleTermsAcceptance,
      handlePersonalInfoProvided: this.handlePersonalInfoProvided,
      userInfo: this.state.userInfo,
      appSettings: this.state.appSettings,
      handleCondicoSeging: this.handleCondicoSeging,
      removeCondicoFromAppStateDueToCompletedAction: this.removeCondicoFromAppStateDueToCompletedAction,
      setCreationDataUnauthenticated: this.setCreationDataUnauthenticated,
      creationDataUnauthenticated: this.state.creationDataUnauthenticated,
      unAuthDisplayAmt: this.state.unAuthDisplayAmt,
      clearCreationDataUnauthenticated: this.clearCreationDataUnauthenticated,
      setCondicoData: this.setCondicoData,
      creationData: this.state.creationData,
      rescindCondico: this.state.rescindCondico,
      attemptCondico: this.state.attemptCondico,
      attemptAmount: this.state.attemptAmount,
      setAttemptAmount: this.setAttemptAmount,
      reviewCondico: this.state.reviewCondico,
      payCondico: this.state.payCondico,
      // homeVC
      onloadSetSegCntrlToActive: this.state.onloadSetSegCntrlToActive,
      setOnloadSetSegCntrlToActive: this.setOnloadSetSegCntrlToActive,
      //
      requirePersonalInfo: this.state.requirePersonalInfo,
      //
      partnerLinkData: this.state.partnerLinkData,
      // myCondico VC
      myInitialLoadAndRefreshCondicos: this.state.myInitialLoadAndRefreshCondicos,
      myInactiveScrollCondicos: this.state.myInactiveScrollCondicos,
      setMyInitialLoadAndRefreshCondicos: this.setMyInitialLoadAndRefreshCondicos,
      setMyInactiveScrollCondicos: this.setMyInactiveScrollCondicos,
      allActiveTransactions: this.state.allActiveTransactions,
      allInactiveTransactions: this.state.allInactiveTransactions,
      setAllActiveTransactions: this.setAllActiveTransactions,
      setAllInactiveTransactions: this.setAllInactiveTransactions,
      myCondicosScrollYForActive: this.state.myCondicosScrollYForActive,
      setMyCondicosScrollYForActive: this.setMyCondicosScrollYForActive,
      myCondicosScrollYForInActive: this.state.myCondicosScrollYForInActive,
      setMyCondicosScrollYForInActive: this.setMyCondicosScrollYForInActive,
      lastShowedActive: this.state.lastShowedActive,
      setLastShowedActive: this.setLastShowedActive,
      setAppliedFilters: this.setAppliedFilters,
      appliedFilters: this.state.appliedFilters,
      endRefreshDateTime: this.state.endRefreshDateTime,
      setEndRefreshDateTime: this.setEndRefreshDateTime,
      // contactUs/ReviewVC
      setContactUsTransactionId: this.setContactUsTransactionId,
      contactUsTransactionDetails: this.state.contactUsTransactionDetails,
      setLast4: this.setLast4,
      last4: this.state.last4,
      clearPartnerLink: this.clearPartnerLink
    };

    var mobile = false;
    if (document.documentElement.clientWidth <= 800) {
      mobile = true;
    }
    return (
      !this.state.isAuthenticating && 
      <div 
        className="App" 
        style={{
          marginTop: this.props.location.pathname === '/' || this.props.location.pathname === '/oneA' || this.props.location.pathname === '/oneB' || this.props.location.pathname === '/oneC' || this.props.location.pathname === '/two' || this.props.location.pathname === '/three' || 
          this.props.location.pathname === '/top' ? '0px' : '60px',
          padding: this.props.location.pathname === '/' || 
          this.props.location.pathname === '/top' || this.props.location.pathname === '/oneA' || this.props.location.pathname === '/oneB' || this.props.location.pathname === '/oneC' || this.props.location.pathname === '/two' || this.props.location.pathname === '/three' || 
          this.props.location.pathname === '/login' || 
          this.props.location.pathname === '/signup' ? '0px' : '10px'}}
      >
        <Navbar
          fixedTop
          fluid
          onToggle={this.toggle}
          expanded={this.state.expanded}
          style={{backgroundColor: this.state.expanded ? config.NavBarColor : config.NavBarColor, borderColor: 'transparent'}}
        >
          <Navbar.Header>
            <Navbar.Brand>
              { this.state.completedStartup
                ? <NavLink onClick={this.selectedBrandMenuItem} to="/home">
                  <img src={ require("./assets/condicoSite/condico_logo2.png") } height="33" width="120" alt="logo" />
                </NavLink>
                : this.state.isAuthenticated && this.state.requireTermsAcceptance && this.state.firstTimeTerms ? <NavLink to="/tutorial">
                  <font face="Roboto, helvetica" >Getting Started</font>
                </NavLink> 
                : this.state.isAuthenticated && this.state.requireTermsAcceptance ? <NavLink to="/terms">
                  <font face="Roboto, helvetica" >Terms and Conditions</font>
                </NavLink> 
                : 
                <div>
                  <NavLink activeStyle={{ color: "#669900", borderColor: 'transparent' }} onClick={this.selectedBrandMenuItem} to="/top">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <img src={ require("./assets/condicoSite/condico_logo2.png") } height={mobile ? '50' : '60'} alt="logo" />
                    </div>
                  </NavLink>
                </div>
              }
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse style={{paddingTop: this.state.isAuthenticated ? '7px' : '19px'}}>
            <Nav pullRight>
              { this.state.completedStartup
                ? <Fragment>
                  {
                      !!this.state.userInfo && this.state.userInfo.type !== "admin" &&
                    <LinkContainer to="/mycondicos">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <div
                          className="navMenuIcon"
                        >
                          <img style={{tintColor:'blue'}} src={ this.props.location.pathname === '/mycondicos' ? require("./assets/tabBar/transactionsIconGreen.png") : require("./assets/tabBar/transactionsIcon.png") } height="20" width="20" alt="logo" />
                          <p style={{color: this.props.location.pathname === '/mycondicos' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >All Transactions</font></p>
                        </div>
                      </NavItem>
                    </LinkContainer>
                  }
                  {
                      !!this.state.userInfo && this.state.userInfo.type === "admin" &&
                      <LinkContainer to="/promos">
                        <NavItem
                          onSelect={this.toggle}
                        >
                          <div
                            className="navMenuIcon"
                          >
                            <p style={{color: this.props.location.pathname.split('/')[1] === 'promos' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Promos</font></p>
                          </div>
                        </NavItem>
                      </LinkContainer>
                    }
                    {
                      !!this.state.userInfo && this.state.userInfo.type === "admin" &&
                      <LinkContainer to="/status">
                        <NavItem
                          onSelect={this.toggle}
                        >
                          <div
                            className="navMenuIcon"
                          >
                            <p style={{color: this.props.location.pathname.split('/')[1] === 'status' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Status</font></p>
                          </div>
                        </NavItem>
                      </LinkContainer>
                    }
                    {
                      !!this.state.userInfo && this.state.userInfo.type === "admin" &&
                      <LinkContainer to="/users">
                        <NavItem
                          onSelect={this.toggle}
                        >
                          <div
                            className="navMenuIcon"
                          >
                            <p style={{color: this.props.location.pathname.split('/')[1] === 'users' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Users</font></p>
                          </div>
                        </NavItem>
                      </LinkContainer>
                    }
                    <LinkContainer to="/profile">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <div
                          className="navMenuIcon"
                        >
                          <img style={{tintColor:'blue'}} src={ this.props.location.pathname === '/profile' ? require("./assets/tabBar/profileIconGreen.png") : require("./assets/tabBar/profileIcon.png") } height="20" width="20" alt="logo" />
                          <p style={{color: this.props.location.pathname === '/profile' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >Profile</font></p>
                        </div>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/more">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <div
                          className="navMenuIcon"
                        >
                          <p style={{color: this.props.location.pathname === '/more' ? '#669900' : 'black', backgroundColor: 'transparent' }}><font face="Roboto, helvetica" >More</font></p>
                        </div>
                      </NavItem>
                    </LinkContainer>
                  </Fragment> 
                : this.state.isAuthenticated && this.state.requireTermsAcceptance
                ? <Fragment>
                </Fragment> : config.getEnvironmentConfig(config.Environment).marketingPage.isEnabled ? 
                <Fragment>
                  <LinkContainer to="/login">
                    <NavItem
                      onSelect={this.toggle}
                    >
                      <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >Login</font></button>
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/signup" style={{marginTop: '-13px'}}>
                    <NavItem
                      onSelect={this.toggle}
                    >
                      <button onClick={() => this.setState({navColor: config.VeryDarkGreyColor})} style={{backgroundColor: 'transparent', borderColor: config.ThemeColor, borderWidth: '1px', padding: '12px', borderRadius: '6px', marginRight: '15px' }}><font face="Roboto, helvetica" >Try It!</font></button>
                    </NavItem>
                  </LinkContainer>
                </Fragment>
                : <Fragment>
                    <LinkContainer to="/oneA">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >How It Works</font></button>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/two">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >FAQ</font></button>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/three">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >Contact Us</font></button>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/more/about">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >About</font></button>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button style={{borderColor: 'transparent', borderWidth: '0px', backgroundColor: 'transparent' }} onClick={() => this.setState({navColor: config.VeryDarkGreyColor})}><font style={{fontWeight:"500"}} face="Roboto, helvetica" >Login</font></button>
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to="/signup" style={{marginTop: '-13px'}}>
                      <NavItem
                        onSelect={this.toggle}
                      >
                        <button onClick={() => this.setState({navColor: config.VeryDarkGreyColor})} style={{backgroundColor: 'transparent', borderColor: config.ThemeColor, borderWidth: '1px', padding: '12px', borderRadius: '6px', marginRight: '15px' }}><font face="Roboto, helvetica" >Try It!</font></button>
                      </NavItem>
                    </LinkContainer>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        { this.state.appError && <Alert style={{marginTop:"15px"}} bsStyle="danger" onDismiss={this.dismissError}>
          <h4>Something went wrong</h4>
          <p>
            Oops... Please refresh browser and try again shortly
          </p>
        </Alert>}
        <div style={{marginTop: this.props.location.pathname === '/' || this.props.location.pathname === '/top' || this.props.location.pathname === '/oneA' || this.props.location.pathname === '/oneB' || this.props.location.pathname === '/oneC' || this.props.location.pathname === '/two' 
        || this.props.location.pathname === '/three' || (this.props.location.pathname === '/more/about' && !this.state.isAuthenticated) || this.props.location.pathname === '/login' || this.props.location.pathname === '/signup' ? '0px' : '30px'}}>
          <Routes childProps={childProps} />
        </div>
        <Loader loaded={this.state.hideLoader} />
      </div>
    );
  }
}

export default withRouter(App);
