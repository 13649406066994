import { API } from "aws-amplify";
import React, { Component } from "react";
import { Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Breadcrumb, ListGroup, ListGroupItem } from "react-bootstrap";

import "./PaymentMethod.css";
import config from "../config.js";
import utilityFunctions from "../utilityFunctions";

export default class PaymentMethod extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sources: undefined
    }
  }

  componentWillMount() {
    this.retrieveCards();
  }

  async retrieveCards() {
    this.props.hideLoader(false);
    try {
      let retrieveCardsResponse = await API.get("payments", "/card")
      utilityFunctions.log(`retrieveCardsResponse ${JSON.stringify(retrieveCardsResponse)}`);
      if(!!retrieveCardsResponse.sources) {
        this.setState({
          sources: retrieveCardsResponse.sources
        });
      } else {
        this.props.handleSetAppError();
      }
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }
  
  //e.currentTarget.getAttribute("href").active = false;
  async makeDefault(cardId) {
    utilityFunctions.log(`setting card ${cardId} to become default`);
    this.props.hideLoader(false);
    try {
      let updateCustomerResponse = await this.setDefaultCard({
        cardData: {
          action: "update",
          card: cardId
        }
      });
      utilityFunctions.log(`updateCustomerResponse ${JSON.stringify(updateCustomerResponse)}`);
      if (!!updateCustomerResponse.sources) {
        this.setState({
          sources: updateCustomerResponse.sources
        });
      } else {
        this.props.handleSetAppError();
      }
    } catch (e) {
      //alert(`make default crashed ${JSON.stringify(e)}`);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  setDefaultCard(data) {
    return API.post("payments", "/card", {
        body: data.cardData
    });
  }

  async submitDeletion(cardId) {
    utilityFunctions.log(`deleting card ${cardId}`);
    this.props.hideLoader(false);
    try {
        let deleteCardResponse = await this.deleteCard({
          cardData: {
            action: "delete",
            card: cardId
          }
      });
      utilityFunctions.log(`deleteCardResponse ${JSON.stringify(deleteCardResponse)}`);
      if (!!deleteCardResponse.message && deleteCardResponse.message === "success") {
        this.retrieveCards();
        return;
      }
      this.props.handleSetAppError();
    } catch (e) {
      //alert(`make default crashed ${JSON.stringify(e)}`);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
      }
      this.props.handleSetAppError();
    }
    this.props.hideLoader(true);
  }

  deleteCard(data) {
    return API.post("payments", "/card", {
      body: data.cardData
    });
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  renderCardList() {
    var list = this.state.sources.concat([{}]);
    utilityFunctions.log(`list ${JSON.stringify(list)} length ${list.length}`)
    return list.map((source, i) =>
          i !== (list.length - 1) ? 
            <ListGroupItem
              key={source.id}
              style={{outline: "none", outlineOffset: "none"}}
            >
                {this.renderCardCell(source, (i === 0))}
            </ListGroupItem>
            : <ListGroupItem
                key="new"
              >
                <div className="Create">    
                  <MaterialButton onClick={(e) => this.props.history.push("/stripe")} variant="fab" mini style={{color: config.ThemeColor}} aria-label="Add" >
                    <AddIcon />
                  </MaterialButton>
                  <h4 className="greenText">Add New Card...</h4>
                </div>
              </ListGroupItem>
      );
  }

  renderCardCell(source, isDefaultCard) {
    return (
      <div>
        <span onClick={() => this.makeDefault(source.id)} className="cardCell">
          <span className="mainContent">
              <span>
                  <img src={ require(`../assets/profileTab/stripe/DarkTheme/${source.brand}.png`) } height="43" width="53" alt="CC" /><span className={isDefaultCard ? "greenText":"blackText"} style={{paddingLeft: "5px", display:"inline-block"}}>{`${source.brand} Ending in ${source.last4}`}</span>
              </span>
          </span>
          <span>
              {
                isDefaultCard && <span>
                      <img style={{marginRight:"25px"}} src={ require(`../assets/profileTab/greeenCheckmark@3x.png`) } height="20" width="20" alt="checkmark" />
                </span>
              }
          </span>
        </span>
        <span></span>
        <span onClick={() => this.submitDeletion(source.id)} style={{paddingLeft: "10px", color:"red"}}>
            Delete
        </span>
      </div>
    );
  }

  render() {
    return (
      this.props.completedStartup && !!this.state.sources ?  
      <div>
            <Breadcrumb>
                <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/profile")} >Profile</Breadcrumb.Item>
                <Breadcrumb.Item active>Payment Method</Breadcrumb.Item>
            </Breadcrumb>
        <div className="imageListContainer">
          <img className="ccImage" style={{marginBottom: "20px"}} src={ require(`../assets/profileTab/stpe_card@3x.png`) } alt="creditCard" />
          <ListGroup>
              {this.renderCardList()}
          </ListGroup>
        </div>
      </div>
      : <div style={{marginTop: '30px'}}>Loading...</div>
    );
  }
}
