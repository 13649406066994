import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button } from "react-bootstrap";

import "./AttemptSummary.css";
import config from "../config.js";
import GenericModal from '../components/GenericModal';
import SummaryView from '../components/SummaryView';
import utilityFunctions from "../utilityFunctions";

export default class AttemptSummary extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
    this.state = {
      showModal: false,
      modalKind: '',
      attemptData: undefined,
      attemptedCondico: undefined
    }
  }

  componentWillMount() {
    if (!!this.props.attemptCondico && !!this.props.attemptAmount) {
        this.setState({
            attemptData: {
                action: config.CondicoAction.attempt,
                initiatorId: this.props.attemptCondico.userId,
                dateTime: this.props.attemptCondico.dateTime,
                amount: this.props.attemptAmount,
                version: this.props.attemptCondico.version
            }
        })
    }
  }

  async handleConfirmation() {
    utilityFunctions.log("confirmed attempt");
    this.props.hideLoader(false);
    try {
      let attemptedCondicoResponse = await this.attemptCondico({
        attemptData: this.state.attemptData
      });
      this.checkResult(attemptedCondicoResponse);
    } catch (e) {
        this.props.hideLoader(true);
        if (!!e.response && !!e.response.data) {
          if(e.response.data.message === "Not authorized") {
            utilityFunctions.log("NOT AUTHORIZED");
            this.props.handleLogout();
            return;
          }
          this.props.handleSetAppError();
          return
        }
        this.props.handleSetAppError();
      }
  }

  attemptCondico(data) {
    return API.post("transactions", "/transactions/1", {
      body: data.attemptData
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`condico response is ${JSON.stringify(response)}`);
    if (!!response.condico) {
        var modalKind = "success";
        if (response.condico.state === config.CondicoState.completed) {
            modalKind = "condico";
        } else if (response.condico.state === config.CondicoState.pendingPayment) {
            modalKind = "pendingPayment";
        } else if (response.condico.state === config.CondicoState.attempted && (response.condico.version > this.props.attemptCondico.version)) {
            modalKind = "missed";
        }
        utilityFunctions.log("success");
        this.setState({attemptedCondico: response.condico, showModal: true, modalKind: modalKind}, () => this.props.removeCondicoFromAppStateDueToCompletedAction(config.CondicoAction.attempt));
    } else {
        utilityFunctions.log("failure");
        this.props.handleSetAppError();
    }
  }

  handleCloseModalPopover() {
    utilityFunctions.log("close modal called");
    this.setState({ showModal: false }, () => this.props.history.push("/mycondicos"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    var summaryBullets, confirmationBullets, alertTitle, imageType, confirmedCondicoData;
    var includeBullets = true;
    utilityFunctions.log("render of attempt summary");
    if (!!this.props.appSettings && !!this.props.attemptCondico) {
        if (!!this.props.attemptCondico.initiator.acceptFloor && this.props.attemptCondico.initiator.acceptFloor) {   
            summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.attemptWithFloorSummary;
            utilityFunctions.log(`attempt summary render assigning bullets as attemptWithFloorSummary ${JSON.stringify(summaryBullets)}`);
        } else if (!!this.props.attemptCondico.initiator.payCeiling && this.props.attemptCondico.initiator.payCeiling) {
            summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.attemptWithCeilingSummary;
            utilityFunctions.log(`attempt summary render assigning bullets as attemptWithCeilingSummary ${JSON.stringify(summaryBullets)}`);
        } else {
            this.props.handleSetAppError();
        }
    }
    if (!!this.state.attemptedCondico && this.state.modalKind === "condico") {
        alertTitle = "Condico!";
        includeBullets = false;
        confirmedCondicoData = this.state.attemptedCondico;
        imageType = config.ConfirmationModalImageType.completed;
        if (!!this.state.attemptedCondico.initiator.acceptFloor && this.state.attemptedCondico.initiator.acceptFloor) {
          confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnAttemptCompletedFloor;   
          utilityFunctions.log(`attempt summary render assigning bullets as popUpBulletsOnAttemptCompletedFloor ${JSON.stringify(confirmationBullets)}`);
        } else if (!!this.state.attemptedCondico.initiator.payCeiling && this.state.attemptedCondico.initiator.payCeiling) {
            confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnAttemptCompletedCeiling;
            utilityFunctions.log(`attempt summary render assigning bullets as popUpBulletsOnAttemptCompletedCeiling ${JSON.stringify(confirmationBullets)}`);
        } else {
            this.props.handleSetAppError();
        }
    } else if (!!this.state.attemptedCondico && this.state.modalKind === "pendingPayment") {
        alertTitle = "Condico! But...";
        includeBullets = false;
        confirmedCondicoData = this.state.attemptedCondico;
        imageType = config.ConfirmationModalImageType.payment;
        confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnAttemptPendingPayment;
    } else if (!!this.state.attemptedCondico && this.state.modalKind === "missed") {
        alertTitle = "Sorry, you're not settled yet";
        includeBullets = false;
        confirmedCondicoData = this.state.attemptedCondico;
        imageType = config.ConfirmationModalImageType.attempted;
        if (!!this.state.attemptedCondico.initiator.payCeiling && this.state.attemptedCondico.initiator.payCeiling) {
            confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnAttemptMissedCeiling;
        } else if (!!this.state.attemptedCondico.initiator.acceptFloor && this.state.attemptedCondico.initiator.acceptFloor) {
            confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnAttemptMissedFloor;
        } else {
            this.props.handleSetAppError();
        }
    }
    
    return (
      <div>
        { 
            ((this.state.showModal === true && !!confirmedCondicoData) || (!!this.props.attemptCondico)) ? <Breadcrumb>
            <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
                <Breadcrumb.Item  onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos/attempt")} >Attempt</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb> :
            <Breadcrumb>
                <Breadcrumb.Item href="/mycondicos">My Condicos</Breadcrumb.Item>
                <Breadcrumb.Item href="/mycondicos/attempt">Attempt</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb>
        }
        { 
            (this.state.showModal === true && !!confirmedCondicoData) ?
            <GenericModal
                bulletPoints={confirmationBullets}
                includeBullet={includeBullets}
                condicoData={confirmedCondicoData}
                partnerAmount={this.props.attemptAmount}
                imageType={imageType}
                alertTitle={alertTitle}
                handleCloseModalPopover={this.handleCloseModalPopover}
            /> : (!!this.props.attemptCondico) ?
            <div>
                <div className="Summary">
                <h2 style={{fontFamily: 'Roboto, helvetica'}}>Confirm Your Attempt</h2>
                For the dispute described as follows:
                <p style={{color: 'grey', fontFamily: 'Roboto, helvetica'}}><i>{this.props.attemptCondico.description}</i></p>
                {
                    !!this.props.appSettings && 
                    <SummaryView
                        childProps={this.props}
                        bulletPoints={summaryBullets}
                        partnerAmount={this.props.attemptAmount}
                        condicoData={this.props.attemptCondico}
                    ></SummaryView>
                }
                </div>
                <div className= "ButtonContainer">
                    <Button
                        className="button"
                        block
                        bsSize="large"
                        onClick={() => this.handleConfirmation()}
                    >
                        Confirm
                    </Button>
                </div>
            </div> :
            <div>
                You must enter your attempt amount first
            </div>
        }
      </div> 
    );
  }
};