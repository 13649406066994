import { API, Auth } from "aws-amplify";
import React, { Component } from "react";

import "./Home.css";
import utilityFunctions from "../utilityFunctions";

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      condicoCount: 0,
      inProgressCount: 0,
      hasGrabbedStats: false
    }
  }

  async componentWillUpdate() {
    utilityFunctions.log("profile componentWillUpdate")
    utilityFunctions.isAuthenticated(this.props);
  }

  // we do the below as a locking mechanism becasue most of the time we go through willmount, but if terms need to be accepted than we receive new props and we had already mounted to we still need to trigger API call
  // does this work when a new COndico is added and we already have been to this view?? - test it out
  componentWillReceiveProps(nextProps) {
      this.startStatisticsAPI(nextProps);
  }

  async componentWillMount() {
    this.startStatisticsAPI(this.props);
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (user !== null) {
        this.setState({ name: user.attributes['custom:givNameCus'] + " " + user.attributes['custom:famName_cus'] })
        utilityFunctions.log(user);
      }
    }
    catch (e) {
      utilityFunctions.log(e);
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  async startStatisticsAPI(props) {
    if (props.completedStartup && !this.state.hasGrabbedStats) {
      if (this.props.userInfo !== undefined && this.props.userInfo.type !== "admin") {
        this.setState({hasGrabbedStats: true});
        props.hideLoader(false);
        try {
          let response = await this.getStatistics();
          utilityFunctions.log(`response in HOME ${JSON.stringify(response)}`)
          if (!!response.statistics && !!response.statistics.success && response.statistics.success) {
            if (!!response.statistics.stats) {
              this.setState({
                condicoCount: response.statistics.stats.condicoCount,
                inProgressCount: response.statistics.stats.inProgressCount
              })
            } else {
              this.props.handleSetAppError();
            }
          } else {
            utilityFunctions.log("home api error");
            this.props.handleSetAppError();
          }
        } catch (e) {
          if (!!e.response && !!e.response.data) {
            if(e.response.data.message === "Not authorized") {
              utilityFunctions.log("NOT AUTHORIZED");
              this.props.handleLogout();
              return;
            }
            this.props.handleSetAppError();
            return
          }
          utilityFunctions.log(`ERRO IS ${e}`)
          this.props.handleSetAppError();
        }
        props.hideLoader(true);
      }
    }
  }

  getStatistics() {
    utilityFunctions.log("retrieving stats in home");
    return API.get("data", `/statistics/1`);
  }

  handleActiveStatClick(){
    utilityFunctions.log("setting active true from home");
    this.props.setOnloadSetSegCntrlToActive(true);
  }

  handleInActiveStatClick() {
    utilityFunctions.log("setting active false from home");
    this.props.setOnloadSetSegCntrlToActive(false);
  }

  render() {
    return (
      this.props.userInfo === undefined ? <div style={{margin: '30px'}}>Loading...</div> : this.props.userInfo.type === "admin" ? 
      <div><h2>Welcome {this.state.name}</h2><br /></div> :
        !this.state.hasGrabbedStats ? <div style={{marginTop: '30px'}}>Loading...</div> :
        <div className="homeJumbotron">
            <div className="statistic">
              <div style={{borderColor: "transparent"}} onClick={() => this.handleInActiveStatClick()}>
                <h1><font face="Roboto, helvetica" color="lightGrey">{this.state.condicoCount}</font></h1>
                <p><font face="Roboto, helvetica" color="lightGrey">Condicos</font></p>
              </div>
            </div>
            <div className="statistic">
              <div style={{borderColor: "transparent"}} onClick={() => this.handleActiveStatClick()}>
                <h1><font face="Roboto, helvetica" color="lightGrey">{this.state.inProgressCount}</font></h1>
                <p><font face="Roboto, helvetica" color="lightGrey">Transactions in Progress</font></p>
              </div>
            </div>
        </div>
    );
  }
}