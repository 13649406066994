import { Auth } from "aws-amplify";
import config from "./config.js";
export default {
  // input (String): takes an ISO date string in format YYY-MM-DDTHH:MM:SS.sssZ
  // output (String): MMM DD YYYY
  // * needs to be tested - witnessed issues

    async isAuthenticated(props) {
      log("utility isAuthenticated")
      try {
        if (await Auth.currentSession()) {
          log(`in utility when is Authenticated`);
          return true;
        }
      }
      catch (e) {
        log(`in utility when not Authenticated`);
        props.handleLogout();
        return false;
      }
    },

    // input (Date)
    // output (String): yyyy-mm-dd
    formatDate(date) {
      var minutesOffset = date.getTimezoneOffset()
      var millisecondsOffset = minutesOffset*60*1000
      var local = new Date(date - millisecondsOffset)
      return local.toISOString().substr(0, 10)
    },

    toShortFormat(input) {

      var month_names =["Jan","Feb","Mar",
                        "Apr","May","Jun",
                        "Jul","Aug","Sep",
                        "Oct","Nov","Dec"];
      
      var realDate = new Date(input);
      var hours = realDate.getHours(); var mins = realDate.getMinutes(); var secs = realDate.getSeconds();
      var amPM = "am";
      if (hours > 12) {
        hours -= 12;
        amPM = "pm";
      } else if (hours === 0) {
        hours = 12;
      }
      log(hours)
      var day = realDate.getDate();
      var month_index = realDate.getMonth();
      var year = realDate.getFullYear();           
      return `${hours}:${mins}:${secs} ${amPM}, ${month_names[month_index]} ${day} ${year}`;
    },

    getDisplayAmount(cognitoIdentityId, condico) {
        var initiatorAmount= null, lastFailedAmount = null, condicoAmount = null;
        if (!!condico.initiator.amount) {
          initiatorAmount = this.currencyInputFormatting(condico.initiator.amount);
        }
        if (!!condico.lastFailedAttempt) {
          lastFailedAmount = this.currencyInputFormatting(condico.lastFailedAttempt.amount);
        }
        if (!!condico.payment) {
          condicoAmount = this.currencyInputFormatting(condico.payment.condicoAmount);
        }
        if (condico.state === config.CondicoState.new || condico.state === config.CondicoState.attempted) {
          if (condico.userId === cognitoIdentityId) {
            if (!!condico.initiator.payCeiling && condico.initiator.payCeiling === true) {
              return `Willing to pay $${initiatorAmount}`;
            } else {
              return `Willing to accept $${initiatorAmount}`;
            }
          } else if (!!condico.lastFailedAttempt) {
            if (!!condico.initiator.payCeiling && condico.initiator.payCeiling === true) {
              return `Prepared to accept: $${lastFailedAmount}`;
            } else {
              return `Prepared to pay: $${lastFailedAmount}`;
            }
          } else {
            return "Not yet entered";
          }
        } else if (condico.state === config.CondicoState.rescinded) {
          if (condico.userId === cognitoIdentityId) {
            if (!!condico.initiator.payCeiling && condico.initiator.payCeiling === true) {
              return `You had been willing to pay $${initiatorAmount}`
            } else {
              return `You had been willing to accept $${initiatorAmount}`;
            }
          } else {
            if (!!condico.lastFailedAttempt) {
              return `You had attempted an amout of ${lastFailedAmount})`;
            } else {
              return "You did not attempt resolution";
            }
          }
        } else if (condico.state === config.CondicoState.completed) {
          return `Settled at $${condicoAmount}`;
        } else if (condico.state === config.CondicoState.pendingPayment) {
          return "Click to have settlement figure released";
        } else if (condico.state === config.CondicoState.expired) {
          if (condico.userId === cognitoIdentityId) {
            if (!!condico.initiator.payCeiling && condico.initiator.payCeiling === true) {
              return `You were willing to pay $${initiatorAmount}`;
            } else {
              return `You were willing to accept $${initiatorAmount}`;
            }
          } else if (!!condico.lastFailedAttempt) {
            return `You had attempted last: $${lastFailedAmount}`;
          } else {
            return "You had not attempted resolution";
          }
        } 
      },

      isAlpha(str) {
        return /^[a-zA-Z. -]+$/.test(str);
      },

      isAlphaNumeric(str) {
        return /^[a-zA-Z0-9. -]+$/.test(str);
      },

      isEmail(email) {
        var str = email.toLowerCase();
        var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        return re.test(str);
      },

      passwordIsOfMinStrength(pass) {
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/; 
        return re.test(pass);
      },

      isPhone(phone) {
        //var noDashes = phone.replaceAll('-', '');
        //var re = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/; // with optional 1
        var re = /^((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/; 
        return re.test(phone);
      },

      currencyInputFormatting(amount) {
        var num = parseInt(amount, 10);
        var dollars = (num / 100).toFixed(2);
        dollars.toLocaleString("en-US", {style:"currency", currency:"CAD"});
        var replacement = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return replacement;
      },

      storeCondicoDataToSessionStorage(cognitoId, condicoData) {
        sessionStorage.setItem(cognitoId, JSON.stringify(condicoData));
      },
    
      retrieveCondicoDataFromSessionStorage(cognitoId) {
        const cachedData = sessionStorage.getItem(cognitoId);
        if (cachedData) {
          return JSON.parse(cachedData);
        }
        log(`retrieved Data ${cachedData}`);
      },
    
      removeCreationDataFromSessionStorage(cognitoId) {
        sessionStorage.removeItem(cognitoId);
      },

      /* @Input: input (String) is the string to make replacements into
      description (String), expiry (Optional String), ccLast4 (Optional String), initiatorFirstname (String), initiatorLastname (String), initiatorEmail (Optional String), 
      partnerFirstname (String), partnerLastname (String), partnerEmail (String), initiatorAmount (Optional Int), partnerAmount (Optional Int), condicoAmount (Optional Int)
      @ Output: String with replaced values
      */
      performStringReplacements(input, description, expiry, ccLast4, initiatorFirstname, initiatorLastname, initiatorCompany, initiatorEmail, partnerFirstname, partnerLastname, partnerEmail, partnerCompany, initiatorAmount, partnerAmount, condicoAmount) {
        var output = input;

        // cc last 4 digit replacement
        if (!!ccLast4) {
          var last4 = "last4Digits";
          output = output.replaceAll("${" + last4 + "}", ccLast4);
        }
        
        // matter description
        var desc = "description";
        output = output.replaceAll("${" + desc +"}", description);
        
        // expiry
        if (!!expiry) {
          var exp = "expiry";
          output = output.replaceAll("${" + exp +"}", `${new Date(expiry).toLocaleTimeString().toString()}, ${new Date(expiry).toDateString()}`);
        }
        
        // initiator
        var initFn = "initiatorFirstname", initLn = "initiatorLastname"
        output = output.replaceAll("${" + initFn +"}", initiatorFirstname);
        output = output.replaceAll("${" + initLn +"}", initiatorLastname);
        
        // initiator email
        if (!!initiatorEmail) {
          var initEmail = "initiatorEmail";
          output = output.replaceAll("${" + initEmail +"}", initiatorEmail);
        }

        // partner
        var partFn = "partnerFirstname", partLn = "partnerLastname", partEmail = "partnerEmail"; 
        output = output.replaceAll("${" + partFn +"}", partnerFirstname);
        output = output.replaceAll("${" + partLn +"}", partnerLastname);
        output = output.replaceAll("${" + partEmail +"}", partnerEmail);

        // initiator amounts
        if (!!initiatorAmount) {
          var initAmt = "initiatorAmount";
          output = output.replaceAll("${" + initAmt +"}", `$${this.currencyInputFormatting(initiatorAmount)}`);
        }
        
        // partner amounts
        if (!!partnerAmount) {
          var partAmt = "partnerAmount";
          output = output.replaceAll("${" + partAmt +"}", `$${this.currencyInputFormatting(partnerAmount)}`);
        }
        
        // Condico Amount
        if (!!condicoAmount) {
          var condicoAmt = "condicoAmount";
          output = output.replaceAll("${" + condicoAmt +"}", `$${this.currencyInputFormatting(condicoAmount)}`);
        }

        // handle business if exists
        var initCompanyOrFirstname = "initiatorCompanyOrFirstname";
        var initCompanyOrFirstnameLastname = "initiatorCompanyOrFirstnameLastname";
        var initCompanyAndFirstname = "initiatorCompanyAndFirstname";
        if (!!initiatorCompany) {
          output = output.replaceAll("${" + initCompanyOrFirstname +"}", initiatorCompany);
          output = output.replaceAll("${" + initCompanyOrFirstnameLastname +"}", initiatorCompany);
          output = output.replaceAll("${" + initCompanyAndFirstname +"}", `${initiatorCompany} or ${initiatorFirstname}`);
        } else {
          output = output.replaceAll("${" + initCompanyOrFirstname +"}", initiatorFirstname);
          output = output.replaceAll("${" + initCompanyOrFirstnameLastname +"}", `${initiatorFirstname} ${initiatorLastname}`);
          output = output.replaceAll("${" + initCompanyAndFirstname +"}", initiatorFirstname);
        }

        var partCompanyOrFirstname = "partnerCompanyOrFirstname";
        var partCompanyOrFirstnameLastname = "partnerCompanyOrFirstnameLastname";
        var partCompanyAndFirstname = "partnerCompanyAndFirstname";
        if (!!partnerCompany) {
          output = output.replaceAll("${" + partCompanyOrFirstname +"}", partnerCompany);
          output = output.replaceAll("${" + partCompanyOrFirstnameLastname +"}", partnerCompany);
          output = output.replaceAll("${" + partCompanyAndFirstname +"}", `${partnerCompany} or ${partnerFirstname}`);
        } else {
          output = output.replaceAll("${" + partCompanyOrFirstname +"}", partnerFirstname);
          output = output.replaceAll("${" + partCompanyOrFirstnameLastname +"}", `${partnerFirstname} ${partnerLastname}`);
          output = output.replaceAll("${" + partCompanyAndFirstname +"}", partnerFirstname);
        }
        return output;
      },

      log: log
      
}

function log(inputString) {
  if (config.getEnvironmentConfig(config.Environment).debug) {
    console.log(`${inputString}`);
  }
}

String.prototype.replaceAll = function (find, replace) {
  if (replace.includes('$')) {
    replace = `\$${replace}`;
  }
  return this.replace(new RegExp(find.replace(/[-^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};