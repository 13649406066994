import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) => {
  //utilityFunctions.log(`child props ${JSON.stringify(cProps)}`)
  //utilityFunctions.log(` redirect to: /login?redirect=${rest.location.pathname}${rest.location.search}`)
  return (
    <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
        ? <C {...props} {...cProps} />
        : <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />}
  />
  )
}
  

  //const currentUser = Auth.userPool.getCurrentUser()
//await currentUser.signOut()