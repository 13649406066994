import { API } from "aws-amplify";
import React, { Component } from "react";
import { Breadcrumb, Button } from "react-bootstrap";

import "./RescindSummary.css";
import config from "../config.js";
import GenericModal from '../components/GenericModal';
import SummaryView from '../components/SummaryView';
import utilityFunctions from "../utilityFunctions";

export default class RescindSummary extends Component {
  constructor(props) {
    super(props);
    this.NavigateBreadcrumb = this.NavigateBreadcrumb.bind(this);
    this.handleCloseModalPopover = this.handleCloseModalPopover.bind(this);
    this.state = {
      showModal: false,
      modalKind: '',
      rescindData: undefined,
      rescindedCondico: undefined
    }
  }

  componentWillMount() {
    if (!!this.props.rescindCondico) {
        this.setState({
            rescindData: {
                action: config.CondicoAction.rescind,
                dateTime: this.props.rescindCondico.dateTime,
                reason: "feature not implemented frontend",
                version: this.props.rescindCondico.version
            }
        })
    }
  }

  async handleConfirmation() {
    utilityFunctions.log("confirmed rescind");
    this.props.hideLoader(false);
    try {
      let rescindedCondicoResponse = await this.rescindCondico({
        rescindData: this.state.rescindData
      });
      this.checkResult(rescindedCondicoResponse);
    } catch (e) {
      this.props.hideLoader(true);
      if (!!e.response && !!e.response.data) {
        if(e.response.data.message === "Not authorized") {
          utilityFunctions.log("NOT AUTHORIZED");
          this.props.handleLogout();
          return;
        }
        this.props.handleSetAppError();
        return
      }
      this.props.handleSetAppError();
    }
  }

  rescindCondico(data) {
    return API.post("transactions", "/transactions/1", {
      body: data.rescindData
    });
  }

  checkResult(response) {
    this.props.hideLoader(true);
    utilityFunctions.log(`condico response is ${response}`);
    if (!!response.condico) {
        utilityFunctions.log("success");
        this.setState({rescindedCondico: response.condico, showModal: true, modalKind: "success"}, () => this.props.removeCondicoFromAppStateDueToCompletedAction(config.CondicoAction.rescind));
    } else {
        utilityFunctions.log("failure");
        this.props.handleSetAppError();
    }
  }

  handleCloseModalPopover() {
    utilityFunctions.log("close modal called");
    this.setState({ showModal: false }, () => this.props.history.push("/mycondicos"));
  }

  NavigateBreadcrumb(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    var summaryBullets, confirmationBullets, alertTitle, imageType, confirmedCondicoData;
    var includeBullets = true;
    if (!!this.props.appSettings) {
      summaryBullets = this.props.appSettings.swiftViewTexts.summaryViewBullets.rescindSummary;
    }
    if (!!this.state.rescindedCondico && this.state.modalKind === "success") {
        alertTitle = "Rescinded";
        includeBullets = true;
        confirmedCondicoData = this.state.rescindedCondico;
        imageType = config.ConfirmationModalImageType.rescinded;
        confirmationBullets = this.props.appSettings.swiftViewTexts.confirmationPopoverBullets.popUpBulletsOnRescindSuccessful;
    }
    
    return (
      <div>
        { 
            ((this.state.showModal === true && !!confirmedCondicoData) || (!!this.props.rescindCondico)) ? <Breadcrumb>
            <Breadcrumb.Item onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos")} >All Transactions</Breadcrumb.Item>
                <Breadcrumb.Item  onClick={(e) => this.NavigateBreadcrumb(e, "/mycondicos/rescind")} >Rescind</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb> :
            <Breadcrumb>
                <Breadcrumb.Item href="/mycondicos">My Condicos</Breadcrumb.Item>
                <Breadcrumb.Item href="/mycondicos/rescind">Rescind</Breadcrumb.Item>
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb>
        }
        { 
            (this.state.showModal === true && !!confirmedCondicoData) ?
            <GenericModal
                bulletPoints={confirmationBullets}
                includeBullet={includeBullets}
                condicoData={confirmedCondicoData}
                imageType={imageType}
                alertTitle={alertTitle}
                handleCloseModalPopover={this.handleCloseModalPopover}
            /> : (!!this.props.rescindCondico) ?
            <div>
                <div className="Summary">
                  <h2 style={{fontFamily: 'Roboto, helvetica'}}>Rescind Your Offer</h2>
                  For the dispute you have described as follows:
                  <p style={{color: 'grey', fontFamily: 'Roboto, helvetica'}}><i>{this.props.rescindCondico.description}</i></p>
                  {
                      !!this.props.appSettings && 
                      <SummaryView
                        childProps={this.props}
                        bulletPoints={summaryBullets}
                        condicoData={this.props.rescindCondico}
                      /> 
                  }
                </div><br />
                <div className= "ButtonContainer">
                <Button
                    className="rescindButton"
                    block
                    bsSize="large"
                    onClick={() => this.handleConfirmation()}
                >
                    Confirm
                </Button>
                </div>
            </div> :
            <div>
                You must create a complete condico first
            </div>
        }
      </div> 
    );
  }
};